import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RSIService } from '@shift/rsi';
import { basicphonecall } from '../../interfaces/basicphonecall';


@Injectable()
export class BasicPhoneCallService {
    public callIsAllowed = new BehaviorSubject<boolean>(false);

    constructor(private rsi: RSIService) {
    }

    public init() {
        this.subscribeForData();
    }

    private subscribeForData(): void {
        const endpoint = this.rsi.firstEndpoint('/basicphonecall/callStates/', {'autoGetData': false});
        endpoint.subscribe((data: basicphonecall.ICallStateObject) => {
            this.callIsAllowed.next(data.callIsAllowed);
        });
    }
}
