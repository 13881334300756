import { Component, ElementRef, Input, Output, EventEmitter, HostBinding, OnInit, OnChanges, SimpleChanges } from '@angular/core';

/**
 * Popup component that show up from the top over the header. Put the shift-popup outside of the shift-screen.
 * @example
 * <shift-screen>
 *                  <shift-main>
 *                      <!-- YOUR CONTENT -->
 *                  </shift-main>
 * </shift-screen>
 * <shift-popup [open]="yourOpenVariable" [text]="'Example text for popup'"></shift-popup>
 */
@Component({
    selector: 'shift-popup',
    template: `<div [ngClass]="{'screenblocker': open}"></div>
<div class="popup-container" [ngClass]="{'open': open}" >
  <div class="popup-wrapper" [ngClass]="{'fullWidth': blocking}">
    <div class="popup content" [ngClass]="{'customOverlay': buttonsAvailable}">
      <div class="customContent" [ngClass]="{'blocking': blocking, 'fixed-height': fixedHeight}">

        <div *ngIf="withImage" class="text-img-wrapper">
          <div class="img-area">
            <img [src]="imageSrc" />
          </div>
          <div class="text-area" [innerHTML]='text'></div>
        </div>

        <div *ngIf="blockingAndLoading">
          <div class="spinner-and-text-wrapper">
            <div class="spinner-container">
              <div *ngIf='showLoadingIdc' class='loading-anime-mask'></div>
            </div>
            <div class="text-area" [innerHTML]='text'></div>
          </div>
        </div>

        <div *ngIf="!withImage && !blockingAndLoading">
          <div class="text-area">
            <p *ngIf='texts.length === 0' [innerHTML]='text'></p>
            <p *ngFor='let atext of texts'>{{ atext }}</p>
          </div>
        </div>



        <div *ngIf="buttonsAvailable" class="overlay-footer">
          <button *ngIf="!hideAbortBtn" type='button' (click)="abortFunction($event)">{{ abortBtnText }}</button>
          <button type='button' (click)="confirmFunction($event)">{{ confirmBtnText }}</button>
        </div>
      </div>
    </div>
  </div>

</div>
`,
    styles: [`:host{position:fixed}`]
})
export class ShiftPopupComponent implements OnInit, OnChanges {

    /**
     * Switch to open and close the Popup. The class 'open' will be applied to the component.
     */
    @HostBinding('class.open')
    @Input()
    public open: boolean;

    /**
     * Sets a single text line.
     */
    @Input() public text = '!Single Textline';

    /**
     * Sets multiple text lines as array:
     */
    @Input() public texts: string[] = [];

    /**
     * Sets confirm button text.
     */
    @Input() public confirmBtnText = '';

    /**
     * Sets abort button text.
     */
    @Input() public abortBtnText = '';

    /**
     * Switch for showing footer with buttons in popup
     */
    @Input() public withFooter: boolean;

    /**
     * Checks if withFooter Input was provided or at least one button text was set.
     */
    public get buttonsAvailable(): boolean {
        if(this.withFooter || this.confirmBtnText !== '' || this.abortBtnText !== '') {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Switch to hide abort button. Set true to hide abort button.
     */
    @Input() public hideAbortBtn: boolean;

    /**
     * Switch to show popup over the full width of the screen.
     */
    @Input() public blocking: boolean;

    /**
     * Switch to set popup with fixed height.
     */
    @Input() public fixedHeight = false;

    /**
     * Switch for showing footer with buttons in popup
     */
    @Input() public showLoadingIdc: boolean;

    /**
     * Sets the source of an image that will be shown next to @Input text.
     * If nothing is provided the @Input texts will be used for showing text lines.
     */
    @Input() public imageSrc = '';

    /**
     * Event will be emitted when open state of popup changed (true if opened, false if closed)
     */
    @Output() public abortClicked: EventEmitter<any> = new EventEmitter();
    @Output() public confirmClicked: EventEmitter<any> = new EventEmitter();

    public blockingAndLoading: boolean;
    public withImage: boolean;

    /**
     * Constructor for popup component
     */
    constructor() {}

    /**
     * Implementation of onInit method: hide abort button when abort text is empty
     */
    ngOnInit(): void {
        if (this.abortBtnText === '') {
            this.hideAbortBtn = true;
        }
    }

    /**
     * Implementation of OnChanges method.
     */
    ngOnChanges(changes: SimpleChanges): void {
        if(this.withFooter && this.abortBtnText === '' && this.confirmBtnText === '') {
            this.confirmBtnText = 'Ok';
        }
        this.blockingAndLoading = this.blocking && this.showLoadingIdc;
        this.withImage = this.imageSrc.length > 0;
    }

    /**
     * Function that emits the output 'abortClicked'
     */
    public abortFunction(event: Event): void {
        if (event) {
            event.stopPropagation();
        }
        if (this.abortClicked.observers.length === 0) {
            console.warn('abortClicked listener is not working - please implement abortClicked() in your <view>.component.ts');
        } else {
            this.abortClicked.emit();
        }
    }

    /**
     * Function that emits the output 'confirmClicked'
     */
    public confirmFunction(event: Event): void {
        if (event) {
            event.stopPropagation();
        }
        if (this.confirmClicked.observers.length === 0) {
            console.warn('confirmClicked listener is not working - please implement confirmClicked() in your <view>.component.ts');
        } else {
            this.confirmClicked.emit();
        }
    }
}
