import { AfterViewInit, Directive, ElementRef, EventEmitter, Output } from '@angular/core';

import { GesturesService } from '../gestures.service';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[panRightDoubleGesture]'
})
export class PanRightDoubleGestureDirective implements AfterViewInit {
    @Output() onPanRightDouble: EventEmitter<{}> = new EventEmitter();

    public emitGesture = true;
    public eventId = 'panDoubleEvent';

    constructor(public el: ElementRef, private gesturesService: GesturesService) {}

    ngAfterViewInit(): void {
        const hammertime: HammerManager = new Hammer(this.el.nativeElement);
        hammertime.get('pan').set({ threshold: 10, direction: Hammer.DIRECTION_RIGHT, pointers: 2 });

        this.gesturesService.pan(hammertime, this.onPanRightDouble, this);
        this.gesturesService.stopForIncomingEvents(hammertime, this);
    }
}
