import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShiftScrollDirective } from './scroll.directive';

export * from './scroll.directive';

@NgModule({
    imports: [CommonModule],
    exports: [ShiftScrollDirective],
    declarations: [ShiftScrollDirective]
})
export class ShiftScrollModule {
    /** @deprecated */
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ShiftScrollModule,
            providers: []
        };
    }
}
