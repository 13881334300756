import { AfterViewInit, Directive, ElementRef, EventEmitter, NgZone, Output } from '@angular/core';
import { GesturesService } from '../gestures.service';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[tapGesture]'
})
export class TapGestureDirective implements AfterViewInit {
    @Output() onTap: EventEmitter<{}> = new EventEmitter();
    @Output() onDoubleTap: EventEmitter<{}> = new EventEmitter();

    constructor(public el: ElementRef, private gesturesService: GesturesService, private ngZone: NgZone) {}

    ngAfterViewInit(): void {
        const hammertime: HammerManager = new Hammer(this.el.nativeElement);
        const tap: Recognizer = hammertime.get('tap');
        const doubletap: Recognizer = hammertime.get('doubletap');

        // Ordered by priority, doubletap has higher prio!
        hammertime.add([doubletap, tap]);

        // Recognize "double tap" with "tap"
        doubletap.recognizeWith(tap);

        // Let "tap" fail when "double tapping"
        tap.requireFailure(doubletap);

        // On single tap
        hammertime.on('tap', (ev: HammerInput) => {
            this.ngZone.run(() => {
                this.onTap.emit(ev);
            });
        });

        // On schweine mett
        hammertime.on('doubletap', (ev: HammerInput) => {
            this.ngZone.run(() => {
                this.onDoubleTap.emit(ev);
            });
        });
    }
}
