import { ShiftLegalEntryComponent } from './legal-entry.component';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ShiftHeaderModule } from '../header/header.module';
import { ShiftMainModule } from '../main/main.module';
import { ShiftScreenModule } from '../shift-screen/shift-screen.module';

@NgModule({
    imports: [CommonModule, ShiftHeaderModule, ShiftMainModule, ShiftScreenModule],
    exports: [ShiftLegalEntryComponent],
    declarations: [ShiftLegalEntryComponent]
})
export class ShiftLegalEntryModule {
    /** @deprecated */
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ShiftLegalEntryModule,
            providers: []
        };
    }
}
