import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShiftButtonModule } from '../button/button.module';
import { ShiftPopupComponent } from './popup.component';
import { ShiftSpinnerModule } from '../asset-components/shift-spinner/shift-spinner.module';

@NgModule({
    imports: [CommonModule, ShiftButtonModule, ShiftSpinnerModule],
    exports: [ShiftPopupComponent],
    declarations: [ShiftPopupComponent]
})
export class ShiftPopupModule {}
