import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, Output, Renderer } from '@angular/core';
import { GesturesService } from '../gestures.service';
import { GesturesThresholds } from '../gestures-thresholds';
import { TweenManagerService } from '../animation/tween-manager.service';

/**
 * DIV of list should have 'display: inline-block;' for full width
 * Parent should use 'overflow-x: scroll' with viable/calculatable dimensions
 */
@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[rubberbandHorizontal]'
})
export class RubberbandHorizontalDirective implements AfterViewInit {
    @Output() onScroll: EventEmitter<{}> = new EventEmitter();
    @Output() onScrollComplete: EventEmitter<{}> = new EventEmitter();

    private _pageMode: boolean;
    /*
     @Input() set rubberbandHorizontal(value_:boolean) {
     this._pageMode = value_;
     }
     */
    private _rendering: boolean;
    private _props: any;
    private _gestureStarted = false;
    private _scrollable: boolean;
    private _pageSwipe = true;
    private _scrollLeftStart: number;
    private _contentWidth: number;
    private _parentWidth: number;
    private _minLeft: number;
    private _maxLeft: number;
    private _lastDeltaX: number;
    private _deltaX: number;
    private _targetLeft = 0;

    private initialOffset: number;
    private lastSnapIndex: number;
    private items: Array<any>;
    private positions: Array<any>;

    constructor(
        public el: ElementRef,
        private gesturesService: GesturesService,
        private renderer: Renderer,
        @Inject(TweenManagerService) private tweenManagerService: TweenManagerService
    ) {
        // Only vertical scrolling w/ mousewheel supported for the moment.
        // renderer.listen(el.nativeElement, 'wheel', (e:any) => { this.onMouseWheel(e); });
    }

    /**
     * After view init
     */
    ngAfterViewInit(): void {
        this._props = { x: 0, y: 0, z: 0 };

        const options: HammerOptions = {
            touchAction: 'manipulation'
        };
        const hammertime: HammerManager = new Hammer(this.el.nativeElement, options);
        const pan: Recognizer = hammertime.get('pan');
        pan.set({
            threshold: GesturesThresholds.GRID_PAN_LEFT_THRESHOLD,
            direction: Hammer.DIRECTION_HORIZONTAL,
            pointers: 1
        });

        // On pan
        hammertime.on('panstart pan panend', (ev: HammerInput) => {
            ev.preventDefault();

            // End
            if (this._gestureStarted && (ev.type === 'panend' || ev.type === 'touchend')) {
                this.gesturesService.gestureInProgress = false;
                this.onPanEnd(ev);
            } else if (!this._gestureStarted && !this.gesturesService.gestureInProgress) {
                // Start
                this.gesturesService.gestureInProgress = true;
                this.onPanStart(ev);
                this.onPan(ev);
            } else {
                // Update
                this.onPan(ev);
            }

            this.onScroll.emit(ev);
        });

        this.reset();
    }

    /**
     * Reset all values
     */
    private reset(): void {
        this._contentWidth = this.contentWidth;
        this._parentWidth = this.parentWidth;
        this._scrollable = this._contentWidth > this._parentWidth;
        this._scrollLeftStart = this.scrollLeft;
        this._props.x = -this._scrollLeftStart;
        this._maxLeft = 0;
        this._minLeft = -this._contentWidth + this._parentWidth;
        if (this._contentWidth < this._parentWidth) {
            this._minLeft = 0;
        } // Wenn der Content gar nicht breiter als der Parent ist -> kein Ãberscrollen erlauben
        this._lastDeltaX = 0;
        this._deltaX = 0;
        this.calculateSnapPositions();
    }

    /**
     * Calculate scroll container's snap positions,
     * at the moment with every panStart
     */
    private calculateSnapPositions(): void {
        // if (this.positions && this.positions.length > 1 && this.positions[this.positions.length - 1] > 0) {
        //      return;
        // }

        this.items = [];
        this.positions = [];
        this.lastSnapIndex = 0;
        this.initialOffset = 0;

        const mainOffset: number = Math.round(this.el.nativeElement.parentElement.getBoundingClientRect().left);
        const scrollOffset: number = this._props.x;
        const depth = 0;

        this.positions = this.positions.concat(
            this.getSnapPositionsOf(this.el.nativeElement, mainOffset, scrollOffset, depth)
        );

        if (this.positions.length > 0) {
            for (let index = 0; index < this.positions.length; index++) {
                if (index === 0) {
                    this.initialOffset = this.positions[index];
                }

                if (this.positions[index] < this._contentWidth - this._parentWidth - this.initialOffset) {
                    this.lastSnapIndex = index + 1;
                }
            }
        }

        this._pageMode = this.positions.length <= 1;
    }

    /**
     * Recursive parsing of children with styleclass "snapX" for their x-pos,
     * no max-depth for now
     */
    private getSnapPositionsOf(parent: any, mainOffset_: number, scrollOffset_: number, depth_: number): Array<any> {
        let a: Array<any> = [];
        const total: number = parent.childElementCount;
        for (let index = 0; index < total; index++) {
            const child: any = parent.children[index];
            const hasSnapStyleClass: boolean = child.classList.contains('snapX');
            const childPos: number = Math.round(child.getBoundingClientRect().left - scrollOffset_ - mainOffset_);
            if (hasSnapStyleClass) {
                a.push(childPos);
                this.items.push(child);
            }
            a = a.concat(this.getSnapPositionsOf(child, childPos + mainOffset_, scrollOffset_, depth_ + 1));
        }
        return a;
    }

    /*
     * On pan start
     */
    private onPanStart(ev: HammerInput): void {
        if (!this._gestureStarted) {
            this.tweenManagerService.kill(this._props);

            this._gestureStarted = true;
            this.reset();
            this.gesturesService.gestureInProgress = true;

            this.rendering = true;
        }
    }

    /*
     * On pan update
     */
    private onPan(ev: HammerInput): void {
        if (this._scrollable && ev.deltaTime < 500) {
            // f.e. prevented on Widget Drag
            if (this.preventScrolling) {
                this._scrollable = false;
            }
        }
        if (this._gestureStarted && this._scrollable) {
            if (ev.deltaX !== this._deltaX) {
                this._lastDeltaX = this._deltaX;
                this._deltaX = ev.deltaX;
            }

            const absDeltaX: number = Math.abs(ev.deltaX);
            const targetLeft: number = -this._scrollLeftStart + this._deltaX;
            let diffX: number;
            let absDiffX: number;
            let rubberLeft: number;
            const maxDeltaX: number = this._parentWidth;
            if (absDeltaX > maxDeltaX) {
                this._deltaX = maxDeltaX * Math.log10(absDeltaX / maxDeltaX);
            }

            if (targetLeft > this.maxLeft) {
                diffX = -targetLeft - maxDeltaX;
                absDiffX = Math.abs(diffX);
                rubberLeft = Math.round(maxDeltaX * Math.log10(absDiffX / maxDeltaX));
            } else if (targetLeft <= this.minLeft) {
                diffX = this.minLeft - targetLeft + maxDeltaX;
                absDiffX = Math.abs(diffX);
                rubberLeft = Math.round(this.minLeft - maxDeltaX * Math.max(0, Math.log10(absDiffX / maxDeltaX)));
            } else {
                rubberLeft = Math.round(targetLeft);
            }

            this._props.x = rubberLeft;
            // this.redraw();
        }
    }

    /**
     * Render
     */
    private render(): void {
        if (this._rendering) {
            this.redraw();
            if (this._gestureStarted || Math.round(this.scrollLeft) !== Math.round(this._targetLeft)) {
                window.requestAnimationFrame(() => this.render());
            } else {
                this.rendering = false;
            }
        }
    }

    /**
     * Redraw
     * Use valid pos for scrollLeft, rest for rubberband via translate3d(...)
     */
    private redraw(): void {
        if (this._props) {
            let scrollValue: number = -Math.round(Math.max(this.minLeft, Math.min(this.maxLeft, this._props.x)));
            let rubberDiff = 0;
            if (this._props.x > this.maxLeft) {
                scrollValue = 0;
                rubberDiff = Math.abs(this._props.x - this.maxLeft);
            } else if (this._props.x < this.minLeft) {
                scrollValue = -this.minLeft;
                rubberDiff = -Math.abs(this.minLeft - this._props.x);
            }
            this.el.nativeElement.parentElement.scrollLeft = scrollValue;
            const transformValue = 'translate3d(' + Math.round(rubberDiff) + 'px, ' + 0 + 'px, ' + 0 + ')';
            this.renderer.setElementStyle(this.el.nativeElement, 'WebkitTransform', transformValue);
        }
    }

    /*
     * On pan end
     */
    private onPanEnd(ev: HammerInput): void {
        if (this._gestureStarted) {
            this._gestureStarted = false;
            this.snap(ev.velocityX);
            this.gesturesService.gestureInProgress = false;
            this.onScrollComplete.emit(ev);
        }
    }

    /**
     * Calculate snap settings by touch-velocity
     */
    private snap(velocity_: number = 0): void {
        const procDist = 100 / Math.max(this._contentWidth, this._parentWidth) * Math.abs(velocity_);
        let targetPos: number;
        let duration = 0.3;
        let nearestPosIndex = 0;

        // Page Mode
        if (this._pageMode) {
            const startPage: number = Math.floor(this._scrollLeftStart / this._parentWidth);
            let targetPage: number = startPage;
            const pagesTotal: number = Math.ceil(this._contentWidth / this._parentWidth);
            if (this._lastDeltaX > 0) {
                targetPage = startPage - 1;
            } else if (this._lastDeltaX < 0) {
                targetPage = startPage + 1;
            }
            targetPage = Math.max(0, Math.min(pagesTotal - 1, targetPage));
            targetPos = -targetPage * this._parentWidth;
        } else {
            targetPos = this._props.x + 1.5 * procDist * this._contentWidth * this.direction;
            if (this._pageSwipe && Math.abs(velocity_) >= 1) {
                targetPos = this._props.x + 0.8 * this.direction * this._parentWidth;
            }

            nearestPosIndex = this.getNearestPositionIndex(targetPos);
            targetPos = -this.positions[nearestPosIndex] + this.initialOffset;
            duration = this.getDuration(this._props.x, targetPos);
        }

        this.scrollTo(targetPos, false, duration);
    }

    /**
     * Scroll tweened to snap position
     */
    private scrollTo(left_: number, easeInOut: boolean = false, duration_: number = 0.8): void {
        this.tweenManagerService.kill(this._props);
        // ToDo: Embed gsap/CustomEase
        // ease: CustomEase.create("custom", "M0,0 C0.128,0.572 0.377,0.926 0.632,1 0.792,1.046 0.838,1 1,1")
        const easing = 'Cubic.easeOut';
        this._targetLeft = Math.max(this.minLeft, Math.min(this.maxLeft, left_));
        this.tweenManagerService.to(this._props, duration_, {
            x: this._targetLeft,
            ease: easing,
            onUpdate: () => {
                this.redraw();
            },
            onComplete: () => {
                this.onSnapComplete();
            }
        });
    }

    /**
     * On snap complete
     */
    private onSnapComplete(): void {
        this.el.nativeElement.parentElement.scrollLeft =
            -1 * Math.max(this.minLeft, Math.min(this.maxLeft, this._props.x));
        this.renderer.setElementStyle(
            this.el.nativeElement,
            'WebkitTransform',
            'translate3d(' + 0 + 'px, ' + 0 + 'px, ' + 0 + ')'
        );
        this.gesturesService.gestureInProgress = false;
    }

    /**
     * Calculate snap duration by distance
     */
    private getDuration(start_: number, end_: number): number {
        const distance: number = Math.abs(end_ - start_);
        const procDist: number = distance / this._parentWidth;
        const duration: number = procDist; // * 1 / 2;
        return Math.max(0.3, Math.min(5, duration));
    }

    /**
     * Calculate nearest snap pos index by target pos
     */
    private getNearestPositionIndex(x_: number): number {
        const scrollX: number = Math.round(-x_);
        let n = 1;
        let posPrev: number;
        let posNext: number;
        let posBest: number;
        let indexBest: number = -1;

        if (this.positions && this.positions.length > 1) {
            if (this.scrollLeft > 0 && scrollX < this.positions[0] + (this.positions[1] - this.positions[0]) / 2) {
                indexBest = 0;
            } else if (scrollX > this.positions[this.lastSnapIndex]) {
                indexBest = this.lastSnapIndex;
            } else {
                while (indexBest < 0 && n < this.positions.length) {
                    posPrev = this.positions[n - 1];
                    posNext = this.positions[n];
                    if (posPrev <= scrollX && scrollX <= posNext) {
                        posBest = scrollX - posPrev > posNext - scrollX ? posNext : posPrev;
                        indexBest = posBest === posNext ? n : n - 1;
                    }
                    n++;
                }

                if (indexBest < 0) {
                    indexBest = this.direction < 0 ? this.lastSnapIndex : 0;
                }
            }
        }

        return Math.max(0, Math.min(this.lastSnapIndex, indexBest));
    }

    /**
     * Returns latest pan direction
     */
    private get direction(): number {
        if (this._deltaX && this._lastDeltaX) {
            if (this._deltaX > this._lastDeltaX) {
                return 1;
            } else if (this._deltaX < this._lastDeltaX) {
                return -1;
            }
        }

        return 0;
    }

    /**
     * Setter
     */
    private set rendering(value_: boolean) {
        if (this._rendering !== value_) {
            this._rendering = value_;
            if (this._rendering) {
                this.render();
            }
        }
    }

    /**
     * Getter
     */
    public get contentWidth(): number {
        return this.el.nativeElement.scrollWidth;
    }

    public get parentWidth(): number {
        return Math.round(parseFloat(getComputedStyle(this.el.nativeElement.parentElement).getPropertyValue('width')));
    }

    public get scrollLeft(): number {
        return this.el.nativeElement.parentElement.scrollLeft;
    }

    private get translateX(): number {
        let mat: any;
        if (!this.el.nativeElement) {
            return 0;
        }

        const style: any = getComputedStyle(this.el.nativeElement);

        if (!style) {
            return 0;
        }

        const transform: any = style.transform || style.webkitTransform || style.mozTransform;
        if (transform) {
            mat = transform.match(/^matrix3d\((.+)\)$/);
            if (mat) {
                return parseFloat(mat[1].split(', ')[12]);
            }
            mat = transform.match(/^matrix\((.+)\)$/);
        }
        return mat ? parseFloat(mat[1].split(', ')[4]) : 0;
    }

    public get minLeft(): number {
        return this._minLeft;
    }

    public get maxLeft(): number {
        return this._maxLeft;
    }

    public get preventScrolling(): boolean {
        return this.el.nativeElement.parentElement.classList.contains('prevent-scroll');
    }
}
