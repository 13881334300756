import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShiftButtonModule } from '../../button/button.module';
import { ShiftPopup2Component } from './popup2.component';

@NgModule({
    imports: [CommonModule, ShiftButtonModule],
    exports: [ShiftPopup2Component],
    declarations: [ShiftPopup2Component]
})
export class ShiftPopup2Module {}
