const defaultAvailableLanguages = [
    'ar_SA',
    'bg_BG',
    'bs_BA',
    'cs_CZ',
    'da_DK',
    'de_AT',
    'de_CH',
    'de_DE',
    'de_LU',
    'el_GR',
    'en_CY',
    'en_GB',
    'en_GI',
    'en_IE',
    'en_MT',
    'en_US',
    'es_ES',
    'es_IC',
    'es_MX',
    'et_EE',
    'fi_FI',
    'fr_BE',
    'fr_CA',
    'fr_CH',
    'fr_FR',
    'fr_LU',
    'fr_RE',
    'hr_HR',
    'hu_HU',
    'is_IS',
    'it_CH',
    'it_IT',
    'ja_JP',
    'ko_KR',
    'lt_LT',
    'lv_LV',
    'mk_MK',
    'nl_BE',
    'nl_NL',
    'no_NO',
    'pl_PL',
    'pt_BR',
    'pt_PT',
    'ro_RO',
    'ru_EE',
    'ru_LT',
    'ru_LV',
    'ru_RU',
    'sk_SK',
    'sl_SI',
    'sq_AL',
    'sr_RS',
    'sv_SE',
    'tr_TR',
    'uk_UA',
    'zh_CN',
    'zh_HK',
    'zh_TW'
];

export default defaultAvailableLanguages;
