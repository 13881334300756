import { Inject, NgZone, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IRSIConfigData } from './rsi.config';
import { IEndpointConfiguration, RSI } from './rsi';

@Injectable()
export class RSIFactory {
  private rsis: { [index: string]: RSI } = {};

  constructor(private httpClient: HttpClient, private zone: NgZone) {
  }

  get(name: string, c?: IRSIConfigData | string): RSI {
    let config: IRSIConfigData;
    if (!this.rsis[name]) {
      if (typeof c === 'string') {
        const u: URL = new URL(c);
        const pathname = u.pathname === '/' ? '' : u.pathname;
        config = {
          server: {
            protocol: u.protocol.substring(0, u.protocol.length - 1),
            port: parseInt(u.port, 10),
            address: u.hostname,
            socketprotocol: 'ws',
            api: pathname
          }
        };
      } else {
        config = c;
      }
      this.rsis[name] = new RSI(this.httpClient, this.zone, config);
    }
    return this.rsis[name];
  }
}
