const ServiceManagementDisableReason = {
    noActiveLicense: 'noActiveLicense' as 'noActiveLicense',
    localVehicleDisabled: 'localVehicleDisabled' as 'localVehicleDisabled',
    switchedOff: 'switchedOff' as 'switchedOff',
    termsAndConditionsNotAccepted: 'termsAndConditionsNotAccepted' as 'termsAndConditionsNotAccepted',
    primaryUserNotVerified: 'primaryUserNotVerified' as 'primaryUserNotVerified',
    initiallyDisabled: 'initiallyDisabled' as 'initiallyDisabled',
    deactivated: 'deactivated' as 'deactivated',
    privacyConflict: 'privacyConflict' as 'privacyConflict'
};

type ServiceManagementDisableReason = (typeof ServiceManagementDisableReason)[keyof typeof ServiceManagementDisableReason];
export { ServiceManagementDisableReason };

