import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output, Renderer2, AfterContentInit, NgZone, OnDestroy } from '@angular/core';
import { GesturesService } from '../gestures.service';
import 'hammerjs';
import * as IScroll from '@shift/iscroll';

interface DirectionMappings {
    dim: 'height' | 'width';
    positionRef: 'top' | 'left';
    deltaAxis: 'deltaY' | 'deltaX';
    translateAxis: 'translateY' | 'translateX';
    velocityAxis: 'velocityY' | 'velocityX';
}

const HorizontalMappings: DirectionMappings = {
    dim: 'width',
    positionRef: 'top',
    deltaAxis: 'deltaX',
    translateAxis: 'translateX',
    velocityAxis: 'velocityX'
};

const VerticalMappings: DirectionMappings = {
    dim: 'height',
    positionRef: 'left',
    deltaAxis: 'deltaY',
    translateAxis: 'translateY',
    velocityAxis: 'velocityY'
};

/**
 * When using horizontal rubberband the host element should have 'display: inline-block;'.
 */
@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[shiftScroll]'
})
export class ShiftScrollDirective implements AfterContentInit, OnDestroy {
    /**
     * Input to set direction of the scroll-list.
     */
    @Input()
    set direction(d: string) {
        this.vertical = d !== 'horizontal';
        this.map = this.vertical ? VerticalMappings : HorizontalMappings;
    }

    /**
     * Switch to disable rubber-band feature for list
     */
    @Input() rubberband = true;

    /**
     * Switch to disable auto-scrolling for list
     */
    @Input() autoscroll = true;

    /**
     * Switch to disable scrolling
     */
    @Input() disableScrolling = false;

    /**
     * Switch to show/hide custom scrollbar
     */
    @Input() showCustomScrollbar = true;

    /**
     * Set selector ('shift-selector') or class with '.class' to enable scrollsnapping to these elements.
     * Examples [snapElement]="'shift-item'" will snapp to every shift-item.
     * [snapElement]="'.item'" will snap to every element which has the class 'item'.
     */
    @Input() snapElement: string;

    /**
     * Sets the scrollWrapper Element for the list.
     */
    @Input() snapContainer: string;

    /**
     * Sets the offSet for snapping to allow seeing for example top-border at shift-item.
     * You can use positive and negative values. 0 will provide no offset.
     */
    @Input() snapOffset: number;

    /**
     * Emits event when scrolling has ended
     */
    @Output() scrollEnd: EventEmitter<any> = new EventEmitter<any>();

    private vertical = true;
    private map: DirectionMappings;
    private parent: any;

    /**
     * IScroll instance
     */
    public myScroll: IScroll;

    /**
     * Reference to the scrollWrapper Element
     */
    private scrollWrapper: Element;

    /**
     * Property that saves the adjusted ClientRects of the snapping elements
     */
    private snapElementsRects: Array<AdjustableClientRect> = [];

    /**
     * Reference to all snapping elements 
     */
    private snapElements: any;

    /**
     * Reference to the shift-header
     */
    private header: Element;


    /**
     * Constructor of the ShiftScrollDirective
     * @param host
     * @param gesturesService
     * @param renderer
     */
    constructor(private host: ElementRef,
        private gesturesService: GesturesService,
        private renderer: Renderer2,
        private zone: NgZone,
        private el: ElementRef) {
        this.map = this.vertical ? VerticalMappings : HorizontalMappings; // set map even if direction is not set as an input
    }

    /**
     * OnDestroy the iScroll instance will be destroyed
     */
    ngOnDestroy() {
        this.myScroll.destroy();
    }

    /**
     * Lifecycle hook implementation of AfterContentInit, where touch events will be added to directive.
     */
    ngAfterContentInit() {
        const native: Element = this.el.nativeElement;
        this.scrollWrapper = native.children[0];
        this.snapElements = this.scrollWrapper.querySelectorAll(this.snapElement);
        this.header = document.querySelector('shift-header');

        this.zone.runOutsideAngular(() => {
            this.myScroll = new IScroll(native, {
                scrollX: false,
                scrollY: !this.disableScrolling,
                deceleration: 0.003,
                bounce: this.rubberband,
                bounceEasing: 'circular',
                bounceTime: 200,
                scrollbars: this.showCustomScrollbar ? 'custom' : null,
                mouseWheel: true,
                interactiveScrollbars: true,
                preventDefaultException: { tagName: /.*/ }
            });

            this.myScroll.on('autoscrollStart', (e: AutoScrollEvent) => {
                if (e.time < 20) {
                    e.time = 200;
                }
    
                e.scrollToY = this.calculatePosition(e);
                const snapCalculations = true;
                this.myScroll.scrollTo(e.scrollToX, e.scrollToY, e.time, e.easing, snapCalculations);
            });

            this.myScroll.on('scrollEnd', ()=> {
                this.scrollEnd.emit();
            });
    
            this.myScroll.on('heightChange', () => {
                this.header = document.querySelector('shift-header');
                this.myScroll.refresh();
                this.snapElementsRects.splice(0);
                let headerBottom = 0;
                if(this.header) {
                    headerBottom = this.header.getBoundingClientRect().bottom;
                }
                this.snapElements = this.scrollWrapper.querySelectorAll(this.snapElement);
    
                for(let i=0; i < this.snapElements.length; i++) {
                    const fixedClientRect: ClientRect = this.snapElements[i].getBoundingClientRect();
                    const singleSnapRect: AdjustableClientRect ={
                        bottom: fixedClientRect.bottom - headerBottom - this.myScroll.getY(),
                        height: fixedClientRect.height,
                        left: fixedClientRect.left - this.myScroll.getX(),
                        right: fixedClientRect.right - this.myScroll.getX(),
                        top: fixedClientRect.top - headerBottom - this.myScroll.getY(),
                        width: fixedClientRect.width
                    }
                    this.snapElementsRects.push(singleSnapRect);
                } 
            });
        });

        this.scrollWrapper.addEventListener('touchstart', (e: Event) => {
            if(this.gesturesService.modalOpen) {
                e.stopPropagation();
            }
        });
    }

    /**
 * Function that adjusts the final scroll position of @Input snapElement
 * @param scrollTo planned scrolling position
 * @param rects boundingClientRects of @Input snapElement
 */
    private calculatePosition(event: AutoScrollEvent): number {
        let nearestDistance: number = 99999;
        let nearestElement;
        let checkBottom = false;

        let rects = this.snapElementsRects.filter((snapElement: AdjustableClientRect) => {
            if (Math.abs(event.scrollToY + snapElement.top) <= snapElement.height / 2) {
                return true;
            }
        });

        if (rects.length === 0) {
            rects = this.snapElementsRects.filter((snapElement: AdjustableClientRect) => {
                if (Math.abs(event.scrollToY + snapElement.bottom) <= snapElement.height / 2) {
                    return true;
                }
            });
            checkBottom = true;
        }

        for (let rect of rects) {
            if (Math.abs(rect.top) < nearestDistance) {
                nearestDistance = Math.abs(rect.top);
                nearestElement = rect;
            }
        }

        if (nearestElement) {
            const diff = event.scrollToY + (checkBottom ? nearestElement.bottom : nearestElement.top);
            event.scrollToY = event.scrollToY - diff;
        }

        return event.scrollToY;
    }
}

/**
 * Interface that describes the property that are necessary to calculate correct snapping position
 */
interface AutoScrollEvent {
    scrollToX: number,
    scrollToY: number,
    scrollFromX: number,
    scrollFromY: number,
    time: number,
    easing: string
}

/**
 * Interface to save adjusted ClientRects information (ClientRect itself is read-only)
 */
interface AdjustableClientRect {
    bottom: number,
    height: number,
    left: number,
    right: number,
    top: number,
    width: number
}