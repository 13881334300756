import { EventEmitter } from '@angular/core';

/** The priority values that can be used by Popup2Model.priority */
export enum Popup2Priority {
  blocking = 0,
  normal = 1
  // update 'numberOfPriorities' if you enhance the enum
}

/**
 * A model class which represents the configuration values of a popup.
 * It is not to be created directly since it has a private constructor.
 * If you want an instance, use one of the static create...() functions.
 * @example
 * const newPopup = Popup2Model.create4OneButton('Hello Popup', 'OK')
 *
 * Subscribe on button events:
 * @example
 * const anotherPopup = Popup2Model.create4NButtons('Hello Popup', 'Option1', 'Option2', 'Option3')
 * anotherPopup.buttonClickedEvents[0].subscribe(()=>{ handleOption1(); });
 * anotherPopup.buttonClickedEvents[1].subscribe(()=>{ handleOption2(); });
 * anotherPopup.buttonClickedEvents[2].subscribe(()=>{ handleOption3(); });
 *
 * Set popup to be higher prio than the others
 * @example
 * anotherPopup.setPriorityBlocking();
 *
 * Use public access to the member variables if you want to adjust further settings.
 */
export class Popup2Model {
  /** the currently used number of available priorities */
  public static numberOfPriorities = 2;

  /** array of button text labels */
  private buttonTextLabels: string[] = [];
  /** array of button clicked event emitters */
  private buttonClickedEventEmitters: EventEmitter<any>[] = [];

  /** flag showing if the popup shall be closed (if allready shown) or not shown at all */
  public skipOrClose = false;
  /** event which is triggered on popup close */
  public closedEvent = new EventEmitter<any>();
  /** the popup text; each array entry is a new paragraph */
  public textLines: string[] = [];
  /** the asset path of the image to be shown in popup */
  public imageSourcePath: string;
  /** the priority of the popup; default is 'normal' */
  public priority: Popup2Priority = Popup2Priority.normal;
  /** show popup whith full screen width */
  public fullWidth = false;
  /** set a certain fixed popup height */
  public fixedHeight = false;
  /** show a loading indicator in popup */
  public showLoadingIndicator = false;
  /** the 'debounce' time in milliseconds that the popup is shown at least; default=1500ms */
  public showAtLeastNMilliseconds = 1500;
  /** the timeout in milliseconds after which the popup shall be closed */
  public closeAfterNMilliseconds: number = undefined;

  /** @returns the text labels of all defined buttons as array */
  public get buttonTexts(): string[] {
    return this.buttonTextLabels;
  }

  /** @returns the number of defined buttons */
  public get numberOfButtons(): number {
    return this.buttonTexts.length;
  }

  /** @returns 'true' if the popup has got an image */
  public get withImage(): boolean {
    return this.imageSourcePath !== undefined;
  }

  /** @returns an array of event emitters whcih are triggered on button clicked. The array is ordered the same like the button texts array*/
  public get buttonClickedEvents(): EventEmitter<any>[] {
    return this.buttonClickedEventEmitters;
  }

  private constructor() { }

  /**
   * Create a simple popup having text and one button.
   * @param text the text to be shown in popup
   * @param buttonText the button label to be used
   */
  public static create4OneButton(text: string, buttonText: string): Popup2Model {
    const popup = new Popup2Model();
    popup.textLines.push(text);
    popup.setButtonTexts(buttonText);
    return popup;
  }

  /**
   * Create a popup having text and up to three buttons.
   * @param text the text to be shown in popup
   * @param buttonText1 the button label to be used for the right most button
   * @param buttonText2 the button label to be used for the button left of button1
   * @param buttonText3 the button label to be used for the button left of button2
   */
  public static create4NButtons(text: string, buttonText1: string, buttonText2?: string, buttonText3?: string): Popup2Model {
    const popup = new Popup2Model();
    popup.textLines.push(text);
    popup.setButtonTexts(buttonText1, buttonText2, buttonText3);
    return popup;
  }

  /**
   * Create a popup having text, an image and up to three buttons.
   * @param text the text to be shown in popup
   * @param imagePath the asset path where the image can be found
   * @param buttonText1 the button label to be used for the right most button
   * @param buttonText2 the button label to be used for the button left of button1
   * @param buttonText3 the button label to be used for the button left of button2
   */
  public static create4NButtonsWithImage(text: string, imagePath: string,
    buttonText1: string, buttonText2?: string, buttonText3?: string): Popup2Model {

    const popup = new Popup2Model();
    popup.textLines.push(text);
    popup.imageSourcePath = imagePath;
    popup.setButtonTexts(buttonText1, buttonText2, buttonText3);
    return popup;
  }

  /**
   * Create a popup having only text and a loading animation, no buttons.
   * @param text the text to be shown in popup
   * The popup has full screen width. It is not closed automatically. It must be closed manually.
   */
  public static create4NoButtonLoading(text: string): Popup2Model {
    const popup = new Popup2Model();
    popup.textLines.push(text);
    popup.fullWidth = true;
    popup.showLoadingIndicator = true;
    return popup;
  }

  /**
   *
   * @param text Create a popup with only text and without any buttons.
   * Closes automatically after the defined timeout.
   * @param closeAfterNMilliseconds milliseconds that the popup should be shown at least
   */
  public static create4NoButtonsTimedInfo(text: string, closeAfterNMilliseconds: number): Popup2Model {
    const popup = new Popup2Model();
    popup.textLines.push(text);
    popup.closeAfterNMilliseconds = closeAfterNMilliseconds;
    return popup;
  }

  /**
   * Set button texts of the popup. Make sure that button text and click event arrays are increased in parallel
   */
  private setButtonTexts(buttonText1: string, buttonText2?: string, buttonText3?: string) {
    this.addButton(buttonText1);
    if (buttonText2) {
      this.addButton(buttonText2);
    }
    if (buttonText3) {
      this.addButton(buttonText3);
    }
  }

  /**
   * Add one button to the popup. Increase both, button text and click event arrays.
   */
  private addButton(text: string) {
    this.buttonTextLabels.push(text);
    this.buttonClickedEvents.push(new EventEmitter<any>());
  }

  /**
   * Increase the priority of the popup from default-'normal' to 'blocking'.
   */
  public setPriorityBlocking() {
    this.priority = Popup2Priority.blocking;
  }
}
