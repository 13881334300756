import { Component, AfterContentInit, ContentChild, ViewChild } from '@angular/core';
import { ShiftMainComponent } from '../main/main.component';
import { ShiftFooterNButtonsComponent } from '../footer/footer-n-buttons/footer-n-buttons.component';
import { ShiftFooterNearingBarComponent } from '../footer/footer-nearing-bar/footer-nearing-bar.component';
import { ShiftScrollDirective } from '../../gestures/scroll/scroll.directive';

/**
 * Shift screen is a component that CAN contain a shift-header, shift-main and a footer.
 * It will automatically put the header at top, footer at bottom and the rest of the screen will be 
 * used by the main. It is a good practice to use a router-outlet in your main where the main content will be changed.
 * For the header and footer you provide dynamic content depending in which route you are.
 * @example
 * <shift-screen>
 *                  <shift-header *ngIf="yourHeaderShownVariable" [title]="yourDynamicHeaderVariable"></shift-header>
 *                  <shift-main>
 *                      <router-outlet></router-outlet>
 *                  </shift-main>
 *                  <shift-footer-n-buttons *ngIf="yourFooterShownVariable" [buttonTexts]="yourDynamicButtonTexts"></shift-footer-n-buttons>
 * </shift-screen>
 */
@Component({
  selector: 'shift-screen, shift-screen-wizard',
  template: `<ng-content></ng-content>`,
  styles: [``]
})
export class ShiftScreenComponent implements AfterContentInit {

  /**
   * Reference to content element <shift-main>
   */
  @ContentChild(ShiftMainComponent) mainElement: ShiftMainComponent;

  /**
   * Reference to content element <shift-main>
   */
  @ViewChild(ShiftScrollDirective) scrollDirective: ShiftScrollDirective;
  
  /**
   * Reference to content element <shift-footer-n-buttons>
   */
  @ContentChild(ShiftFooterNButtonsComponent) footerElement: ShiftFooterNButtonsComponent;
  
  /**
   * Reference to content element <shift-footer-nearing-bar>
   */ 
  @ContentChild(ShiftFooterNearingBarComponent) controlbarElement: ShiftFooterNearingBarComponent;


  /**
   * Constructor for shift screen
   */
  constructor() { }

  /**
   * Default implementation of AfterContentInit
   */
  ngAfterContentInit() {
    if(this.mainElement) {
      if(this.footerElement || this.controlbarElement) {
        this.mainElement.hasFooter = true;
        if(this.footerElement) {
          this.footerElement.disabledScrollbar = this.mainElement.disableScrolling || !this.mainElement.showCustomScrollbar;
        }
      } else {
        this.mainElement.hasFooter = false;
      }
    }
  }

  ngAfterViewChecked() {
    if(this.mainElement) {
      this.mainElement.refreshScroll();
    }
  }
}
