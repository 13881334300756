import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShiftRangeComponent } from './range.component';

@NgModule({
    imports: [CommonModule],
    declarations: [ShiftRangeComponent],
    exports: [ShiftRangeComponent]
})
export class ShiftRangeModule {}
