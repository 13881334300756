import { Injectable } from '@angular/core';

// shift
import { RSIEndpoint, RSIService } from '@shift/rsi';
import { MibLogService } from '@shift/core';
import { RunStateEnum } from './run-state.enum';
import { webappmanagement } from '../../interfaces/webappmanagement';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable()
export class RunStateService {
  public runState = new BehaviorSubject<string>('');

  private endpoint: RSIEndpoint<webappmanagement.IEngineInstanceObject>;
  private sub: Subscription;
  private engineInstanceUri: string;
  private beforeRunningHooks: Array<() => Promise<void>> = [];
  private beforeSuspendHooks: Array<() => Promise<void>> = [];

  constructor(private rsi: RSIService, private logger: MibLogService) {
  }

  /**
   * Init Service with runStateUri
   * @param uri string
   * */
  public init(uri: string) {
    if (this.engineInstanceUri === uri && this.endpoint && this.sub) {
      return;
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }

    // warn user when app is started without engineinstanceURI
    if (!uri || uri === '') {
      console.warn('!! No engineinstanceURI defined. Runstate could not be set.');
      return;
    }

    if (uri.charAt(0) !== '/') {
      uri = '/' + uri;
    }
    this.subscribeToEngineInstance(uri);
  }

  /**
   * @deprecated Use init(uri) instead. This method will be removed soon!
   */
  public setEngineInstanceURI(uri: string) {
    this.init(uri);
  }

  /**
   * Subscribe on RSI's engineInstance endpoint to react to status change requests
   * While startup sets the app's status (runState) to "running"
   * While startup hides native statusBar
   * @param uri string
   */
  private subscribeToEngineInstance(uri: string): void {
    console.log('>> Set engineinstanceURI to: ' + uri);
    this.engineInstanceUri = uri;

    this.endpoint = this.rsi.endpoint(uri, {autoGetData: false});
    this.sub = this.endpoint
      .subscribe(
        (data: webappmanagement.IEngineInstanceObject) => this.getRunState(data),
        error => this.logger.error(`>> Subscription failed! Error: ${error}`, 'runstate_service-subscribeToEngineInstance')
      );
  }

  private async getRunState(data: webappmanagement.IEngineInstanceObject) {
    console.log('>> EngineInstanceObject', data);
    if (data.runState === RunStateEnum.SuspendRequested && this.runState.getValue() !== '') {
      await Promise.all(this.beforeSuspendHooks.map(x => x()));
      this.setRunState(RunStateEnum.Suspended);
      this.logger.info('>> App status is now suspended.');
    }

    if (this.runState.getValue() === '' || data.runState === RunStateEnum.StartRequested) {
      await Promise.all(this.beforeRunningHooks.map(x => x()));
      this.endpoint.updateElement({ runState: RunStateEnum.Running, statusBarVisible: false });
      this.logger.info('>> App status is now running.');
      this.logger.info('>> Native statusbar is now hidden.');
    }

    this.runState.next(data.runState);
  }

  /**
   * Tell hmi if native speller (NHTSA) is open, so hmi should handle it.
   * Eg. Block it while car is driving
   * @param handle boolean
   */
  public hmiHandleNHTSA(handle: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.endpoint) {
        this.endpoint.updateElement({ hmiHandleNHTSA: handle }).subscribe(
          () => resolve(),
          (error: Error) => {
            console.log('Update hmiHandleNHTSA failed: ', error);
            reject(error);
          }
        );
      } else {
        console.warn('!! No engineInstancesEndpoint defined. Could not handle NHTSA');
        this.logger.warn('!! No engineInstancesEndpoint defined. Could not handle NHTSA');
        reject(new Error('UPDATE /webappmanagement/engineinstances/ \n NO_ENGINE_INSTANCE_URI_SET'));
      }
    });
  }

  /**
   * tell RSI that app's status (runState) has changed
   * @param state RunState
   */
  private setRunState(state: RunStateEnum): void {
    if (this.endpoint) {
      this.endpoint.updateElement({ runState: state });
    }
  }

  addBeforeRunning(hook: () => Promise<void>) {
    this.beforeRunningHooks.push(hook);
  }


  addBeforeSuspend(hook: () => Promise<void>) {
    this.beforeSuspendHooks.push(hook);
  }
}
