import { Injectable } from '@angular/core';
import { RSIService } from '@shift/rsi';
import { drivingstate } from '../../interfaces/drivingstate';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DrivingStateService {
    public isDriving = new BehaviorSubject<boolean>(false);

    constructor(private rsi: RSIService) {
    }

    public init() {
        this.subscribeForData();
    }

    private subscribeForData(): void {
        const endpoint = this.rsi.firstEndpoint('/drivingstate/vehicleDynamics/', {'autoGetData': false});
        endpoint.subscribe((data: drivingstate.IVehicleDynamicObject) => {
            this.onDrivingStateChanged(data);
        });
    }

    private onDrivingStateChanged(data: drivingstate.IVehicleDynamicObject): void {
        this.isDriving.next(!data.vehicleIsStandingStill);
    }
}
