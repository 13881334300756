import { HttpClient } from '@angular/common/http';
import { NgZone } from '@angular/core';
import { RSIEndpoint } from './rsi.endpoint';
import { IRSIConfigData, RSIConfig } from './rsi.config';
import { ServiceLocator } from './service.locator';

export interface IEndpointConfiguration {
  autoGetData?: boolean;
  autoSubscribe?: boolean;
  reconnectCount?: number;
  reconnectDelay?: number;
  interval?: number;
  updatelimit?: number;
  Authorization?: string;
}

export class RSI {
  config: RSIConfig;
  serviceRegistry: ServiceLocator;
  endpoints: Array<RSIEndpoint<any>> = [];

  private defaultEndpointConfig: IEndpointConfiguration = {
    autoGetData: true,
    autoSubscribe: true,
    reconnectCount: 100,
    reconnectDelay: 3000
  };

  constructor(private http: HttpClient, zone: NgZone, config?: IRSIConfigData) {
    if (config) {
      this.config = new RSIConfig(config);
    } else {
      this.config = new RSIConfig();
    }
    this.serviceRegistry = new ServiceLocator(this.config, http, zone);
  }

  public endpoint(target: string, endpointConfig?: IEndpointConfiguration | boolean): RSIEndpoint<any> {
    let conf: any;
    if (typeof endpointConfig === 'boolean') {
      conf = {
        autoGetData: endpointConfig,
        autoSubscribe: endpointConfig
      };
    } else if (endpointConfig) {
      conf = endpointConfig;
    } else {
      conf = this.defaultEndpointConfig;
    }
    const defaultConfigClone: IEndpointConfiguration = Object.assign({}, this.defaultEndpointConfig);
    conf = Object.assign(defaultConfigClone, conf);
    const newEndpoint: RSIEndpoint<any> = new RSIEndpoint(this.http, this.serviceRegistry);
    newEndpoint.initialize(target, conf);
    this.endpoints.push(newEndpoint);
    return newEndpoint;
  }

  public firstEndpoint(
    target: string,
    endpointConfig: IEndpointConfiguration = this.defaultEndpointConfig
  ): RSIEndpoint<any> {
    const defaultConfigClone: IEndpointConfiguration = Object.assign({}, this.defaultEndpointConfig);
    endpointConfig = Object.assign(defaultConfigClone, endpointConfig);
    const resultEndpoint = new RSIEndpoint(this.http, this.serviceRegistry);
    this.endpoint(target, { autoSubscribe: false }).subscribe((data: any) => {
      // For now expand is hardcoded to 4 should be extracted from the target string
      resultEndpoint.initialize(data[0].uri + '?$expand=3', endpointConfig);
    });
    return resultEndpoint;
  }

  updateConfig(configData: IRSIConfigData) {
    this.config.update(configData);
  }
}
