import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ShiftButton } from './button';

export * from './button';

@NgModule({
    imports: [CommonModule],
    exports: [ShiftButton],
    declarations: [ShiftButton]
})
export class ShiftButtonModule {
    /** @deprecated */
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ShiftButtonModule,
            providers: []
        };
    }
}
