import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { WebappConfig } from './services/webapp.config';
import { BackgroundService } from './services/background.service';

export function webappConfigProviderFactory(provider: WebappConfig) {
  const returnFunc = () => {
    return provider.load();
  };
  return returnFunc;
}


@NgModule({
  declarations: [],
  imports: [],
  providers: []
})
export class WebappModule {
  // @dynamic

  static forRoot(config: any): ModuleWithProviders {
    return {
      ngModule: WebappModule,
      providers: [
        BackgroundService,
        { provide: 'WEBAPP_ENVIRONMENT', useValue: config }, WebappConfig,
        { provide: APP_INITIALIZER, useFactory: webappConfigProviderFactory, deps: [WebappConfig], multi: true }]
    };
  }
}

