import { NgModule, ModuleWithProviders } from '@angular/core';
import { PressGestureDirective } from './press-gesture.directive';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule],
    exports: [PressGestureDirective],
    declarations: [PressGestureDirective]
})
export class PressGestureModule {
    /** @deprecated */
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: PressGestureModule,
            providers: []
        };
    }
}
