/**
 * Class that defines the popup model.
 */
export class PopupModel {
    /**
     *
     */
    public textId: string;

    /**
     * Stores information if popup should be visible.
     */
    public visible: boolean;

    /**
     * Stores information if popup should be have footer.
     */
    public withFooter: boolean;

    /**
     * Stores information about confirm button text.
     */
    public confirmBtnText: string;

    /**
     * Stores information about abort button text.
     */
    public abortBtnText: string;

    /**
     * Stores information if abort button should be visible.
     */
    public hideAbortBtn: boolean;

    /**
     * Stores information if popup should be visible over the whole width of the screen.
     */
    public blocking: boolean;

    /**
     * Switch for showing footer with buttons in popup
     */
    public showLoadingIdc: boolean;

    /**
     * Stores information about the text that should be shown. 
     */
    public text: string;

    /**
     * Stores information about the text lines that should be shown.
     */
    public texts: string[];

    /**
     * Stores information that popup should be active.
     */
    public active: boolean;

    /**
     * Stores information about the image source that should be shown.
     */
    public imageSrc: string;

    /**
     *  Constructor for popup model.
     */
    constructor(
        textId: string,
        blocking: boolean,
        imageSrc?: string,
        confirmBtnTxt?: string,
        showAbortBtn?: boolean,
        abortBtnTxt?: string
    ) {
        this.textId = textId;
        this.visible = false;
        this.withFooter = !blocking;
        this.blocking = blocking;
        this.confirmBtnText = confirmBtnTxt ? confirmBtnTxt : 'unset';
        this.hideAbortBtn = !showAbortBtn;
        this.abortBtnText = abortBtnTxt ? abortBtnTxt : 'unset';
        this.text = '';
        this.texts = [];
        this.active = true;
        this.imageSrc = imageSrc ? imageSrc : '';
    }
}
