import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { GesturesService } from '../../gestures/gestures.service';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';

/**
 * Range component can show differnt kind of ranges. It has for example an output 'onValueChanged' that you can listen to.
 * @example
 * <shift-screen>
 *                  <shift-main>
 *                      <shift-range (onValueChanged)="yourChangeValueFunction($event)"></shift-range>
 *                      <!-- MORE OF YOUR CONTENT -->
 *                  </shift-main>
 * </shift-screen>
 */
@Component({
  selector: 'shift-range',
  template: `
<!-- LEFT BUTTON -->
<div *ngIf="showLeftButton" (click)="moveDown()" [style.order]="invert?2:0" class="left-btn-wrapper">
    <div *ngIf="!btnDownRef || btnDownRef.children.length==0" class="btn btn-up">-
    </div>
    <div #btnDownRef>
        <ng-content select="[btn-down]"></ng-content>
    </div>
</div>


<div class="track" #track >
    <!-- TRACK LINE -->
    <div class="track-line" [class.vertical]="isVertical" *ngIf="showTrackLine"
         [style.left]="!isVertical?thumbRect?.width/2+'px':'50%'"
         [style.right]="!isVertical?thumbRect?.width/2+'px':'50%'"
         [style.top]="isVertical?thumbRect?.height/2+'px':'50%'"
         [style.bottom]="isVertical?thumbRect?.height/2+'px':'50%'">
    </div>

    <!-- TICKS -->
    <div #tickWrapper class="tick-wrapper" (click)="onTrack($event)"
         [style.left]="!isVertical?thumbRect?.width/2+'px':'0'"
         [style.right]="!isVertical?thumbRect?.width/2+'px':'0'"
         [style.top]="isVertical?thumbRect?.height/2+'px':'0'"
         [style.bottom]="isVertical?thumbRect?.height/2+'px':'0'">

        <div class="indicator-line horizontal"
             *ngIf="showTrackIndicator && !isVertical"
             [style.width]="percentage*100+'%'"
             [class.animate]="shouldAnimate"></div>

        <div class="indicator-line vertical"
             *ngIf="showTrackIndicator && isVertical"
             [style.height]="percentage*100+'%'"
             [class.animate]="shouldAnimate"></div>

        <ng-container *ngIf="showTicks">
            <div class="tick"
                 *ngFor="let tick of ticks"
                 [style.left]="!isVertical?tick.percentage+'%':'50%'"
                 [style.top]="isVertical?tick.percentage+'%':'50%'"
                 [class.active]="value>=tick.value"
                 [class.vertical]="isVertical">
            </div>
        </ng-container>

        <!-- THUMB -->
        <div class="thumb-wrapper" [class.vertical]="isVertical" #thumb touch-action [class.animate]="shouldAnimate">
            <div [style.transform]="!isVertical?'translateX(-50%)':'translateY(-50%)'">
            <div class="thumb" *ngIf="(!thumbRef || thumbRef.children.length==0)"  ></div>
            <div #thumbRef>
                <ng-content select="[thumb]"></ng-content>
            </div>
            </div>
        </div>
    </div>
</div>

<!-- RIGHT BUTTON -->
<div *ngIf="showRightButton" [style.order]="invert?0:2" (click)="moveUp()" class="right-btn-wrapper">
    <div *ngIf="!btnUpRef || btnUpRef.children.length == 0"  class="btn btn-down">+</div>

    <div #btnUpRef  >
        <ng-content select="[btn-up]"></ng-content>
    </div>
</div>
`,
  styles: [``]
})
export class ShiftRangeComponent implements AfterViewInit, OnDestroy {

  /**
   * Gets the number of ticks
   */
  get numTicks(): number {
    return this._numTicks;
  }

  /**
   * Sets the number of ticks/levels.
   */
  @Input()
  set numTicks(value: number) {
    const total: number = this._max - this._min;
    this._stepSize = Math.round(total / value);
    this._numTicks = value;
    this.updateTicks();
  }

  /**
   * Gets the minimum value.
   */
  get min(): number {
    return this._min;
  }

  /**
   * Sets the minimum value.
   */
  @Input()
  set min(value: number) {
    this._min = Number(value);
  }

  /**
   * Gets the maximium value.
   */
  get max(): number {
    return this._max;
  }

  /**
   * Sets the maximum value.
   */
  @Input()
  set max(value: number) {
    this._max = Number(value);
  }

  /**
   * Sets the stepSize.
   */
  @Input()
  set stepSize(value: number) {
    this._stepSize = Number(value);
    const total: number = this._max - this._min;
    this._numTicks = total / this._stepSize;

    setTimeout(() => {
      this.updateTicks();
    }, 10);
  }

  /**
   * Gets the stepSize.
   */
  get stepSize(): number {
    return this._stepSize;
  }

  /**
   * Gets if range is vertical.
   */
  get isVertical(): boolean {
    return this._isVertical;
  }

  /**
   * Sets if range should be vertical.
   */
  @Input()
  set isVertical(value: boolean) {
    this._isVertical = value;
    this.measure();
    this.updateValue(this._value);
    setTimeout(() => {
      this.measure();
      this.updateValue(this._value);
    }, 10);
  }

  /**
   * Gets information if range is inverted.
   */
  get invert(): boolean {
    return this._invert;
  }

  /**
   * Sets range as inverted.
   */
  @Input()
  set invert(value: boolean) {
    this._invert = value;
    setTimeout(() => {
      this.measure();
      this.updateValue(this._value - this._min);
    }, 10);
  }

  /**
   * Gets the current value.
   */
  get value(): number {
    return this._value;
  }

  /**
   * Sets the current value.
   */
  @Input()
  set value(value: number) {
    if (!this._inited) {
      this._value = value;
      return;
    }
    value = Math.max(Number(this._min), value);
    value = Math.min(Number(this._max), value);
    this.updateValue(value - Number(this._min));
  }

  /**
   * Gets the current value as percentage.
   */
  get percentage(): number {
    return this._percentage;
  }

  /**
   * Sets the current value as percentage.
   */
  set percentage(value: number) {
    this._percentage = value;
  }

  /**
   * Gets information if thumb is animated.
   */
  get animateThumb() {
    return !this.isDragging && this.animate;
  }

  /**
   * Gets information if 'vertical' class is applied to range component.
   */
  @HostBinding('class.vertical')
  get useVerticalClass() {
    return this._isVertical;
  }

  /**
   * Switch to show and control the left button.
   */
  @Input() public showLeftButton = false;

  /**
   * Switch to show and control the right button.
   */
  @Input() public showRightButton = false;

  /**
   * Input to show the ticks.
   */
  @Input() public showTicks = true;

  /**
   * Input to show the track line
   */
  @Input() public showTrackLine = true;

  /**
   * Input to have fixed steps
   */
  @Input() public fixSteps = true;

  /**
   * Input to animate the range component.
   */
  @Input() public animate = true;

  /**
   * Gets information if range should be animated.
   */
  public get shouldAnimate() {
    if (this.animate && !this.isDragging && this.afterSetup) {
      return true;
    }
    return false;
  }

  /**
   * Input to show the track indicator.
   */
  @Input() public showTrackIndicator = true;

  /**
   * Event emits when percentage has changed.
   */
  @Output() public onPercentageChanged: EventEmitter<number> = new EventEmitter<number>();

  /**
   * Event emits when value has changed.
   */
  @Output() public onValueChanged: EventEmitter<number> = new EventEmitter<number>();

  /**
   * Event emits when range has been initialized.
   */
  @Output() public onInit: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Information about dragging state
   */
  public isDragging = false;

  /**
   * Information about setup stage.
   */
  private afterSetup = false;

  /**
   * ElementRef of the thumb of the range component.
   */
  @ViewChild('thumb') public thumb: ElementRef;

  /**
   * ElementRef of the track of the range component.
   */
  @ViewChild('track') public track: ElementRef;

  /**
   * ElementRef of the thumb track of the range component.
   */
  @ViewChild('thumbTrack') public thumbTrack: ElementRef;

  /**
   * Elementref of the tickWrapper of the range component.
   */
  @ViewChild('tickWrapper') public tickWrapper: ElementRef;

  /**
   * Stores the number of ticks of range.
   */
  private _numTicks = 0;

  /**
   * Stores the directional state of range.
   */
  private _isVertical = false;

  /**
   * Stores minimum value of range.
   */
  private _min = 0;

  /**
   * Stores maximum value of range.
   */
  private _max = 100;

  /**
   * Stores step size of range.
   */
  private _stepSize: number;

  /**
   * Stores invert status of range.
   */
  private _invert = false;

  /**
   * Stores the percentage value of range.
   */
  private _percentage = 0;

  /**
   * Stores the value of range.
   */
  private _value = 0;

  public thumbRect: any;
  private trackRect: any;
  private elementRect: any;
  private tickWrapperRect: any;
  private subscriptions: Array<Subscription> = [];

  /**
   * Stores the ticks of the range.
   */
  public ticks: Array<any> = [];
  private _inited = false;

  /**
   * Constructor of the range component
   * @param el
   */
  constructor(private el: ElementRef, private gestureService: GesturesService) {
  }

  ngAfterViewInit(): void {
    setTimeout((_: any) => this.setup());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

  setupDragAndDrop() {
    const native: HTMLElement = this.el.nativeElement;

    const touchstart: Observable<Event> = fromEvent(native, 'touchstart');
    const mousedown: Observable<Event> = fromEvent(native, 'mousedown');

    const touchmove: Observable<Event> = fromEvent(document, 'touchmove');
    const mousemove: Observable<Event> = fromEvent(document, 'mousemove');

    const touchend: Observable<Event> = fromEvent(document, 'touchend');
    const mouseup: Observable<Event> = fromEvent(document, 'mouseup');


    const startEvent: Subscription = merge(touchstart, mousedown).subscribe((event: Event) => {
      event.stopPropagation();
      event.preventDefault();
      if (!this.isDragging) {
        this.isDragging = true;
        this.gestureService.gestureInProgress = true;
        this.measure();
      }
    });

    const moveEvent: Subscription = merge(touchmove, mousemove).subscribe((event: Event) => {
      event.stopPropagation();
      if (this.isDragging) {
        const touchMoveArea: any = event instanceof TouchEvent ? event.touches[0] : event;
        const targetPosition: number = this._isVertical ? touchMoveArea.clientY - this.trackRect.top : touchMoveArea.clientX - this.trackRect.left;
        this.positionToValue(targetPosition);
      }
    });

    const endEvent = merge(touchend, mouseup).subscribe((event: Event) => {
      this.isDragging = false;
    });

    this.subscriptions.push(startEvent);
    this.subscriptions.push(moveEvent);
    this.subscriptions.push(endEvent);
  }

  positionToValue(position: number) {
    let dragPercentage: number;
    let dragValue: number;
    const dimension: string = this._isVertical ? 'height' : 'width';
    const axis: string = this._isVertical ? 'top' : 'left';
    position = Math.max(position, 0);
    position = Math.min(this.trackRect[dimension], position);
    dragPercentage = position / (this.trackRect[dimension]);
    if (this._invert) {
      dragPercentage = 1 - dragPercentage;
    }
    dragValue = Number(dragPercentage * (this._max - this._min));
    this.updateValue(dragValue);
  }

  measure() {
    this.trackRect = this.track.nativeElement.getBoundingClientRect();
    this.thumbRect = this.thumb.nativeElement.getBoundingClientRect();
    this.elementRect = this.el.nativeElement.getBoundingClientRect();
    this.tickWrapperRect = this.tickWrapper.nativeElement.getBoundingClientRect();
  }

  updateValue(dragValue: number) {
    let prop = 'width';
    if (this._isVertical) {
      prop = 'height';
    }

    let fixedValue = dragValue;
    if (this._stepSize > 0 && this.fixSteps) {
      fixedValue = Math.round(dragValue / this._stepSize) * this._stepSize;
    }

    // fixedValue = Math.max(fixedValue, this._min);
    // fixedValue = Math.min(fixedValue, this._max );

    let fixedPercentage = fixedValue / (Number(this._max) - Number(this._min));
    if (this._invert) {
      fixedPercentage = 1 - fixedPercentage;
    }
    const fixedY = fixedPercentage * (this.trackRect[prop] - this.thumbRect[prop]);

    this._percentage = fixedPercentage;
    this.onPercentageChanged.emit(fixedPercentage);
    this._value = Number(this._min) + fixedValue;
    this.onValueChanged.emit(Number(this._min) + fixedValue);
    this.positionThumb(fixedY, fixedPercentage);
    setTimeout(() => {
      this.afterSetup = true;
    }, 400);
  }

  /**
   * Function that setup the ticks. Is called when changing the stepSize.
   */
  updateTicks() {
    this.ticks = [];
    if (this._stepSize === 0) {
      return;
    }
    const range: number = this._max - this._min;
    const tickCount: number = Math.round(range / this._stepSize);
    for (let i = 0; i <= tickCount; i++) {
      this.ticks.push({
        percentage: i / tickCount * 100,
        value: i * this.stepSize
      });
    }
  }

  /**
   * Function that positions the thumb.
   * @param pos
   * @param fixedPercentage
   */
  positionThumb(pos: number, fixedPercentage: number) {
    if (this._isVertical) {
      this.thumb.nativeElement.style.top = Math.abs(Number(fixedPercentage * 100)) + '%';
      this.thumb.nativeElement.style.transform = 'translateX(-50%)';
    } else {
      this.thumb.nativeElement.style.left = Math.abs(Number(fixedPercentage * 100)) + '%';
      this.thumb.nativeElement.style.transform = 'translateY(-50%)';
    }
  }

  /**
   * Function that sets the thumb one step up.
   */
  moveUp() {
    const step: number = this._stepSize > 0 ? this._stepSize : 1;
    const v: number = this._value - Number(this._min) + Number(step);
    if (v <= this._max) {
      this.updateValue(v);
    }
  }

  /**
   * Function that sets the thumb one step down.
   */
  moveDown() {
    const step: number = this._stepSize > 0 ? this._stepSize : 1;
    const v: number = this._value - Number(this._min) - Number(step);
    if (v >= this._min) {
      this.updateValue(v);
    }
  }

  /**
   * Function is called when clicking directly on the track.
   * @param evt
   */
  onTrack(evt: any) {
    if (evt.pointers && evt.pointers.length > 0) {
      evt = evt.pointers[0];
    }

    const trackRect = this.tickWrapper.nativeElement.getBoundingClientRect();
    const x = evt.pageX - trackRect.left;
    const y = evt.pageY - trackRect.top;

    // this.preventAnimation = false;
    let perc: number;
    if (!this._isVertical) {
      perc = x / trackRect.width;
    } else {
      perc = y / trackRect.height;
    }
    if (this.invert) {
      perc = 1 - perc;
    }

    const value: number = perc * (this.max - this.min);
    this.updateValue(value);
  }

  /**
   * Function that setups the range component.
   */
  private setup() {
    this.measure();
    this.setupDragAndDrop();
    this._value = Math.max(this._min, this._value);
    this.updateValue(this._value - Number(this._min));
    this._inited = true;
    this.onInit.emit(true);
  }
}
