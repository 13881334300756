import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'addPlural'})
export class AddPluralPipe implements PipeTransform {
  transform(idWithoutPlural: string, count: number): string {
    const pluralCategory = this.getPluralCategory(count);
    return idWithoutPlural + pluralCategory;
  }

  private getPluralCategory(count: number) {
    let pluralCategory = '.other';
    if (count === 0) { pluralCategory = '.zero'; }
    if (count === 1) { pluralCategory = '.one'; }
    if (count === 2) { pluralCategory = '.two'; }
    if (count > 2 && count <= 4) { pluralCategory = '.few'; }
    if (count > 4) { pluralCategory = '.many'; }
    return pluralCategory;
  }
}
