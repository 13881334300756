const UserRoleEnum = {
    primaryUser: 'primaryUser' as 'primaryUser',
    secondaryUser: 'secondaryUser' as 'secondaryUser',
    guestUserOnline: 'guestUserOnline' as 'guestUserOnline',
    anonymousGuestUser: 'anonymousGuestUser' as 'anonymousGuestUser',
    guestUserOffline: 'guestUserOffline' as 'guestUserOffline'
};

type UserRoleEnum = (typeof UserRoleEnum)[keyof typeof UserRoleEnum];
export { UserRoleEnum };
