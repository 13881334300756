const RunStateEnum = {
    Running: 'running' as 'running',
    StartRequested: 'startRequested' as 'startRequested',
    Suspended: 'suspended' as 'suspended',
    Stopped: 'stopped' as 'stopped',
    SwitchRequested: 'switchRequested' as 'switchRequested',
    SuspendRequested: 'suspendRequested' as 'suspendRequested'
};

type RunStateEnum = (typeof RunStateEnum)[keyof typeof RunStateEnum];
export { RunStateEnum };
