import { Directive, ElementRef, AfterViewInit, Output, EventEmitter, NgZone } from '@angular/core';

import { GesturesService } from '../gestures.service';

@Directive({
    selector: '[dragGesture]'
})
export class DragGestureDirective implements AfterViewInit {
    @Output() onDrag: EventEmitter<{}> = new EventEmitter();

    constructor(public el: ElementRef, private gesturesService: GesturesService, private ngZone: NgZone) {}

    /**
     * WARNING!
     * DragGesture does not use GesturesService!
     * It is not blocked by other gestures and does not prevent other gestures from starting.
     */
    ngAfterViewInit(): void {
        const hammertime: HammerManager = new Hammer(this.el.nativeElement);
        hammertime.on('hammer.input', (ev: HammerInput) => {
            this.ngZone.run(() => {
                this.onDrag.emit(ev);
            });
        });
    }
}
