import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
    transform(value: any): string {
        let s: number = parseInt(value, 10) / 1000;
        const m = Math.floor(s / 60);
        s -= m * 60;
        return (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s);
    }
}
