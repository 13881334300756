import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RSIService } from '@shift/rsi';
import { MibLogService } from '@shift/core';
import { servicemanagement } from '../../interfaces/servicemanagement';

@Injectable()
export class ServiceManagementService {
    public services: BehaviorSubject<servicemanagement.IServiceObject[]>;
    private _services: servicemanagement.IServiceObject[];

    constructor(private rsi: RSIService, private logger: MibLogService) {
        this.services = new BehaviorSubject([]);
        this._services = [];
    }

    public init() {
        this.getServicesFromRsi();
    }

    /**
     * returns the baseUrl of a service by passing the serviceId (name)
     * @param id serviceId or name (e.g. idp_v1 or userinfo_v1)
     */
    public getBaseUrlForServiceId(id: string): string {
        const index = this._services.findIndex(inSer => inSer.name === id);
        return this._services[index].baseUrl;
    }

    /**
     * Subscribe to rsi servicemanagement
     */
    private getServicesFromRsi(): void {
        const endpoint = this.rsi.endpoint('/servicemanagement/services', {'autoGetData': false});
        endpoint.subscribe((serviceData: servicemanagement.IServiceObject[]) => {
            this._services = serviceData;
            this.services.next(serviceData);
        });
    }
}
