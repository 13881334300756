const ServiceTypeEnum = {
    WebApp: 'webApp' as 'webApp',
    Extern: 'extern' as 'extern',
    App: 'app' as 'app',
    VehicleOnly: 'vehicleOnly' as 'vehicleOnly',
    Mbb: 'mbb' as 'mbb',
    MbbInternal: 'mbbInternal' as 'mbbInternal',
    MbbEssential: 'mbbEssential' as 'mbbEssential',
    FunctionOnDemand: 'functionOnDemand' as 'functionOnDemand'

};

type ServiceTypeEnum = (typeof ServiceTypeEnum)[keyof typeof ServiceTypeEnum];
export { ServiceTypeEnum };
