const InstallationStateEnum = {
    DownloadPending: 'downloadPending' as 'downloadPending',
    Downloading: 'downloading' as 'downloading',
    InstallPending: 'installPending' as 'installPending',
    Installing: 'installing' as 'installing',
    Installed: 'installed' as 'installed',
    Failed: 'failed' as 'failed',
    ReinstallRequested: 'reinstallRequested' as 'reinstallRequested',
    Deleted: 'deleted' as 'deleted',
    ManifestDownloadPending: 'manifestDownloadPending' as 'manifestDownloadPending',
    WaitingForConfirmation: 'waitingForConfirmation' as 'waitingForConfirmation',
    Confirmed: 'confirmed' as 'confirmed',

};

type InstallationStateEnum = (typeof InstallationStateEnum)[keyof typeof InstallationStateEnum];
export { InstallationStateEnum };
