import { Injectable } from '@angular/core';
import { RSIService } from '@shift/rsi';
import { onlinestate } from '../../interfaces/onlinestate';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class OnlineStateService {
    public onlineState: onlinestate.IModemDeviceObject = {
        networkConnectionState: 'disconnected',
        networkTechnology: 'n/a',
        networkRegistrationState: 'not registered and not searching'
    };

    public onlineStateBehavior = new BehaviorSubject<onlinestate.IModemDeviceObject>({});

    constructor(private rsi: RSIService) {
    }

    public init(): void {
        this.subscribeForData();
    }

    public isOnline(): boolean {
        return this.onlineState.networkConnectionState === 'connected' && this.isRegistered();
    }

    public isRoaming(): boolean {
        return this.onlineState.networkRegistrationState === 'registered and roaming';
    }

    public isRegistered(): boolean {
        const state = this.onlineState.networkRegistrationState;
        return state === 'registered' || state === 'registered and roaming';
    }

    public isConnectionType4G(): boolean {
        return this.isConnectionType('LTE');
    }

    public isConnectionType3G(): boolean {
        return this.isConnectionType('UMTS') || this.isConnectionType('HSDPA') || this.isConnectionType('HSDPA+');
    }

    private isConnectionType(connectionType: string): boolean {
        return connectionType === this.onlineState.networkTechnology && this.isRegistered();
    }

    private subscribeForData(): void {
        const endpoint = this.rsi.firstEndpoint('/onlinestate/modemDevices', {'autoGetData': false});
        endpoint.subscribe(
            (data: onlinestate.IModemDeviceObject) => {
                this.onlineState = data;
                this.onlineStateBehavior.next(data);
            },
            (e: Error) => {
                console.log(e);
                // this.showInfo('onlinestateError');
            }
        );
    }
}
