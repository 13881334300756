import { DragGestureDirective } from './drag-gestures.directive';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [CommonModule],
    exports: [DragGestureDirective],
    declarations: [DragGestureDirective]
})
export class DragGestureModule {}
