import { Injectable } from '@angular/core';
import { ipcRenderer, remote } from 'electron';
import { EnvService } from '../environment/env.service';
import { access, constants, mkdir, writeFile } from 'fs';
import { resolve } from 'path';

@Injectable()
export class ScreenshotService {
    public directory = './screenshots';

    constructor(private envService: EnvService) {
        if (this.envService.isElectron) {
            this.listenForScreenshotEvent();
        }
    }

    private listenForScreenshotEvent(): void {
        ipcRenderer.on('screenshot-event', (event: any, message: string) => {
            const dir = message === 'save' ? this.directory : '';
            this.takeScreenshot(dir);
        });
    }

    private saveImageAs(defaultPath: string, buf: Electron.NativeImage) {
        const filters = [{ name: 'image', extensions: ['png'] }];
        remote.dialog.showSaveDialog({ title: 'save As', defaultPath, filters }, fileName => {
            if (fileName === undefined) {
                return;
            }
           // writeFile(fileName, buf.toPNG());
        });
    }

    private takeScreenshot(directory: string): void {
        const defaultFileName: string = 'SHIFT_' + this.dateString;
        remote.getCurrentWindow().capturePage(buf => {
            if (directory.length > 0) {
                this.createDirectory(directory)
                    .then(() => {
                    //  writeFile(resolve(directory, defaultFileName + '.png'), buf.toPNG());
                    })
                    .catch(() => {
                        this.saveImageAs(defaultFileName, buf);
                    });
            } else {
                this.saveImageAs(defaultFileName, buf);
            }
        });
    }

    private createDirectory(directory: string): Promise<any> {
        return new Promise((resolve, reject) => {
            access(directory, constants.W_OK | constants.F_OK, (err: Error) => {
                if (err) {
                    mkdir(directory, (mkerr: Error) => {
                        if (mkerr) {
                            return reject(mkerr);
                        }
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        });
    }

    /**
     * dateString: YYYYMMDD-HHMMSS
     */
    private get dateString(): string {
        const date: Date = new Date();
        const month: string = ('0' + (date.getMonth() + 1).toString()).slice(-2); // add leading zero & fix 0-11 => 1-12
        const day: string = ('0' + date.getDate().toString()).slice(-2); // add leading zero
        const hours: string = ('0' + date.getHours().toString()).slice(-2); // add leading zero
        const minutes: string = ('0' + date.getMinutes().toString()).slice(-2); // add leading zero
        const seconds: string = ('0' + date.getSeconds().toString()).slice(-2); // add leading zero
        const dateString: string =
            date.getFullYear() + '' + month + '' + day + '-' + hours + '' + minutes + '' + seconds;
        return dateString;
    }
}
