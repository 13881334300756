import { Injectable, ElementRef } from '@angular/core';

@Injectable()
export class ScrollService {
    // Contains the active scroll Element
    // Scroll event can only be startet if the scroll element is NULL!
    // This prevents multiple scroll events at one time
    private _scrollElement: ElementRef;

    constructor() {}

    public get scrollElement(): ElementRef {
        return this._scrollElement;
    }

    public set scrollElement(value: ElementRef) {
        if (value !== this._scrollElement) {
            this._scrollElement = value;
        }
    }
}
