import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// shift rsi
import { RSIService, RSIEndpoint } from '@shift/rsi';

// shift rsi-services
import { servicemanagement } from '../../interfaces/servicemanagement';

@Injectable()
export class BaseUrlService {

    // holds all base urls of all servicemanagement operations
    public baseUrls = new BehaviorSubject<any>({});

    constructor(
        private rsi: RSIService
    ) {}

    /**
     * Subscribes to app's service in rsi servicemanagement
     * Collects all baseUrls of service's operations
     * @param serviceID {string} - app's unique service id
     * @param devUrls {any} - if devUrls are set service returns them as dummy data
     */
    public init(serviceID: string, devUrls?: any): Promise<void> {
        return new Promise((resolve, reject) => {
            if( devUrls ) {
                this.baseUrls.next(devUrls);
                resolve();
            } else {
                this.rsi.firstEndpoint(
                    '/servicemanagement/services?name=carapp_incarenrollment',
                    {'autoGetData': false}
                ).subscribe(
                    (service: servicemanagement.IServiceObject) => {
                        const urls = <any>{};
                        for( const operation of service.operations ) {

                            // get rid of unnecessary path after baseUrl
                            const splittedUrl = operation.invocationUrl.split('/');
                            const url = splittedUrl[0] + '//'+ splittedUrl[2];

                            urls[operation.operationId] = url;
                        }
                        console.log('%c Operation BaseUlrs: ', 'color:#fff; background:#333', urls);
                        this.baseUrls.next(urls);
                        resolve();
                    }
                )
            }
        });
    }
}

