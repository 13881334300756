import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Footer component that can show several buttons OR own content via ng-content.
 * Should be nested inside a shift-screen.
 * @example
 * <shift-screen>
 *                  <shift-main>
 *                      <!-- YOUR CONTENT -->
 *                  </shift-main>
 *                  <shift-footer-n-buttons [buttonTexts]="['Button 1', 'Button 2']"></shift-footer-n-buttons>
 * </shift-screen>
 */
@Component({
    moduleId: module.id,
    // tslint:disable-next-line:component-selector
    selector: 'shift-footer, shift-footer-setup, shift-footer-n-buttons, shift-footer-n-buttons-setup',
    template: `<footer class="navigation-footer" [class.disable-scrolling]="disabledScrollbar">
    <button 
        *ngFor="let buttonText of buttonTexts; let i = index" 
        class="navigation-footer-button" 
        type='button' 
        [disabled]='isDisabled[i]' 
        (click)="onButtonClicked($event, i)" 
        [ngClass]="{'grayed-out': grayOutIfDisabled }">
            {{ buttonText }}
    </button>
    <ng-content></ng-content>
</footer>
`,
    styles: [`.navigation-footer{position:relative}`]
})
export class ShiftFooterNButtonsComponent {

    /**
     * Sets the button texts. First button text = first element of array.
     */
    @Input() buttonTexts: string[] = [];

    /**
     * Sets the disable state of the corresponding button. 
     */
    @Input() public isDisabled: boolean[] = [];

    /**
     * Set to true if you want to gray out buttons if they are disabled.
     */
    @Input() public grayOutIfDisabled: boolean;

    /**
     * Event that emmits when a button is clicked. Provides as param the index of the clicked button. 
     */
    @Output() public buttonClicked: EventEmitter<number> = new EventEmitter();

    /**
     * Property that indicates if scrollbar in shift-main is disabled
     */
    public disabledScrollbar = false;

    /**
     * Constructor for the footer
     */
    constructor() {}

    /**
     * function that is triggered and emmits the "buttonClicked"-event when a button was clicked.
     * @param index 
     */
    public onButtonClicked(event: Event, index: number): void {
        event.stopPropagation();
        this.buttonClicked.emit(index);
    }
}
