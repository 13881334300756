import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShiftVirtualScrollListComponent } from './virtual-scroll-list.component';
import { ShiftScrollModule } from '../../gestures/scroll/scroll.module';

export * from './virtual-scroll-list.component';

@NgModule({
    imports: [CommonModule, ShiftScrollModule],
    exports: [ShiftVirtualScrollListComponent],
    declarations: [ShiftVirtualScrollListComponent]
})
export class ShiftVirtualScrollListModule {}
