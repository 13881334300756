import { element } from 'protractor';
import { GesturesService } from '../../gestures/gestures.service';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit, OnInit, OnChanges, HostBinding, ElementRef, ViewChild, AfterContentInit
} from '@angular/core';

/**
 * This dropdown shows a selectable list of text-items. For changing the heights for your own needs include the corresponding
 * theme or change it manually via ::ng-deep.
 * If you have shift-items and a dropdown make sure that both have the same height for aligning correctly.
 * @example
 * <shift-screen>
 *                  <shift-main>
 *                      <shift-dropdown [listData]="['Item 1', 'Item 2', 'Item 3']" [selectedIndex]="0"></shift-dropdown>
 *                      <!-- MORE OF YOUR CONTENT -->
 *                  </shift-main>
 * </shift-screen>
 */
@Component({
  moduleId: module.id,
  // tslint:disable-next-line:component-selector
  selector: 'shift-dropdown',
  template: `<div class="dropdownBackground" *ngIf="open" (click)="closeDropdown($event)"></div>

<div class="drop-down" [class.open]="open">
    <div class="dropdown-button button"
        (click)="openDropdown($event)"
        [class.open]="open">
            {{label}}
            <div class="dropdown-icon"></div>
    </div>
    <div #container class="dropdown-container" *ngIf="open" [ngStyle]="{'top': topPosition+'px',
                                                                        'left': leftPosition+'px',
                                                                        'width': containerWidth+'px'}">
        <div class="dropdown-item-full" *ngFor="let data of listData; let i = index"
        (click)="selectIndex(i)">
            <div class="option">
                <div class="image-holder" [class.selected]="selectedIndex === i"></div>
                <span>{{ data }}</span>
            </div>
        </div>
    </div>
</div>
`,
  styles: [`:host{position:relative;display:flex;height:100%;flex-grow:5}:host .drop-down{position:relative;display:block;width:100%}.dropdown-container{position:absolute;top:0;display:flex;flex-direction:column;align-items:center;width:100%;z-index:99}`],
})
export class ShiftDropdownComponent implements OnInit {
  /**
   * Sets the label of the dropdown if its closed.
   */
  @Input() label = 'Dropdown';

  /**
   * Gets the displayable text-items as an array of strings.
   */
  @Input() listData: Array<string> = [];

  /**
   * Set class rtl whenever it's passed through the parent component.
   * Also 'Mirroring' the dropdown that text is read from right to left. Usable for arabic language e.g.
   */
  @HostBinding('class.rtl')
  @Input() isRtl = false;

  /**
   * Sets the selected item of the dropdown. 0 for the first element of @Input listData
   */
  @Input() selectedIndex: number;

  /**
   * Sets the offset of the dropdown-container for even amount of options.
   * Negative values shifts the dropdown container up, positive down.
   * The Default behaviour is that the dropdown container opens up central next to the opened shift-item
   * but shifted down one half of a shift-item to have the borders aligned with the border of the shift-item.
   */
  @Input() offsetForEvenOptions: number;

  /**
   * Event that emmits when the selected item was changed.
   */
  @Output() public selectedIndexChange: EventEmitter<number> = new EventEmitter();

  /**
   * Event that emmits when the dropdown was opened
   */
  @Output() public isOpen: EventEmitter<boolean> = new EventEmitter();

  /**
   * stores information if dropdown is currently open
   */
  public open: boolean;

  /**
   * stores the absolute y-position where the dropdown-container should be opened
   */
  public topPosition: number;

  /**
   * stores the absolute x-Position where the dropdown-container should be openend
   */
  public leftPosition: number;

  /**
   * stores the width in pixel of the dropdown
   */
  public containerWidth: number;

  /**
   * Constructor for the dropdown component
   */
  constructor(private el: ElementRef,
              private gestureService: GesturesService) {
  }

  /**
   * On initialization the label will be set to the selected item (if a selected item was defined and is in listData range)
   */
  ngOnInit() {
    if (this.selectedIndex < this.listData.length && this.selectedIndex >= 0) {
      this.label = this.listData[this.selectedIndex];
    }
  }

  /**
   * function that set the new selected item and closes the dropdown
   * @param index
   */
  selectIndex(index: number): void {
    this.selectedIndex = index;
    this.open = false;
    this.label = this.listData[index];
    this.isOpen.emit(false);
    this.gestureService.modalOpen = false;
    this.selectedIndexChange.emit(index);
  }

  /**
   * function is triggered when clicking on a closed dropdown
   * @param event
   */
  openDropdown(event: Event): void {
    event.preventDefault();
    if(!this.gestureService.gestureInProgress) {
      this.open = true;
      this.gestureService.modalOpen = true;
      this.isOpen.emit(true);
      // - 2 because of left and right border
      this.containerWidth = this.el.nativeElement.getBoundingClientRect().width - 2;
      requestAnimationFrame(()=> {
        this.setPosition();
      }); 
    }

  }

  /**
   * function that closes the dropdown without selecting any new item (old item will be still selected)
   * @param event
   */
  closeDropdown(event: Event): void {
    event.preventDefault();
    this.open = false;
    this.gestureService.modalOpen = false;
    this.isOpen.emit(false);
  }

  /**
   * gets the native element of <shift-main>
   * @param el 
   */
  getShiftMain(el): Node {
    while (el.parentNode) {
      el = el.parentNode;
      if(el.tagName === 'SHIFT-MAIN') {
        return el;
      }
    }
  } 

  /**
   * Sets the css properties left,top for the position of the dropdown container.
   */
  setPosition() {
    const even: boolean = this.listData.length % 2 === 0;
    let offset: number = even ? (this.offsetForEvenOptions ? this.offsetForEvenOptions : Math.floor(this.listData.length / -2) + 1)
                                : Math.floor(this.listData.length / 2) * -1;

    const elRect = this.el.nativeElement.getBoundingClientRect();
    const topPositionOfDD = elRect.top;
    const leftPositionOfDD = elRect.left;
    const borderOfTop = this.getTopBorderPos(this.el.nativeElement);

    const height = window.innerHeight;
    const heightOfDD = this.getContainerHeight();
    let topPositionOfDDContainer = topPositionOfDD  + offset * (elRect.height + 1);
    if(height - (topPositionOfDDContainer + heightOfDD) < 0 && topPositionOfDDContainer < borderOfTop) {
      while(topPositionOfDDContainer < borderOfTop) {
        topPositionOfDDContainer = topPositionOfDDContainer + (elRect.height + 1);
      }
      // TODO: Make dropdown scrollable
    } else {
      while(topPositionOfDDContainer < borderOfTop) {
        topPositionOfDDContainer = topPositionOfDDContainer + (elRect.height + 1);
      }
      while(height - (topPositionOfDDContainer + heightOfDD) < 0) {
        topPositionOfDDContainer = topPositionOfDDContainer - (elRect.height + 1);
      }
    }
    const spaceRemaining = height - topPositionOfDD;
    // -1 to align with top-border
    this.topPosition = topPositionOfDDContainer - 1;
    // +1 to align with item slot seperator
    this.leftPosition = leftPositionOfDD + 1;
  }

  /**
   * Calculates the top border --> the dropdown container should always be opened below that border.
   * @param el 
   */
  getTopBorderPos(el): number {
    let shiftMainNode: any;
    let shiftHeaderNode: any;

    while (el.parentNode) {
      el = el.parentNode;
      if(el.tagName === 'SHIFT-MAIN') {
        shiftMainNode = el;
      }
      if(el.tagName === 'SHIFT-HEADER') {
        shiftHeaderNode = el;
      }
    }
    if (shiftHeaderNode) {
      return shiftHeaderNode.getBoundingClientRect().top;
    }
    if (shiftMainNode) {
      return shiftMainNode.getBoundingClientRect().top;
    }
    return 0;
  }

  /**
   * Returns the dropdown container height.
   */
  getContainerHeight(): number {
    const element: HTMLElement = this.el.nativeElement;
    const heightOfDDContainer = element.querySelector('.dropdown-container').getBoundingClientRect().height;
    return heightOfDDContainer;
  }
}
