import { RSIService } from '@shift/rsi';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()
export class BackgroundService {


  constructor(private rsi: RSIService) {

  }

  public async startByEntrypointId(entrypointID: string): Promise<string> {
    const entrypoint: any = await this.getEntryPoint(entrypointID);
    const engineinstanceID = await this.startByEntrypoint(entrypoint);
    return engineinstanceID;
  }

  getEntryPoint(entrypointID: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.rsi.endpoint('/webappmanagement/entrypoints?entrypointID=' + entrypointID, {
        autoGetData: true,
        autoSubscribe: false
      }).subscribe((data) => {
        if (data.length === 1) {
          resolve(data[0]);
        }
      });
    });
  }

  startByEntrypoint(entryPoint: any): Promise<string> {
    return new Promise((resolve, reject) => {
      let request: Observable<HttpResponse<any>> = this.rsi.endpoint('/webappmanagement/engineInstances/').createElement({
        name: entryPoint.name,
        engineID: 'background',
        entrypoint: entryPoint.uri,
        runState: 'startRequested'
      });

      request.subscribe((res: HttpResponse<any>) => {
        if (res.headers.has('Location')) {
          const loc = res.headers.get('Location');
          const id = loc.substring(loc.lastIndexOf('/') + 1);
          resolve(id);
        }
      });
    });
  }


}
