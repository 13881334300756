import { ShiftLegalEntryModule } from './../legal-entry/legal-entry.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ShiftLegalOverviewComponent } from './legal-overview.component';
import { ShiftListModule } from '../list/list.module';

@NgModule({
    imports: [CommonModule, FormsModule, ShiftLegalEntryModule, ShiftListModule],
    declarations: [ShiftLegalOverviewComponent],
    exports: [ShiftLegalOverviewComponent]
})
export class ShiftLegalOverviewModule {}
