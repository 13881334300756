
/**
 * Abstract class describing a popup state. Each concrete popup state class must provide these methods.
 */
export abstract class Popup2AbstractStateModel {
  /**
   * Method to be called when entering the state. Used to perform initializing.
   */
  public abstract onEnter();

  /**
   * Called when a new popup is available. The state implementation may check if there is some action necessary
   * (e.g. close current popup and switch to the next state).
   */
  public abstract newPopupAvailable();

  /**
   * Called when any popup button is pressed. The state implementation may check if there is some action necessary.
   */
  public abstract buttonPressed();

  /**
   * Called when popup shall be closed. State implementation decides which action to perform to get popup closed.
   */
  public abstract closePopup();
}
