import { AfterViewInit, Directive, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[pinchGesture]'
})
export class PinchGestureDirective implements AfterViewInit {
    @Output() onPinch: EventEmitter<{}> = new EventEmitter();

    public emitGesture = true;

    constructor(public el: ElementRef) {
    }

    ngAfterViewInit(): void {
        const hammertime: HammerManager = new Hammer(this.el.nativeElement);
        hammertime.get('pinch').set({enable: true});

        hammertime.on('pinchstart pinchmove pinchend', (ev: HammerInput) => {
            this.onPinch.emit(ev);
        });
    }
}
