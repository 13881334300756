import * as process from 'process';
import { Inject } from '@angular/core';

export class EnvService {
    public static readonly ENV_ELECTRON: string = 'ENV_ELECTRON';
    public static readonly ENV_HYBRID: string = 'ENV_HYBRID';
    public static readonly ENV_BROWSER: string = 'ENV_BROWSER';

    isHybrid = false;
    isElectron = false;
    isBrowser = false;
    env: string;

    VIWI_PORT = 3000;
    VIWI_HOST = 'localhost';
    VIWI_API_PREFIX = '';

    constructor(@Inject('SHIFT_ENV') private SHIFT_ENV: any) {
        console.log('ENV IN VIWI SERVICE ', this.env);
        const w: any = window;
        const isCordovaApp = !!w.cordova;
        if (isCordovaApp) {
            this.isHybrid = true;
            this.env = EnvService.ENV_HYBRID;
        } else if (this.checkElectron()) {
            this.isElectron = true;
            this.env = EnvService.ENV_ELECTRON;
        } else {
            this.isBrowser = true;
            this.env = EnvService.ENV_BROWSER;
        }

        this.checkEnv();
    }

    private checkElectron() {
        if (typeof process === 'undefined') {
            return false;
        }
        const p: any = process;
        return typeof p !== 'undefined' && p.versions && p.versions.electron !== undefined;
    }

    private checkEnv() {
        if (this.SHIFT_ENV && this.SHIFT_ENV.VIWI_PORT) {
            this.VIWI_PORT = this.SHIFT_ENV.VIWI_PORT;
        }

        if (this.SHIFT_ENV && this.SHIFT_ENV.VIWI_HOST) {
            this.VIWI_HOST = this.SHIFT_ENV.VIWI_HOST;
        }

        if (this.SHIFT_ENV && this.SHIFT_ENV.VIWI_API_PREFIX) {
            this.VIWI_API_PREFIX = this.SHIFT_ENV.VIWI_API_PREFIX;
        }
    }
}
