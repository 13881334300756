import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ShiftSwitchComponent } from './switch.component';

export * from './switch.component';

@NgModule({
    imports: [CommonModule],
    exports: [ShiftSwitchComponent],
    declarations: [ShiftSwitchComponent]
})
export class ShiftSwitchModule {}
