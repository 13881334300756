import { Popup2AbstractStateContextModel } from './popup2-abstract-state-context.model';
import { Popup2AbstractStateModel } from './popup2-abstract-state.model';
import { Popup2StateOpenWithTimeoutModel } from './popup2-state-open-with-timout.model';
import { Popup2StateOpenModel } from './popup2-state-open.model';
import { Popup2StateClosingModel } from './popup2-state-closing.model';

/**
 * A class which represents the state where popup is open.
 * To debounce popup opening and closing, the popup stays in this state for the defined number of milliseconds.
 */
export class Popup2StateOpenEnsureMinTimeModel extends Popup2AbstractStateModel {

  /** A timer object used for the show-at-least-time */
  private showAtLeastTimer: NodeJS.Timer;

  constructor (private stateContext: Popup2AbstractStateContextModel) {
    super();
  }

  /**
   * Request milliseconds to keep the popup in this state and start the timer.
   */
  public onEnter() {
    const milliseconds = this.stateContext.currentlyShownPopup.showAtLeastNMilliseconds;
    if (milliseconds) {
      this.ensureShowAtLeastTime(milliseconds);
    } else {
      this.showAtLeastTimeOver();
    }
  }

  /**
   * Ignore since popup should stay open in this state
   */
  public newPopupAvailable() {
    // ignore new popups in this state
  }

  /**
   * Close popup on button pressed
   */
  public buttonPressed() {
    this.closePopup();
  }

  /**
   * Start timer and subscribe on timeout after defined milliseconds. Call showAtLeastTimeOver() on timeout.
   * @param milliseconds milliseconds to run the timer
   */
  private ensureShowAtLeastTime(milliseconds: number) {
    this.showAtLeastTimer = setTimeout(() => {
      this.showAtLeastTimeOver();
    }, milliseconds);
  }

  /**
   * Called on timer timeout.
   * Check if new popup is available and switch to next suitable state.
   */
  private showAtLeastTimeOver() {
    let nextState: Popup2AbstractStateModel;

    if (this.stateContext.mustSwitch2NextPopup()) {
      nextState = new Popup2StateClosingModel(this.stateContext);
    } else  if (this.stateContext.currentlyShownPopup.closeAfterNMilliseconds > 0) {
      nextState = new Popup2StateOpenWithTimeoutModel(this.stateContext);
    } else {
      nextState = new Popup2StateOpenModel(this.stateContext);
    }

    this.stateContext.switch2State(nextState);
  }

  /**
   * Clear timer and swicth to state 'closing'.
   */
  public closePopup() {
    this.clearTimer();
    this.switch2StateClosing();
  }

  /**
   * Clear timer if not undefined.
   */
  private clearTimer() {
    if (this.showAtLeastTimer) {
      clearTimeout(this.showAtLeastTimer);
    }
  }

  /**
   * Create new state 'closing' and switch to this state.
   */
  private switch2StateClosing() {
    const nextState = new Popup2StateClosingModel(this.stateContext);
    this.stateContext.switch2State(nextState);
  }
}
