import { Injectable } from '@angular/core';

import { MibLogService } from '@shift/core';
import { RSIEndpoint, RSIService, RSI } from '@shift/rsi';
import { webappmanagement } from '../../interfaces/webappmanagement';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class AppSwitcherService {
    private webappsEndpoint: RSIEndpoint<any>;
    private engineInstanceEndpoint: RSIEndpoint<any>;
    private entryPointsEndpoint: RSIEndpoint<any>;
    private installedWebApps: ReplaySubject<webappmanagement.IWebappObject[]>;

    constructor(private rsi: RSIService, private logger: MibLogService) {
        this.installedWebApps = new ReplaySubject<webappmanagement.IWebappObject[]>(1);
    }

    public init(engineInstanceUri: string) {
        this.webappsEndpoint = this.rsi.endpoint('/webappmanagement/webapps', {'autoGetData': false});
        this.entryPointsEndpoint = this.rsi.endpoint('/webappmanagement/entrypoints', {'autoGetData': false});

        if (engineInstanceUri) {
            this.engineInstanceEndpoint = this.rsi.endpoint(engineInstanceUri);
        }

        this.requestListOfInstalledApps();
    }

    public isAppExecutable(appName: string): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this.getInstalledAndExecutableWebApp(appName)
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    resolve(false);
                });
        });
    }

    public switch2App(appName: string, entryPointName: string, appArguments: string): void {
        this.getInstalledAndExecutableWebApp(appName)
            .then(webAppObject => {
                this.getEntryPointObject(webAppObject, entryPointName)
                    .then(entryPointObject => {
                        this.logger.info(
                            'AppSwitcher: switching to ' + webAppObject.name + '/' + entryPointObject.name
                        );
                        this.switch2EntryPoint(entryPointObject, appArguments);
                    })
                    .catch(() => {
                        this.logger.warn(
                            'AppSwitcher: Unable to find entrypoint ' + entryPointName + ' for app ' + appName
                        );
                    });
            })
            .catch(() => {
                this.logger.warn('AppSwitcher: Unable to find installed and executable app ' + appName);
            });
    }

    private getEntryPointObject(
        webAppObject: webappmanagement.IWebappObject,
        entryPointName: string
    ): Promise<webappmanagement.IEntrypointObject> {
        return new Promise<webappmanagement.IEntrypointObject>((resolve, reject) => {
            this.entryPointsEndpoint.subscribe((entryPoints: webappmanagement.IEntrypointObject[]) => {
                const foundEntryPoint = entryPoints.find(
                    entryPoint =>
                        entryPoint.name === entryPointName &&
                        webAppObject.entrypoints &&
                        webAppObject.entrypoints.some(webAppEntryPoint => webAppEntryPoint.uri === entryPoint.uri)
                );
                if (foundEntryPoint === undefined) {
                    reject();
                } else {
                    resolve(foundEntryPoint);
                }
            });
        });
    }

    private switch2EntryPoint(entryPointObject: webappmanagement.IEntrypointObject, appArguments: string): void {
        if (this.engineInstanceEndpoint) {
            this.engineInstanceEndpoint.updateElement({
                'switchToEntrypoint': { 'id': entryPointObject.id },
                'switchToArguments': appArguments
            });
        } else {
            this.logger.warn('AppSwitcher: cannot switch (no engineinstance)');
        }
    }

    private requestListOfInstalledApps(): void {
        this.webappsEndpoint.subscribe((apps: webappmanagement.IWebappObject[]) => {
            const listOfInstalledApps = apps.map(app => app.name).join(',');
            this.logger.debug('AppSwitcher: received installed apps: ' + listOfInstalledApps);
            this.installedWebApps.next(apps);
        });
    }

    private getInstalledAndExecutableWebApp(appName: string): Promise<webappmanagement.IWebappObject> {
        return new Promise<webappmanagement.IWebappObject>((resolve, reject) => {
            this.installedWebApps.subscribe(apps => {
                const foundApp = apps.find(app => app.name === appName && app.availabilityState === 'executable');
                if (foundApp === undefined) {
                    reject();
                } else {
                    resolve(foundApp);
                }
            });
        });
    }
}
