import { ErrorHandler, Injectable } from '@angular/core';
import { MibLogService } from '../miblog/miblog.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private logger: MibLogService) {}

    handleError(error) {
        this.logger.error(error);
        throw error;
    }
}
