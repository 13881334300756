import { Component, HostBinding } from '@angular/core';


/**
 * The avatar component uses ng-content in its template. 
 * So just provide the wished content within the <shift-avatar> selector
 * and it will show you it as an avatar.
 * @example
 * <shift-screen>
 *                  <shift-main>
 *                      <shift-avatar>
 *                          <img src="./path/to/image.png">
 *                      </shift-avatar>
 *                      <!-- MORE OF YOUR CONTENT -->
 *                  </shift-main>
 * </shift-screen>
 */
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'shift-avatar,[shiftAvatar]',
    template: '<ng-content></ng-content>',
    styles: [``]
})
export class ShiftAvatarComponent {

    /**
     * Sets the class 'avatar' to the component
     */
    @HostBinding('class.avatar') true;

    /**
     * Constructor for the avatar component
     */
    constructor() {}
}
