import { EventEmitter } from '@angular/core';

export interface IRSIConfigData {
    server: {
        port: number;
        address: string;
        protocol: string;
        socketprotocol: string;
        api: string;
    };
}

export class RSIConfig {
    onChange: EventEmitter<IRSIConfigData> = new EventEmitter<IRSIConfigData>();

    configData: IRSIConfigData = {
        server: {
            port: 1337,
            address: '127.0.0.1',
            protocol: 'http',
            socketprotocol: 'ws',
            api: 'api/v1'
        }
    };

    constructor(data?: IRSIConfigData) {
        if (data) {
            this.configData = Object.assign(this.configData, data);
        }
    }

    update(data: IRSIConfigData) {
        this.configData = data;
        this.onChange.emit(this.configData);
    }

    public getSocketPath(): string {
        return (
            this.configData.server.socketprotocol +
            '://' +
            this.configData.server.address +
            ':' +
            this.configData.server.port
        );
    }

    public getBasePath(): string {
        return (
            this.configData.server.protocol + '://' + this.configData.server.address + ':' + this.configData.server.port
        );
    }

    public getHost() {
      return this.configData.server.protocol + '://' + this.configData.server.address;
    }

    public getAPIPath(): string {
        return this.configData.server.api && this.configData.server.api !== ''
            ? this.getBasePath() + '/' + this.configData.server.api
            : this.getBasePath();
    }
}
