import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ShiftSpinnerComponent } from './shift-spinner.component';

@NgModule({
    imports: [CommonModule],
    exports: [ShiftSpinnerComponent],
    declarations: [ShiftSpinnerComponent]
})
export class ShiftSpinnerModule {}
