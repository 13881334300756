import { Component, Input, OnInit } from '@angular/core';

/**
 * A page-load component that has a loading indicator and a text.
 * Is layed over the whole. Put the page-load selector outside of shift-screen.
 * @example
 * <shift-screen>
 *                  <shift-main>
 *                      <!-- YOUR CONTENT -->
 *                  </shift-main>
 * </shift-screen>
 * <shift-page-load *ngIf="yourPageLoadShowVariable" [text]="'Example text of page load'"></shift-page-load>
 * 
 */
@Component({
    moduleId: module.id,
    selector: 'shift-page-load',
    template: `<div class='loading-container' [ngClass]='{"cover": cover, "primary": primary}'>
    <span class="loading-anime-mask"></span>
    <span> {{ text }} </span>
</div>
`,
    styles: [`:host{position:fixed;pointer-events:none;top:0;bottom:0;left:0;right:0}`]
})
export class ShiftPageLoadComponent {

    /**
     * Switch to set the class 'cover' on the loading-container div
     */
    @Input() cover: boolean;

    /**
     * Switch to set the class 'primary' on the loading-container div
     */
    @Input() primary: boolean;

    /**
     * Sets the text that should be shown next to the loading indicator.
     */
    @Input() text: string;
}
