import { TapGestureDirective } from './tap-gesture.directive';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

@NgModule({
    imports: [CommonModule],
    exports: [TapGestureDirective],
    declarations: [TapGestureDirective]
})
export class TapGestureModule {
    /** @deprecated */
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: TapGestureModule,
            providers: []
        };
    }
}
