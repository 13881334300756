import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ShiftFooterNButtonsComponent } from './footer-n-buttons/footer-n-buttons.component';
import { ShiftFooterNearingBarComponent } from './footer-nearing-bar/footer-nearing-bar.component';

@NgModule({
    imports: [CommonModule, RouterModule],
    exports: [ShiftFooterNearingBarComponent, ShiftFooterNButtonsComponent],
    declarations: [ShiftFooterNearingBarComponent, ShiftFooterNButtonsComponent],
    providers: []
})
export class ShiftFooterModule {
    /** @deprecated */
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ShiftFooterModule,
            providers: []
        };
    }
}
