import { Injectable, isDevMode } from '@angular/core';

@Injectable()
export class MibLogService {
    private name: string;

    constructor() {}

    public init(name: string) {
        this.name = name;
        window.onerror = (errorMsg, url, lineNumber) => {
            this.error(errorMsg, url);
        };
    }

    public error(message: any, source?: string) {
        const logObject = this.format(message, source, 3, 50);
        console.error(logObject);
    }

    public warn(message: any, source?: string) {
        const logObject = this.format(message, source, 4, 40);
        console.warn(logObject);
    }

    public info(message: any, source?: string) {
        const logObject = this.format(message, source, 5, 30);
        console.info(logObject);
    }

    public debug(message: any, source?: string) {
        const logObject = this.format(message, source, 6, 20);
        console.debug(logObject);
    }

    public trace(message: any, source?: string) {
        const logObject = this.format(message, source, 7, 10);
        console.trace(logObject);
    }

    private format(message: any, source: string, severity: number, level: number) {
        if (isDevMode()) {
          return message;
        }

        return { name: this.name, message, source, severity, level };
    }
}
