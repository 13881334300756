import { BehaviorSubject } from 'rxjs';
import { MibLogService } from '@shift/core';
import { Injectable } from '@angular/core';
import { RSIService } from '@shift/rsi';
import { vehicleinformation } from '../../interfaces/vehicleinformation';

@Injectable()
export class VehicleInformationService {
    public vehicleInformation = new BehaviorSubject({});

    constructor(private rsi: RSIService, private logger: MibLogService) {
    }

    public init(): void {
        this.subscribeForData();
    }

    private subscribeForData(): void {
        const endpoint = this.rsi.firstEndpoint('/vehicleinformation/vehicleData/', {'autoGetData': false});
        endpoint.subscribe(
            (data: vehicleinformation.IVehicleDataObject) => {
                // this.setInitialLanguage(data);
                this.vehicleInformation.next(data);
            },
            (e: Error) => {
                this.logger.error(e);
                // this.showInfo('vehicleinformationdataError');
            }
        );
    }
}
