import { PanRightDoubleGestureDirective } from './panright-double-gesture.directive';
import { PanLeftDoubleGestureDirective } from './panleft-double-gesture.directive';
import { PanUpGestureDirective } from './panup-gesture.directive';
import { PanRightGestureDirective } from './panright-gesture.directive';
import { PanLeftGestureDirective } from './panleft-gesture.directive';
import { PanDownGestureDirective } from './pandown-gesture.directive';
import { PanGestureDirective } from './pan-gesture.directive';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [CommonModule],
    exports: [
        PanGestureDirective,
        PanDownGestureDirective,
        PanLeftGestureDirective,
        PanRightGestureDirective,
        PanUpGestureDirective,
        PanLeftDoubleGestureDirective,
        PanRightDoubleGestureDirective
    ],
    declarations: [
        PanGestureDirective,
        PanDownGestureDirective,
        PanLeftGestureDirective,
        PanRightGestureDirective,
        PanUpGestureDirective,
        PanLeftDoubleGestureDirective,
        PanRightDoubleGestureDirective
    ]
})
export class PanGestureModule {}
