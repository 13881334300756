import { Injectable } from '@angular/core';
import { RSIService, RSIEndpoint } from '@shift/rsi';
import defaultAvailableLanguages from './languages';
import { language } from '../../interfaces/language';
import { BehaviorSubject, ReplaySubject, Subject, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class LanguageService {
    public availableLangs: string[];
    public currentLanguageByRsi = new BehaviorSubject<string>('');
    public languagesChanges = new Subject<string>();
    public errors = new ReplaySubject<Error>();

    private languageComponentEndpoint: RSIEndpoint<any>;
    private languageChangeEndpoint: RSIEndpoint<any>;
    private languageChangesEndpoint: RSIEndpoint<any>;

    constructor(
        private rsi: RSIService
    ) {
        this.availableLangs = [];
    }

    public init(serviceID: string, langs?: any): void {

        this._isRegisterd(serviceID, langs)
            .then((uri: string) => this._subscribeToLanguageComponent(uri))
            .catch((error: Error) => this._errorHandling(error));
    }

    /**
     * Get to language component's resource.
     * Register component if not registered
     * for more info: https://group-cip.audi.de/jira/browse/VWEEWEBENR-243
     * @param serviceID {string} - the app's unique service id
     * @param langs {Array<string>} - list of available languages. Default from imported language file
     */
    private _isRegisterd(serviceID: string, langs?: string[]): Promise<string> {
        const filteredLanguageComponents$ = this.rsi.endpoint('/language/components?name=' + serviceID, { 'autoSubscribe': false });
        return new Promise((resolve, reject) => {
            filteredLanguageComponents$.subscribe(
                (data: language.IComponentObject[]) => {
                    // Check, if the app is already registered
                    if (data.length > 0) {
                        resolve(data[0].uri);
                    } else {
                        this._getAvailableLangs(langs).then((availableLangs: any) => {
                            this._registerLanguageComponent(serviceID, availableLangs)
                                .then((uri: string) => resolve(uri))
                                .catch((error: Error) => reject(error));
                        }).catch((error) => {
                            console.error(error);
                        });
                    }
                },
                (error: any) => {
                    reject(error);
                }
            );
        });
    }

    /**
     * Register language component
     */
    private _registerLanguageComponent(serviceID: string, langs: any): Promise<string> {
        const component = {
            name: serviceID,
            availableLanguages: langs,
            componentType: 'Web App',
            language: ''
        };
        return new Promise((resolve, reject) => {
            this.rsi.endpoint('/language/components', false).createElement(component).subscribe(
                (data: any) => {
                    const uri = data.headers.get('location');
                    console.log('%c created lang. component: ', 'color: #fff; background-color:#222', uri, data);
                    if ( !uri ) { reject('No component uri found'); }
                    resolve(uri);
                    console.log('>> Register app as language component named ' + serviceID + '.');
                },
                (error: Error) => {
                    reject(error);
                }
            );
        });
    }

    /**
     * Subscribe to language component
     * @param uri {string} - uri of language component
     */
    private _subscribeToLanguageComponent(uri: string): void {

        this.languageComponentEndpoint = this.rsi.endpoint(uri, { 'autoSubscribe': false });
        this.languageComponentEndpoint.subscribe((data: language.IComponentObject) => {

            if (data.availableLanguages) {
                this.availableLangs = data.availableLanguages;
            }

            this._subscribeToLanguageChanges(data.id);
            this.languageComponentEndpoint.unsubscribe();
        });
    }

    /**
     * Subscribe to language change transaction
     * @param id {string} - id of language component to listen for changes
     */
    private _subscribeToLanguageChanges(id: string): void {
        this.languageChangesEndpoint = this.rsi.endpoint('/language/changeTransactions?component=' + id, { 'autoGetData': false });
        this.languageChangesEndpoint.subscribe((data: language.IChangeTransactionObject[]) => {

            console.log('%c lange service change transation: ', 'color: red; background-color: #ddd', data);

            for (const transaction of data) {
                this.languageChangeEndpoint = this.rsi.endpoint('/language/changeTransactions/' + transaction.id, { 'autoGetData': false });
                this.languageChangeEndpoint.subscribe(
                    (innerData: language.IChangeTransactionObject) => {
                        this._handleLanguageChange(innerData);
                    },
                    (error: Error) => {
                        console.error(error);
                    }
                );
            }
        });
    }

    /**
     * handle language change
     * @param data
     */
    private _handleLanguageChange(data: language.IChangeTransactionObject): void {
        if (data.state !== 'pending') {
            return;
        }
        data.state = 'ongoing';
        this.languageChangeEndpoint.updateElement({ 'state': data.state });

        this.setLanguage(data.newLanguage).pipe(take(1)).subscribe(
            (success: boolean) => {
                data.state = 'successful';
                this.languageChangeEndpoint.updateElement({ 'state': data.state });
                this.languageComponentEndpoint.updateElement({ language: data.newLanguage });
            },
            (error: Error) => {
                console.error(error);
                data.state = 'failed';
                this.languageChangeEndpoint.updateElement({ 'state': data.state });
            }
        );
    }

    /**
     * Set app language
     * Updates currentLanguageByRsi Subject on which apps will subscribe
     * for languageChanges
     * @param language string
     * @param error Error
     */
    private setLanguage(lng: string, error?: Error): Observable<boolean> {

        if (!error && lng) {
            console.log('>> Use ' + lng + ' as app language.');

            this.currentLanguageByRsi.next(lng);
            this.languagesChanges.next(lng);
            return of(true);

        } else {
            console.warn('Error during language set - default lang will be used.');
            const langs = this.availableLangs;

            const newLang = 'en_DE';
            this.currentLanguageByRsi.next(newLang);
            this.languagesChanges.next(newLang);

            if (this.languageComponentEndpoint) {
                this.languageComponentEndpoint.updateElement({ language: newLang });
            }

            return of(true);
        }
    }

    /**
     * Handles Errors while language processes
     * Sets default langauge
     * @param error {Error}
     */
    private _errorHandling(error: Error): void {
        this.setLanguage(undefined, error).pipe(take(1)).subscribe(
            (success: boolean) => {
                console.warn('>> lang changed, but apps not listening to future changes');
                this.errors.next(error);
            },
            (e: Error) => {
                this.errors.next(e);
            }
        );
    }

    /**
     * Get available langs for app
     * @param langs - app's addons/webapps/languages.json file
     */
    private _getAvailableLangs(langs: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (typeof langs === 'undefined') {
                resolve(defaultAvailableLanguages);
                console.warn(
                    'Shift lib default available languagegs are used. ' +
                    'To use custom available languages for your app, call languageServive.init ' +
                    'with app addons/webapps/languages.json as second parameter.'
                );
            } else {
                const availableLangs = langs.map((lang: any) => lang.name);
                resolve(availableLangs);
            }
        });
    }
}
