import { ShiftSpinnerModule } from '../asset-components/shift-spinner/shift-spinner.module';
import { CommonModule } from '@angular/common';
import { ShiftPageLoadComponent } from './page-load.component';
import { NgModule } from '@angular/core';

export * from './page-load.component';

@NgModule({
    imports: [CommonModule, ShiftSpinnerModule],
    exports: [ShiftPageLoadComponent],
    declarations: [ShiftPageLoadComponent]
})
export class ShiftPageLoadModule {}
