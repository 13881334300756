import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RSIService } from '@shift/rsi';
import { MibLogService } from '@shift/core';
import { webappmanagement } from '../../interfaces/webappmanagement';

@Injectable()
export class WebappManagementService {
    public webapps: BehaviorSubject<webappmanagement.IWebappObject[]>;
    private _webapps: webappmanagement.IWebappObject[];

    constructor(private rsi: RSIService, private logger: MibLogService) {
        this.webapps = new BehaviorSubject([]);
        this._webapps = [];
    }

    public init() {
        this.getWebappsFromRsi();
    }

    /**
     * returns a webappobject by passing the serviceId (name)
     * @param id serviceId or name (e.g. carapp_alexa)
     */
    public getWebappForServiceId(id: string): webappmanagement.IWebappObject {
        const index = this._webapps.findIndex(inSer => inSer.serviceid === id);
        return this._webapps[index];
    }

    /**
     * Subscribe to rsi webappmanagement
     */
    private getWebappsFromRsi(): void {
        const endpoint = this.rsi.endpoint('/webappmanagement/webapps', {'autoGetData': false});
        endpoint.subscribe((webapps: webappmanagement.IWebappObject[]) => {
            this._webapps = webapps;
            this.webapps.next(webapps);
        });
    }
}
