import { Injectable, NgZone, Inject } from '@angular/core';

declare const TweenLite: any;

@Injectable()
export class TweenManagerService {
    public enabled = true;
    public _slowmotionFactor = 1;
    public transitionClassCache: any = {};

    public animationHint: HTMLElement;
    public hideTimeoutId: any;
    // static zone:NgZone;

    constructor(@Inject(NgZone) private zone: NgZone) {}

    public get slowmotionFactor(): number {
        return this._slowmotionFactor;
    }

    public set slowmotionFactor(value: number) {
        this._slowmotionFactor = Math.max(0, value);
        this.updateCssDurations();
    }

    public kill(target_: any): void {
        TweenLite.killTweensOf(target_);
    }

    public to(target_: any, duration_: number, vars_: any): void {
        this.zone.runOutsideAngular(() => {
            const duration: number = this.enabled ? duration_ : 0;
            if (vars_.delay !== null) {
                vars_.delay *= this.slowmotionFactor;
            }
            if (duration > 0) {
                TweenLite.to(target_, this.slowmotionFactor * duration, vars_);
            } else {
                TweenLite.set(target_, vars_);
            }
        });
    }

    public fromTo(target_: any, duration_: number, fromVars_: any, toVars_: any): void {
        this.zone.runOutsideAngular(() => {
            const duration: number = this.enabled ? duration_ : 0;
            if (toVars_.delay !== null) {
                toVars_.delay *= this.slowmotionFactor;
            }
            TweenLite.fromTo(target_, this.slowmotionFactor * duration, fromVars_, toVars_);
        });
    }

    public set(target_: any, vars_: any): void {
        this.zone.runOutsideAngular(() => {
            TweenLite.set(target_, vars_);
        });
    }

    public getOpacity(child_: HTMLElement): number {
        return 1;
    }

    public displayHint(): void {
        if (!this.animationHint) {
            const div: HTMLElement = document.createElement('div');
            const h3: HTMLElement = document.createElement('h3');
            const p: HTMLElement = document.createElement('p');
            h3.innerText = 'SlowMotion-Faktor';
            div.appendChild(h3);
            div.appendChild(p);
            div.classList.add('display-hint');
            this.animationHint = div;
            document.body.appendChild(div);
        }
        this.animationHint.querySelector('p').textContent = this.slowmotionFactor.toFixed(2) + '';
        TweenLite.to(this.animationHint, 0.4, {
            opacity: 1
        });
        clearTimeout(this.hideTimeoutId);
        this.hideTimeoutId = setTimeout(() => {
            TweenLite.to(this.animationHint, 0.4, {
                opacity: 0,
                onComplete: (): void => {
                    this.animationHint.remove();
                    this.animationHint = null;
                }
            });
        }, 1000);
    }

    public updateCssDurations(): void {
        const transitions: Array<any> = this.findRulesWithProperty('transition-duration');
        const factor: number = this.slowmotionFactor;
        this.displayHint();
        transitions.forEach((rule: any) => {
            const selectorText: string = rule.selectorText.toLowerCase();
            let originalValue = '';
            if (this.transitionClassCache[selectorText]) {
                originalValue = this.transitionClassCache[selectorText];
            } else {
                originalValue = rule.style['transition-duration'];
                this.transitionClassCache[selectorText] = originalValue;
            }

            let newValue = '';
            const value: number = parseFloat(originalValue);
            if (originalValue.indexOf('ms') !== -1) {
                newValue += value * factor + 'ms';
            } else if (originalValue.indexOf('s') !== -1) {
                newValue = value * factor + 's';
            }
            rule.style['transition-duration'] = newValue;
        });
    }

    public findRulesWithProperty(property: any): Array<any> {
        const matchedRules: Array<any> = [];
        let styles: StyleSheetList = document.styleSheets,
            n: any,
            sheet: any,
            rules: any,
            m: any;
        for (n = 0; n < styles.length; n++) {
            sheet = styles[n];
            rules = sheet.cssRules || sheet.rules;
            for (m = 0; m < rules.length; m++) {
                if (rules[m].style && rules[m].style[property]) {
                    matchedRules.push(rules[m]);
                }
            }
        }
        return matchedRules;
    }
}
