export interface ISplitEventResult {
    service?: string;
    resource?: string;
    element?: string;
}

export function splitEvent(event: string): { service?: string, resource?: string, element?: string } {
  /*
   let captureGroups = event.match(URIREGEX);
   if (!captureGroups) {
   return {}; //leave immediately if
   }
   return {
   service: captureGroups[1].toLowerCase(),
   resource: captureGroups[2].toLowerCase(),
   element: captureGroups[3]
   }
   */

  if (event.lastIndexOf('#') != -1) {
    event = event.substring(0, event.lastIndexOf('#'));
  }

  if (event.indexOf('?') != -1) {
    event = event.substring(0, event.lastIndexOf('?'));
  }

  if (event.charAt(0) === '/') {
    event = event.substring(1);
  }

  const eventParts: Array<string> = event.split('/');


  let service;
  let resource;
  let element;

  if (eventParts.length >= 1) {
    service = eventParts[0].toLowerCase();
  }

  if (eventParts.length >= 2) {
    resource = eventParts[1].toLowerCase();
  }

  if (eventParts.length >= 3 && eventParts[2] != '') {
    element = eventParts[2].toLowerCase();
    if (element === '') {
      element = undefined;
    }
  }

  return {
    service: service,
    resource: resource,
    element: element
  };
}
