import { CommonModule } from '@angular/common';
import { ShiftAvatarComponent } from './avatar.component';
import { NgModule } from '@angular/core';

export * from './avatar.component';

@NgModule({
    imports: [CommonModule],
    exports: [ShiftAvatarComponent],
    declarations: [ShiftAvatarComponent]
})
export class ShiftAvatarModule {}
