import { Component, Input } from '@angular/core';

/**
 * Control bar component as footer. Provide the control bar entries (Type: ControlBarEntryModel) as input:
 *
 * @example
 * <shift-screen>
 *                  <shift-main>
 *                      <!-- YOUR CONTENT -->
 *                  </shift-main>
 *                  <shift-footer-nearing-bar [entrys]="yourEntrysArray"></shift-footer-nearing-bar>
 * </shift-screen>
 * 
 */
@Component({
    moduleId: module.id,
    // tslint:disable-next-line:component-selector
    selector: 'shift-controlbar, shift-footer-nearing-bar',
    template: `<div class="outer on-top">
  <div class="shadow-inner-bottom"></div>
</div>
<div class="border-top"></div>
<footer *ngIf='!showDisabled' class="controlbar">
    <div *ngFor="let entry of entrys" class="controlbarButton"
         [routerLink]="entry.link" routerLinkActive="controlbarButtonActive" >
        <div class="button-content-wrapper">
            <span>{{ entry.name }}</span>
            <div class="image-wrapper">
                <img src='{{ entry.icon }}' *ngIf="entry.icon != ''">
            </div>
        </div>
    </div>
</footer>

<footer class="controlbar controlbarDisablingBox" *ngIf='showDisabled'></footer>
`,
    styles: [`:host{position:relative}`]
})
export class ShiftFooterNearingBarComponent {
    
    /**
     * Input that that the disabled state
     */
    @Input() public showDisabled = false;

    /**
     * Sets the entries for the control-bar-buttons
     */
    @Input() public entrys: ControlBarEntryModel[] = [];

    /**
     * Constructor for control bar footer
     */
    constructor() {}

}

/**
 * Model class for describing an entry in the Control-bar
 */
export class ControlBarEntryModel {
    /**
     * Router link of one controlbar entry 
     */
    link: string;

    /**
     * Name for the controlbar entry
     */
    name: string;

    /**
     * Icon source for the controlbar entry
     */
    icon: string
}
