import { AfterViewInit, Component, ContentChild, ContentChildren, ElementRef, HostBinding, Input, ViewChild, AfterContentInit, Renderer2 } from '@angular/core';
import {ShiftDropdownComponent} from '../dropdown/dropdown.component';
import { NodeData } from '@angular/core/src/view';

/**
 * Item component where you can include content at different kind of places in the component
 * with the help of multi content projection: https://juristr.com/blog/2016/01/ng2-multi-content-projection/
 *
 * Just put the shift-item in its container. e.g. in shift-main and provide your content at the right places.
 * @example
 * A lot of examples are shown in the app/items.compents.html of the shift-ui.
 * It is highly recommened to use these kind of markup in your application.
 */
@Component({
  selector: 'shift-item, shift-item-divider, shift-item-header, shift-item-setup',
  template: `<ng-content select="[itemBegin]" [class.modalOpen]="isModalOpen"></ng-content>
<div class="item-content">
  <ng-content></ng-content>
</div>
<ng-content select="[itemEnd]"></ng-content>
`,
  styles: [`:host{box-sizing:border-box}.item-content{white-space:nowrap;text-overflow:ellipsis}`]
})
export class ShiftItemComponent implements AfterViewInit {

  /**
   * Sets the class item-header to component when isHeader is set to true.
   */
  @HostBinding('class.item-header') isHeader: boolean;

  /**
   * Sets the class flex-height to component when shift-button-expandable is in shift-item.
   */
  @HostBinding('class.flex-height') isFlexheight = false;

  /**
   * Sets the class item-divider to component when isDevider is set to true.
   */
  @HostBinding('class.item-divider') isDivider: boolean;

  /**
   * Sets the class 'item' to shift-item component.
   */
  @HostBinding('class.item') true;

  /**
   * Sets the class 'leftBorder' to shift-item component.
   */
  @HostBinding('class.leftBorder') get leftBorder(): boolean {
    return this.setLeftBorderClass;
  };

  /**
   * Sets the class 'with-padding' to shift-item component.
   */
  @HostBinding('class.with-padding') get padding(): boolean {
    return this.withPadding;
  };

  /**
   * Sets the class 'oneSlotFilled' to shift-item component when only one slot via ng-content was filled.
   */
  @HostBinding('class.oneSlotFilled') get oneSlot(): boolean {
    return this.nrOfProjections === 1 ? true : false;
  }

  /**
   * Sets the class 'twoSlotFilled' to shift-item component when two slots via ng-content was filled.
   */
  @HostBinding('class.twoSlotFilled') get twoSlots(): boolean {
    return this.nrOfProjections === 2 ? true : false;
  }

  /**
   * Sets the class 'threeSlotFilled' to shift-item component when three slots via ng-content was filled.
   */
  @HostBinding('class.threeSlotFilled') get threeSlots(): boolean {
    return this.nrOfProjections === 3 ? true : false;
  }

  /**
   * Sets the class 'modal-open' to shift-item while a modal like dropdown is open
   */
  @HostBinding('class.modal-open') isModalOpen: boolean;

  /**
   * Set the class 'item-content-empty' to shift-item when default ng-content did not get filled
   */
  @HostBinding('class.item-content-empty') get itemContentEmpty() {
    return this.isItemContentEmpty;
  }

  /**
   * Input to to set the class 'leftBorder' to the item-content and itemEnd of a shift-item.
   */
  @Input() setLeftBorderClass = false;

  /**
   * Input to disable padding for the shift-item slots. Class .with-padding will be removed from shift-item.
   */
  @Input() withPadding = true;

  /**
   * Reference to any shift-dropdown that was projected into the shift-item.
   */
  @ContentChild(ShiftDropdownComponent) modal: ShiftDropdownComponent;

  /**
   * number of content projections that were provided to the shift-item
   */
  private nrOfProjections: number;

  /**
   * property indicates if ng-content does got filled or not
   */
  private isItemContentEmpty = true;

  /**
   * Constructor checks which selector was used and sets the corresponding property for isHeader and isDevider.
   * @param _element
   */
  constructor(private _element: ElementRef, private renderer: Renderer2) {
    const nodeName = _element.nativeElement.nodeName;
    this.isHeader = nodeName === 'SHIFT-ITEM-HEADER';
    this.isDivider = nodeName === 'SHIFT-ITEM-DIVIDER';
  }

  /**
   * Implement AfterView Interface in order to listen if a modal is opened or not.
   */
  ngAfterViewInit() {
    if (this.modal) {
      this.modal.isOpen.subscribe((isOpen) => {
        this.isModalOpen = isOpen;
      });
    }
  }
  
  /**
   * AfterContentInit
   */
  ngAfterContentInit() {
    this.nrOfProjections = this.getNrOfPojections(); 
    if(this._element.nativeElement.querySelector('shift-button-expandable')) {
      this.isFlexheight = true;
    }
  }
  /**
   * On every change calculate and set the number of content children.
   */
  ngOnChanges() {
    this.nrOfProjections = this.getNrOfPojections(); 
  }

  /**
   * Function that lookup in the template, calculates and return the 
   * number of projections the user has provided to that shift-item
   */
  private getNrOfPojections(): number {
    const native: HTMLElement = this._element.nativeElement;
    let child: Element;
    let customProjections = 0;
    for(let i = 0; i < native.children.length; i++) {
      child = native.children.item(i);
      if(child.hasAttribute('itembegin') || child.hasAttribute('itemend')) {
        customProjections++;
      }
    }
    if(native.querySelector('.item-content').children.length === 0 && customProjections === 2) {
      this.isItemContentEmpty = true;
    } else {
      this.isItemContentEmpty = false;
    }
    return native.querySelector('.item-content').children.length + customProjections; 
  }
}
