import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { OnlineState } from './online-status/online-status.component';

/**
 * Header component with header title and several button options. And possibility to add own buttons.
 * Should be nested inside a shift-screen.
 * @example
 * <shift-screen>
 *                  <shift-header [title]="'Header text'"></shift-header>
 *                  <shift-main>
 *                      <!-- YOUR CONTENT -->
 *                  </shift-main>
 * </shift-screen>
 */
@Component({
    moduleId: module.id,
    // tslint:disable-next-line:component-selector
    selector: 'shift-header',
    template: `<header>
    <shift-spinner *ngIf="showLoadingIndicator"></shift-spinner>
    <img *ngIf="iconUrl!==''" class="icon" [src]="iconUrl">
    <div *ngIf="iconClass!==''" class="{{ iconClass }} font-icon"></div>

    <ng-content select="[beforeHeader]"></ng-content>
    <div class='title-wrapper'>
        <h1>{{ title }}</h1>
    </div>
    <ng-content select="[afterHeader]"></ng-content>

    <div class='button-wrapper'>
        <online-status [onlineState]="onlineState" *ngIf="onlineState"></online-status>
        <div *ngIf="headIcon"
            class="head-icon"
            [class.alone]="buttons.length===0"
            [ngClass]="'head-icon--'+headIcon">
        </div>
        <button *ngFor="let btn of buttons; let i = index"
            type='button'
            class="button"
            [class.wide]="i < buttons.length-1"
            [ngClass]="'button--'+btn"
            (click)="onButtonClicked($event, btn)">
        </button>
    </div>
</header>
`,
    styles: [`header{position:relative}`]
})
export class ShiftHeaderComponent {

    /**
     * Sets an icon url for an icon that should be shown in front of the header title.
     */
    @Input() public iconUrl = '';

    /**
     * If this is set a div with the set class will be available in front of the header title.
     * With its class you can style your own css-property based icon.
     */
    @Input() public iconClass = '';

    /**
     * Sets the header title.
     */
    @Input() public title = '';

    /**
     * Sets the online state with its different options by providing an OnlineState object.
     */
    @Input() onlineState: OnlineState;

    /**
     * Array that gets the wished buttons as string. Buttons will be displayed in the header left to right. 
     * So the last entry of the array will be displayed at the end (right).
     * There will be classes set for your provided buttons like the following: 'button--yourProvidedButton'
     * You can use this class to set a background for example or style it otherwise.
     * E.g. for providing a legal button with the string 'legal' there will be the following class applied: 'button--legal'.
     */
    @Input() public buttons: Array<'legal' | 'info' | 'back' | 'close' | string> = [];

    /**
     * String that will represent the wished headIcon. 
     * There will be classes set for your provided icon like the following: 'button--yourProvidedIcon'
     * You can use this class to set a background for example or style it otherwise.
     * E.g. for providing a user icon with the string 'icon' there will be the following class applied: 'icon--user'.
     */
    @Input() public headIcon: 'agent' | 'user' | 'marker' | 'navi' | string;

    /**
     * Switch to show the loading indicator
     */
    @Input() public showLoadingIndicator = false;

    /**
     * EventEmitter that will emit the string of the clicked Button.
     */
    @Output() public buttonClicked: EventEmitter<string> = new EventEmitter();

    /**
     * Constructor for the header component
     */
    constructor() {}

    /**
     * Function that triggers the buttonClicked event emmiter.
     */
    public onButtonClicked(event: any, btn: string): void {
        if(this.buttonClicked.observers.length === 0) {
            console.warn('buttonClicked listener is not working - please subscribe to buttonClicked EventEmitter in your component.ts');
        } else {
            this.buttonClicked.emit(btn);
        }
    }
}
