import { Popup2AbstractStateModel } from './popup2-abstract-state.model';
import { Popup2AbstractStateContextModel } from './popup2-abstract-state-context.model';
import { Popup2StateOpenEnsureMinTimeModel } from './popup2-state-open-ensure-min-time.model';
import { Popup2StateOpenWithTimeoutModel } from './popup2-state-open-with-timout.model';
import { Popup2StateOpenModel } from './popup2-state-open.model';

/**
 * A class which represents the state where no popup is shown.
 */
export class Popup2StateClosedModel extends Popup2AbstractStateModel {

  constructor (private stateContext: Popup2AbstractStateContextModel) {
    super();
  }

  /** Check if there is a new popup available on enter */
  public onEnter() {
    this.newPopupAvailable();
  }

  /** Check for a new popup. Depending on the kind of popup, switch to the next suitable state. */
  public newPopupAvailable() {
    const nextPopup = this.stateContext.getPopup2BeShownNext();
    if (nextPopup) {
      this.stateContext.showPopup(nextPopup);

      let nextState: Popup2AbstractStateModel;
      if (nextPopup.showAtLeastNMilliseconds > 0) {
        nextState = new Popup2StateOpenEnsureMinTimeModel(this.stateContext);
      } else if (nextPopup.closeAfterNMilliseconds > 0) {
        nextState = new Popup2StateOpenWithTimeoutModel(this.stateContext);
      } else {
        nextState = new Popup2StateOpenModel(this.stateContext);
      }
      this.stateContext.switch2State(nextState);
    }
  }

  /** Not possible when popup is closed */
  public buttonPressed() {
    // not possible
  }

  /** Does nothing since popup is closed already */
  public closePopup() {
    // already closed
  }

}
