import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { RSISocket } from './rsi.socket';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { RSIConfig } from './rsi.config';
import { ISplitEventResult, splitEvent } from './util';

interface URLCheckResult {
  url: string;
}

export interface IService {
  host: string;
  ws: RSISocket;
  originalUrl: string;
}

export interface IPendingCheck {
  promise: Promise<IService>;
}

export interface IRedirectEvent {
  from: string;
  to: string;
}

@Injectable()
export class ServiceLocator {
  public onReset: EventEmitter<any> = new EventEmitter<any>();
  public onRedirect: EventEmitter<IRedirectEvent> = new EventEmitter<IRedirectEvent>();

  private services: { [index: string]: IService } = {};
  private pendingChecks: { [index: string]: IPendingCheck } = {};
  private sockets: { [index: string]: RSISocket } = {};

  constructor(private config: RSIConfig, private http: HttpClient, private zone: NgZone) {
    this.config.onChange.subscribe(() => this.reset());
  }


  async getService(url: string): Promise<IService> {
    const parts: ISplitEventResult = splitEvent(url);
    if (this.services.hasOwnProperty(parts.service)) {
      return this.existingService(parts.service);
    } else if (this.pendingChecks.hasOwnProperty(parts.service)) {
      return this.pendingService(parts.service);
    } else {
      return this.createService(url);
    }
  }

  public existingService(serviceName: string): Promise<IService> {
    return new Promise((resolve, reject) => {
      resolve(this.services[serviceName]);
    });
  }


  public async pendingService(serviceName: string): Promise<IService> {
    return this.pendingChecks[serviceName].promise;
  }

  getUrlFromServiceList(url: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.http.get(this.config.getAPIPath()).toPromise();
        const urlParts = new URL(url);
        const parts: ISplitEventResult = splitEvent(urlParts.pathname);
        const service = resp.data.find(x => x.name === parts.service);

        resolve(this.config.getHost() + ':' + service.port + urlParts.pathname);
      } catch (e) {
        reject();
      }
    });
  }


  checkUrl(url: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        if (url.indexOf('http') === -1) {
          url = this.config.getAPIPath();
        }
        const response: HttpResponse<Object> = await this.http.get(url, { observe: 'response' }).toPromise();
        resolve(response.url);
      } catch (e) {
        try {
          const response = await this.getUrlFromServiceList(url);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      }
    });
  }


  async createService(url: string): Promise<IService> {
    let resolve: Function = null;
    let reject: Function = null;
    const promise: Promise<IService> = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });

    const parts: ISplitEventResult = splitEvent(url);
    this.pendingChecks[parts.service] = {
      promise: promise
    };

    try {
      const serviceURL: string = await this.checkUrl(this.config.getAPIPath() + url);
      const urlParts = new URL(serviceURL);
      if (serviceURL !== this.config.getAPIPath() + url) {
        this.onRedirect.emit({
          from: this.config.getAPIPath() + url,
          to: serviceURL
        });
      }

      const config = new RSIConfig({
        server: {
          port: parseInt(urlParts.port, 10),
          address: urlParts.hostname,
          protocol: 'http',
          socketprotocol: 'ws',
          api: ''
        }
      });

      const socket: RSISocket = this.getSocket(config);
      let host = urlParts.protocol + '//' + urlParts.host;
      if (this.config.configData.server.api !== '') {
        host = host + '/' + this.config.configData.server.api;
      }
      const service: IService = {
        host: host,
        ws: socket,
        originalUrl: url
      };
      this.services[parts.service] = service;
      delete this.pendingChecks[parts.service];
      resolve(service);
    } catch (e) {
      reject(e);
      delete this.pendingChecks[parts.service];
    }
    return promise;
  }

  private reset() {
    Object.keys(this.sockets).forEach(key => this.sockets[key].close());

    this.services = {};
    this.pendingChecks = {};
    this.sockets = {};
    this.onReset.emit();
  }

  private getSocket(config: RSIConfig): RSISocket {
    let socket: RSISocket;
    const path = config.getSocketPath();

    if (this.sockets.hasOwnProperty(path)) {
      socket = this.sockets[path];
    } else {
      socket = new RSISocket(this.zone, config);
      this.sockets[path] = socket;
    }
    return socket;
  }
}
