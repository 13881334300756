import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { RSIService } from '@shift/rsi';
import { MibLogService } from '@shift/core';

import { UserRoleEnum } from './user-role.enum';
import { usermanagement } from '../../interfaces/usermanagement';

@Injectable()
export class UserService {
    public users: BehaviorSubject<usermanagement.IUserObject[]>;
    public activeUser: BehaviorSubject<usermanagement.IUserObject>;
    public primaryUser: BehaviorSubject<usermanagement.IUserObject>;
    public isPrimaryUser: BehaviorSubject<boolean>;

    constructor(private rsi: RSIService, private logger: MibLogService) {
        this.users = new BehaviorSubject([]);
        this.activeUser = new BehaviorSubject({});
        this.primaryUser = new BehaviorSubject({});
        this.isPrimaryUser = new BehaviorSubject(false);
    }

    public init() {
        this.subscribeToUserContext();
        this.subscribeToUsers();
    }

    /**
     * Subscribe to userContext
     */
    private subscribeToUserContext(): void {
        this.rsi.firstEndpoint(
            '/usermanagement/userContexts/',
            {
                autoGetData: false,
                reconnectCount: 5,
                reconnectDelay: 3000
            }
        ).subscribe((userContext: usermanagement.IUserContextObject) => {
            if (userContext.activeUser) {
                this.activeUser.next(userContext.activeUser);
                const isPrimary = userContext.activeUser.userRole === UserRoleEnum.primaryUser;
                this.isPrimaryUser.next(isPrimary);
            }
        });
    }

    /**
     * Subscribe to rsi users
     */
    private subscribeToUsers(): void {
        this.rsi.endpoint(
            '/usermanagement/users/',
            {
                autoGetData: false,
                reconnectCount: 5,
                reconnectDelay: 3000
            }
        ).subscribe((users: usermanagement.IUserObject[]) => {
            this.users.next(users);
            this.setPrimaryUser(users);
        });
    }

    /**
     * set primaryUser
     * @param users {usermanagement.IUserObject[]}
     */
    private setPrimaryUser(users: usermanagement.IUserObject[]): void{
        for (const user of users) {
            console.log('>>>> USERROLE', user.userRole);
            if (user.userRole === UserRoleEnum.primaryUser) {
                this.primaryUser.next(user);
                break;
            }
        }
    }

}
