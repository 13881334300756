/**
 * A wrapper around a standard WebSocket, which adds reconnecting behaviour.
 *
 * This behaves like a WebSocket in every way, except if it fails to connect
 * or gets disconnected, it will repeatedby try to reconnect. It is a thus
 * a drop-in replacement for WebSocket.
 */
export class ReconnectingWebSocket {
    /** WebSocket states */
    public static CONNECTING = WebSocket.CONNECTING;
    public static OPEN = WebSocket.OPEN;
    public static CLOSING = WebSocket.CLOSING;
    public static CLOSED = WebSocket.CLOSED;

    /** default options */
    private static defaults: { [key: string]: any } = {
        debug: true,
        automaticOpen: true,
        reconnect: 1000,
        decay: 1.5,
        timeout: 2000,
        maxAttempts: null,
        maxReconnect: 30000
    };

    /** web socket url */
    protected url: string;

    /** connection options */
    protected options: { [key: string]: any };

    /** number of attempted connects since start or last connection. */
    protected attempts: number;

    /** current state of the connection (CONNECTING, OPEN, CLOSING, CLOSED) */
    protected readyState: number;

    /** list of the sub-protocols */
    protected protocol: string;

    /** name of the sub-protocol */
    protected protocols: string[];

    /** wrapped web socket */
    protected ws: WebSocket;

    /** forced close indicator */
    private forcedClose: boolean;

    /** time-out indicator */
    private timedOut: boolean;

    /* ---- default event handlers ---- */

    /**
     * An event listener to be called, when the connection's readyState changes
     * to OPEN; this.indicates, that the connection is ready to send and receive
     * data.
     */
    public onopen: (ev: Event) => void = (ev: Event): void => {};

    /** Called when the connections readyState changes to CLOSED. */
    public onclose: (ev: CloseEvent) => void = (ev: Event): void => {};

    /** Called when a connection attempt is made. */
    public onconnecting: () => void = () => {};

    /** Called when a message is received from the server */
    public onmessage: (ev: MessageEvent) => void = (ev: MessageEvent) => {};

    /** Called when an error occurs. */
    public onerror: (ev: ErrorEvent) => void = (ev: ErrorEvent) => {};

    /**
     * Default constructor.
     *
     * @param url       Web socket URL
     * @param protocols list of protocols
     * @param options   connection options
     */
    constructor(url: string, protocols: string[] = [], options: { [key: string]: any } = {}) {
        this.url = url;
        this.attempts = 0;
        this.readyState = ReconnectingWebSocket.CONNECTING;
        this.protocols = protocols;
        this.options = {};

        // Copy the given options and the default options into one map.
        for (const key in ReconnectingWebSocket.defaults) {
            if (typeof options[key] !== 'undefined') {
                this.options[key] = options[key];
            } else {
                this.options[key] = ReconnectingWebSocket.defaults[key];
            }
        }

        // Check, if the connection should be opened automatically.
        if (this.options['automaticOpen']) {
            this.open(false);
        }
    }

    /**
     * Open the web socket connection.
     */
    public open(reconnectAttempt: boolean) {
        this.ws = new WebSocket(this.url, this.protocols);

        // Call the event handler
        this.onconnecting();

        // Time-out timer for reconnect attempts
        const timer = setTimeout(() => {
            this.log('ReconnectingWebSocket', 'timeout', this.url);
            this.timedOut = true;
            this.ws.close();
            this.timedOut = false;
        }, this.options['timeout']);

        // web socket on open event handler
        this.ws.onopen = (event: Event) => {
            clearTimeout(timer);
            this.log('ReconnectingWebSocket', 'onopen', this.url);
            this.protocol = this.ws.protocol;
            this.readyState = ReconnectingWebSocket.OPEN;
            this.attempts = 0;
            reconnectAttempt = false;
            this.onopen(event);
        };

        // web socket on close event handler, which basically contains all
        // the necessary reconnect behaviour
        this.ws.onclose = (event: CloseEvent) => {
            clearTimeout(timer);
            // this.ws = null;
            if (this.forcedClose) {
                this.readyState = ReconnectingWebSocket.CLOSED;
                this.onclose(event);
            } else {
                this.readyState = ReconnectingWebSocket.CONNECTING;
                this.onconnecting();
                if (!reconnectAttempt && !this.timedOut) {
                    this.log('RecconectingWebSocket', 'onclose', this.url);
                    this.onclose(event);
                }

                // calculate next reconnect time based an attempts and decay
                const timeout = this.options['reconnect'] * Math.pow(this.options['decay'], this.attempts);
                setTimeout(() => {
                    this.attempts++;
                    this.open(true);
                }, timeout > this.options['maxReconnect'] ? this.options['maxReconnect'] : timeout);
            }
        };

        // web socket message reception handler
        this.ws.onmessage = (event: MessageEvent) => {
            this.log('ReconnectingWebSocket', 'onmessage', this.url, event.data);
            this.onmessage(event);
        };

        // web socket error handler
        this.ws.onerror = (event: ErrorEvent) => {
            this.log('ReconnectingWebSocket', 'onerror', this.url);
            this.onerror(event);
        };
    }

    /**
     * Sends data over the web socket to the server.
     *
     * @param data  a text string, ArrayBuffer or Blob to send
     */
    public send(data: any) {
        if (this.ws) {
            this.log('ReconnectingWebSocket', 'send', this.url, data);
            this.ws.send(data);
        } else {
            throw this.forcedClose
                ? 'INVALID_STATE_ERR : websocket closed'
                : 'INVALID_STATE_ERR : Pausing to reconnect websocket';
        }
    }

    /**
     * Closes the websocket connection or connect attempt, if any.
     * If the connection is already closed, this method does nothing.
     */
    public close(code: number = 1000, reason?: string) {
        // default close code is CLOSE_NORMAL (1000)
        this.forcedClose = true;
        if (this.ws) {
            this.ws.close(code, reason);
        }
    }

    /**
     * Refresh the connection, if still open (close, reconnect).
     */
    public refresh() {
        if (this.ws) {
            this.ws.close();
        }
    }

    /**
     * Create a debug log output.
     */
    protected log(...args: any[]) {
        if (this.options['debug']) {
            // console.log(args);
        }
    }
}
