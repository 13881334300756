import {UrlUtil} from '../util/url.util';
import {RSIService} from '@shift/rsi';
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class WebappConfig {


  public SERVICE_ID: string;
  public ENTRY_POINT_ID: string;
  public ENTRY_POINT_TYPE: string;
  public engineinstanceURI: string;
  public languages: any;
  public isSimulator = false;

  constructor(private rsi: RSIService,
              private http: HttpClient,
              @Inject('WEBAPP_ENVIRONMENT') private environment: any) {

  }

  init() {
    const w: any = window;

    let simData: any = {};
    if (localStorage.getItem(location.href)) {
      const data = localStorage.getItem(location.href);
      try {
        simData = JSON.parse(data);
      } catch (e) {

      }
    }


    if (!w.hasOwnProperty('ENTRY_POINT_TYPE') && UrlUtil.getParameterByName('ENTRY_POINT_TYPE')) {
      w.ENTRY_POINT_TYPE = UrlUtil.getParameterByName('ENTRY_POINT_TYPE');
      this.isSimulator = true;
    } else if (simData.hasOwnProperty('ENTRY_POINT_TYPE')) {
      this.isSimulator = true;
      w.ENTRY_POINT_TYPE = simData.ENTRY_POINT_TYPE;
    }
    this.ENTRY_POINT_TYPE = w.ENTRY_POINT_TYPE;

    if (!w.hasOwnProperty('ENTRY_POINT_ID') && UrlUtil.getParameterByName('ENTRY_POINT_ID')) {
      w.ENTRY_POINT_ID = UrlUtil.getParameterByName('ENTRY_POINT_ID');
    } else if (simData.hasOwnProperty('ENTRY_POINT_ID')) {
      w.ENTRY_POINT_ID = simData.ENTRY_POINT_ID;
    }
    this.ENTRY_POINT_ID = w.ENTRY_POINT_ID;


    if (UrlUtil.getParameterByName('engineinstanceURI')) {
      w.engineinstanceURI = UrlUtil.normalizeEngineinstanceURI(UrlUtil.getParameterByName('engineinstanceURI'));
    } else if (UrlUtil.getParameterByName('engineInstanceURI')) {
      w.engineinstanceURI = UrlUtil.normalizeEngineinstanceURI(UrlUtil.getParameterByName('engineInstanceURI'));
    } else if (simData.hasOwnProperty('engineinstanceURI')) {
      w.engineinstanceURI = simData.engineinstanceURI;
    }
    this.engineinstanceURI = w.engineinstanceURI;

    if (UrlUtil.getParameterByName('VIWI_PORT')) {
      this.environment.VIWI_PORT = parseInt(UrlUtil.getParameterByName('VIWI_PORT'));
    } else if (simData.hasOwnProperty('VIWI_PORT')) {
      this.environment.VIWI_PORT = parseInt(simData.VIWI_PORT, 10);
    }

    if (UrlUtil.getParameterByName('VIWI_HOST')) {
      this.environment.VIWI_HOST = UrlUtil.getParameterByName('VIWI_HOST');
    } else if (simData.hasOwnProperty('VIWI_HOST')) {
      this.environment.VIWI_HOST = simData.VIWI_HOST;
    }

    if (this.environment.hasOwnProperty('SHIFT_SERVICE_ID')) {
      this.SERVICE_ID = this.environment['SHIFT_SERVICE_ID'];
    }

    this.rsi.VIWI_PORT = this.environment.VIWI_PORT;

    this.rsi.rsi.config.configData = {
      server: {
        port: this.environment.VIWI_PORT,
        address: this.environment.VIWI_HOST,
        protocol: 'http',
        socketprotocol: 'ws',
        api: this.environment.VIWI_API_PREFIX
      }
    };


    const dataToStore: any = {
      ENTRY_POINT_TYPE: w.ENTRY_POINT_TYPE,
      ENTRY_POINT_ID: w.ENTRY_POINT_ID,
      engineinstanceURI: w.engineinstanceURI,
      VIWI_PORT: this.environment.VIWI_PORT,
      VIWI_HOST: this.environment.VIWI_HOST

    };

    if (this.isSimulator) {
      window.addEventListener('beforeunload', function (event) {
        localStorage.setItem(location.href, JSON.stringify(dataToStore));
      });
    } else {
    }
  }


  private async loadSupportedLanguages() {
    return this.http.get('./assets/json/languages.json').toPromise();
  }

  async load() {
    try {
      this.languages = await this.loadSupportedLanguages();
    } catch (e) {

    }
    this.init();
  }


}
