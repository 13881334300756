import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

/**
 * declaration of zxcvbn library
 */
declare var zxcvbn: any; // TODO use real type def for zxcvbn lib ?!

// var zxcvbn = require('zxcvbn'); // TODO use real type def for zxcvbn lib ?!

/**
 * Emits an {@link #strengthChanged} event when the strength of the password,
 * that was entered into the attached input field, has changed.
 *
 * The strength of the entered password is calculated using the library zxcvbn.
 */
@Directive({
    selector: '[shiftPwdStrengthIndicatorInput]'
})
export class ShiftPwdStrengthIndicatorDirective {
    /**
     * Event emits if strength of entered password has changed
     */
    @Output() strengthChanged = new EventEmitter<number>();

    /**
     * Listens on 'input' event and execute updateStrength function
     */
    @HostListener('input')
    oninput() {
        this.updateStrength(this.elem.nativeElement.value);
    }
    /**
     * Stores strength of password.
     */
    private strength = -1;

    /**
     * Constructor of pwd strength indicator directive
     * @param elem 
     */
    constructor(private elem: ElementRef) {}

    /**
     * Function calculates new password strength based on zxcvbn library
     * @param pwd 
     */
    private updateStrength(pwd: string) {
        if (typeof zxcvbn === 'undefined') {
            console.error('Please load zxcvbn library before using ShiftPwdStrengthIndicatorDirective.');
            return;
        }
        if (pwd.length === 0) {
            this.strength = -1;
            this.strengthChanged.emit(-1);
            return;
        }

        const newStrength = zxcvbn(pwd).score;

        if (this.strength !== newStrength) {
            this.strength = newStrength;
            this.strengthChanged.emit(this.strength);
        }
    }
}
