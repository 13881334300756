import { ModuleWithProviders, NgModule } from '@angular/core';
import { RSIFactory } from './rsi.factory';
import { ServiceLocator } from './service.locator';
import { HttpClientModule } from '@angular/common/http';
import { RSIService } from './rsi.service';

@NgModule({
    imports: [HttpClientModule],
    declarations: [],
    exports: [],
    providers: []
})
export class RSIServicesModule {
    static forRoot(config:any): ModuleWithProviders {
        return {
            ngModule: RSIServicesModule,
            providers: [RSIFactory, ServiceLocator, RSIService, { provide: 'RSI_CONFIG', useValue: config }]
        };
    }
}
