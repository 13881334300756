import { Popup2AbstractStateModel } from './popup2-abstract-state.model';
import { Popup2AbstractStateContextModel } from './popup2-abstract-state-context.model';
import { Popup2StateClosingModel } from './popup2-state-closing.model';

/**
 * A class which represents the state where popup is open.
 */
export class Popup2StateOpenModel extends Popup2AbstractStateModel {
  constructor(private stateContext: Popup2AbstractStateContextModel) {
    super();
  }

  /**
   * Does nothing.
   */
  public onEnter() {
    // nothing to do
  }

  /**
   * Check if new popup must be displayed. If true, close current popup.
   */
  public newPopupAvailable() {
    if (this.stateContext.mustSwitch2NextPopup()) {
      this.closePopup();
    }
  }

  /**
   * Close the popup on button pressed.
   */
  public buttonPressed() {
    this.closePopup();
  }

  /**
   * Create popup state 'closing' and switch to this state.
   */
  public closePopup() {
    const nextState = new Popup2StateClosingModel(this.stateContext);
    this.stateContext.switch2State(nextState);
  }
}
