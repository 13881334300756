import { Popup2AbstractStateModel } from './popup2-abstract-state.model';
import { Popup2AbstractStateContextModel } from './popup2-abstract-state-context.model';

/**
 * A class which represents the state where the popup is about to close.
 * This may take some milliseconds to finish the css-animated transition.
 */
export class Popup2StateClosingModel extends Popup2AbstractStateModel {
  /** The time in milliseconds to keep this state */
  private debounceTimeBeforeOpeningNextPopupMilliseconds = 400;

  constructor (private stateContext: Popup2AbstractStateContextModel) {
    super();
  }

  /** Close the popup. Start timer whose timeout leads to the closed state */
  public onEnter() {
    this.stateContext.closePopup();
    this.ensureDebounceTimeBeforeOpeningNextPopup();
  }

  /**
   * Do nothing, keep this state until timeout.
   */
  public newPopupAvailable() {
    // ignore in this state
  }

  /**
   * Popup is closing, button press is impossible or will be ignored.
   */
  public buttonPressed() {
    // ignore in this state
  }

  /**
   * Start timer. Call setPopupClosed() on timeout.
   */
  private ensureDebounceTimeBeforeOpeningNextPopup() {
    setTimeout(() => {
      this.setPopupClosed();
    }, this.debounceTimeBeforeOpeningNextPopupMilliseconds);
  }

  /**
   * Set Popup to state closed.
   */
  private setPopupClosed() {
    this.stateContext.resetPopupState2Closed();
  }

  /**
   * Nothing to do since popup is already about to be closed.
   */
  public closePopup() {
    // already closing
  }
}
