import { Component, ElementRef, Input, Output, EventEmitter, HostBinding } from '@angular/core';

/**
 * Overlay component that lays over your screen with predefined content OR own content (with ng-content)
 */
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'shift-overlay',
    template: `<div class="dim"></div>

<!-- Show dynamic content set from outside -->
<div class="content dynamicContent" #dynamicContent>
    <ng-content></ng-content>
</div>

<!-- Show if no dynamic content is set from outside -->
<div class="content" *ngIf="dynamicContent.children.length == 0 && !loading">
    <div class="text-area">
        <span>{{text}}</span>
    </div>
    <div class="button-area">
        <shift-button class="text-button" (click)="close()">
            Ok
        </shift-button>
    </div>
</div>

<div class="content" *ngIf="dynamicContent.children.length == 0 && loading">
    <div class="loading" [class.loadingDemoMode]="loadingDemoMode">
        <div class="loading-pulse" *ngIf="loadingAnimation === 1"></div>
        <div class="loading-circle" *ngIf="loadingAnimation === 2"></div>
    </div>
    <div class="loading-text" *ngIf="loadingDemoMode">
        <shift-button (click)="nextLoadingAnimation()">
            Animation {{loadingAnimation}}
        </shift-button>
        <shift-button (click)="close()">
            Close
        </shift-button>
    </div>
</div>`,
    styles: [`:host{position:absolute;top:0;left:0;width:100%;height:100%}`]
})
export class OverlayComponent {

    /**
     * Switch for opening the overlay. Class 'open' will be applied if true.
     */
    @HostBinding('class.open')
    @Input()
    public open: boolean;

    /**
     * Emits an event if overlay state was changed. Provides true if opened and false if closed.
     */
    @Output() public openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Switch for showing predefined loading animation.
     */
    @Input() public loading: boolean;

    /**
     * Switch for showing predefined demo.
     */
    @Input() public loadingDemoMode = false;

    /**
     * Sets text in overlay.
     */
    @Input() public text = ''; // overlay text

    /**
     * Sets active loading animation in demo
     */
    @Input() public loadingAnimation = 1;

    /**
     * Constant that saves the number of predefined loading animations.
     */
    private readonly numLoadingAnimations = 2; 

    /**
     * Constructor for overlay component.
     * @param el 
     */
    constructor(private el: ElementRef) {}

    /**
     * Function that emits the output openChange with false.
     */
    public close(): void {
        this.open = false;
        this.openChange.emit(this.open);
    }

    /**
     * Function that loads the next animation of the demo.
     */
    public nextLoadingAnimation(): void {
        this.loadingAnimation++;
        if (this.loadingAnimation > this.numLoadingAnimations) {
            this.loadingAnimation = 1;
        }
    }

    /**
     * Function that loads the previous animation of the demo.
     */
    public previousLoadingAnimation(): void {
        this.loadingAnimation--;
        if (this.loadingAnimation < 1) {
            this.loadingAnimation = this.numLoadingAnimations;
        }
    }
}
