import { AfterViewInit, Directive, ElementRef, EventEmitter, HostBinding, NgZone, Output } from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[pressGesture]'
})
export class PressGestureDirective implements AfterViewInit {
    @Output() onPress: EventEmitter<{}> = new EventEmitter();
    @Output() onRelease: EventEmitter<{}> = new EventEmitter();

    private _pressing: boolean;
    @HostBinding('class.pressing')
    get pressing(): boolean {
        return this._pressing;
    }

    constructor(public el: ElementRef, private ngZone: NgZone) {}

    ngAfterViewInit(): void {
        const options: HammerOptions = {
            touchAction: 'manipulation'
        };
        const hammertime: HammerManager = new Hammer(this.el.nativeElement, options);
        hammertime.on('hammer.input', (ev: HammerInput) => {
            this.ngZone.run(() => {
                if (ev.srcEvent.type === 'touchstart') {
                    this._pressing = true;
                    this.onPress.emit(ev);
                } else if (ev.srcEvent.type === 'touchend') {
                    this._pressing = false;
                    this.onRelease.emit(ev);
                }
            });
        });
    }
}
