import { Component, OnInit } from '@angular/core';

/**
 * Arrow asset component that can be used as subcomponent
 */
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'asset-arrow-c',
    template: `<svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39">
  <defs>
  </defs>
  <path id="arrow-c" data-name="Form 26 Kopie 6" class="cls-1" d="M8.446,6.553l17.8,13.432L8.446,33.422a1.514,1.514,0,0,0-.31,2.083h0a1.433,1.433,0,0,0,2.036.317h0l19.39-14.636a1.5,1.5,0,0,0,.592-1.2h0a1.5,1.5,0,0,0-.592-1.2h0L10.172,4.153v0a1.432,1.432,0,0,0-2.036.32h0a1.511,1.511,0,0,0-.283.883h0a1.5,1.5,0,0,0,.592,1.2h0Z"
  />
</svg>
`,
    styles: [`.cls-1{fill-rule:evenodd}`]
})
export class ArrowCAssetComponent implements OnInit {
    /**
     * Constructor for the arrow asset component
     */
    constructor() {}

    /**
     * default implementation of ngOnInit-interface function.
     */
    ngOnInit() {}
}
