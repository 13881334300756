import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ShiftButtonModule } from '../button/button.module';
import { ShiftListModule } from '../list/list.module';
import { ShiftDropdownComponent } from './dropdown.component';

@NgModule({
    imports: [CommonModule, ShiftButtonModule, ShiftListModule],
    exports: [ShiftDropdownComponent],
    declarations: [ShiftDropdownComponent]
})
export class ShiftDropdownModule {
    /** @deprecated */
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ShiftDropdownModule,
            providers: []
        };
    }
}
