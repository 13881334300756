import { Component, Input } from '@angular/core';

/**
 * Sub component that can show the online state
 */
@Component({
    moduleId: module.id,
    selector: 'online-status',
    template: `<div id='online-state'>
    <img class='connection-type' *ngIf='onlineState.isConnectionType4G()' src='/assets/shift/ui/icons/F300/Online_Status_LTE.png'>
    <img class='connection-type' *ngIf='onlineState.isConnectionType3G()' src='/assets/shift/ui/icons/F300/Online_Status_UMTS_3G.png'>

    <img class='connection-type' *ngIf='onlineState.isRoaming' src='/assets/shift/ui/icons/F300/Online_Status_Roaming.png'>
    <img class='connection-type' *ngIf='!onlineState.isRegistered' src='/assets/shift/ui/icons/F300/Online_Status_None.png'>

    <img *ngIf='onlineState.isOnline' src='/assets/shift/ui/icons/F300/Online_Status_1.png'>
    <img *ngIf='!onlineState.isOnline' src='/assets/shift/ui/icons/F300/Online_Status_2.png'>
</div>
`,
    styles: [``]
})
export class OnlineStatusComponent {

    /**
     *  Gets as input an online state object that is used in the template.
     */
    @Input() onlineState: OnlineState;

    /**
     * Constructor for the online state component.
     */
    constructor() { }
}

/**
 *  Online state model that defines what an online state contains.
 */
export class OnlineState {
    /**
     * Constant-string for 4g connection type
     */
    readonly CONNECTION_TYPE_4G = '4g';

    /**
     * Constant-string for 3g connection type
     */
    readonly CONNECTION_TYPE_3G = '3g';

    /**
     * Stores the connection type
     */
    connectionType = '';

    /**
     * Stores the online state information
     */
    isOnline = false;

    /**
     * Stores the network registration information
     */
    isRegistered = false;

    /**
     * Stores the roaming registration information
     */
    isRoaming = false;

    /**
     * Returns true if the property connectionType is set to 4g
     */
    isConnectionType4G(): boolean {
        if(this.connectionType === this.CONNECTION_TYPE_4G) {
            return true;
        }
        return false;
    }

    /**
     * Returns true if the property connectionType is set to 3g
     */
    isConnectionType3G(): boolean {
        if(this.connectionType === this.CONNECTION_TYPE_3G) {
            return true;
        }
        return false;
    }
}