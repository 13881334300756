import { AfterContentInit, Component, ContentChild, Input, ViewChild } from '@angular/core';

import { ShiftPwdStrengthIndicatorComponent } from './pwd-strength-indicator.component';
import { ShiftPwdStrengthIndicatorDirective } from './pwd-strength-indicator.directive';

/**
 * Combines {@link ShiftPwdStrengthIndicatorComponent} with {@link ShiftPwdStrengthIndicatorDirective}.
 *
 * The native password input field wrapped by the shift-pwd-strength-indicator-container
 * must be marked with the shiftPwdStrengthIndicatorInput directive.
 *
 * @example
 * <shift-pwd-strength-indicator-container>
 *                      <input type="password" shiftPwdStrengthIndicatorInput>
 * </shift-pwd-strength-indicator-container>
 */
@Component({
    selector: 'shift-pwd-strength-indicator-container',
    template: `<ng-content></ng-content>
<shift-pwd-strength-indicator [strengthTexts]="strengthTexts" [isRtl]='isRtl'></shift-pwd-strength-indicator>
`
})
export class ShiftPwdStrengthIndicatorContainerComponent implements AfterContentInit {
    /**
     * 'Mirroring' the pwd strength indicator that text is read from right to left. Usable for arabic language e.g.
     */
    @Input() isRtl: boolean;

    /**
     * Sets the strength texts as array that will be displayed when entering a password for strenght indication.
     * First element e.g. 'very bad', last element e.g. 'very good'
     */
    @Input() strengthTexts: string[];

    /**
     * ShiftPwdStrengthIndicatorDirective as content child
     */
    @ContentChild(ShiftPwdStrengthIndicatorDirective) directive: ShiftPwdStrengthIndicatorDirective;

    /**
     * Sub component as view child reference.
     */
    @ViewChild(ShiftPwdStrengthIndicatorComponent) component: ShiftPwdStrengthIndicatorComponent;

    /**
     * Implementation of AfterContentInit where directive and component are linked
     */
    ngAfterContentInit() {
        this.directive.strengthChanged.subscribe((strength: number) => {
            this.component.strength = strength;
        });
    }
}
