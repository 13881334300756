import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, OnDestroy, Output, Renderer } from '@angular/core';
import { GesturesService } from '../gestures.service';
import { GesturesThresholds } from '../gestures-thresholds';
import { TweenManagerService } from '../animation/tween-manager.service';
import { Subscription } from 'rxjs';

/**
 * List should use the [rubberbandVertical] directive, f.e. w/ CSS { position: 'relative'; height: auto; }
 * List-Parent should use 'overflow-y: hidden' or 'scroll' with a viable height
 * List-Items will "snap" with the StyleClass "snapY"
 * Gradients should be named (StyleID or StyleClass) 'gradient-top' and 'gradient-bottom'
 */
@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[rubberbandVertical]'
})
export class RubberbandVerticalDirective implements AfterViewInit, OnDestroy {
    @Output() onScroll: EventEmitter<{}> = new EventEmitter();
    @Output() onScrollTo: EventEmitter<{}> = new EventEmitter<number>();

    private _props: any;
    private _pageSwipe = true;
    private _gestureStarted = false;
    private _scrollable: boolean;
    private _scrollTopStart: number;
    private _contentHeight: number;
    private _parentHeight: number;
    private _minTop: number;
    private _maxTop: number;
    private _lastDeltaY: number;
    private _deltaY: number;
    private _direction: number;
    private initialOffset: number;
    private lastSnapIndex: number;
    private positions: Array<any>;
    private twoFingerEventSubscription: Subscription;
    private _parentBounds: any;

    // List Gradients
    private _gradientBottom: any;
    private _gradientTop: any;
    private _gradientBottomVisible: boolean;
    private _gradientTopVisible: boolean;

    constructor(public el: ElementRef,
                private gesturesService: GesturesService,
                private renderer: Renderer,
                @Inject(TweenManagerService) private tweenManagerService: TweenManagerService) {
        renderer.listen(el.nativeElement, 'wheel', (e: any) => {
            this.onMouseWheel(e);
        });
    }

    /**
     * After view init
     */
    ngAfterViewInit(): void {
        this._props = {x: 0, y: 0, z: 0};

        const options: HammerOptions = {touchAction: 'manipulation'};
        const hammertime: HammerManager = new Hammer(this.el.nativeElement, options);
        const pan: Recognizer = hammertime.get('pan');
        pan.set({
            threshold: GesturesThresholds.GRID_PAN_LEFT_THRESHOLD,
            direction: Hammer.DIRECTION_VERTICAL,
            pointers: 1
        });

        // On pan
        hammertime.on('panstart pan panend', (ev: HammerInput) => {
            // End
            if (this._gestureStarted && (ev.type === 'panend' || ev.type === 'touchend')) {
                this.gesturesService.gestureInProgress = false;
                this.onPanEnd(ev);
            } else if (!this._gestureStarted && !this.gesturesService.gestureInProgress) {
                this.gesturesService.gestureInProgress = true;
                this.onPanStart(ev);
                this.onPan(ev);
            } else {
                this.onPan(ev);
            }

            ev.preventDefault();
        });

        this.reset();
        this.resetWheel();

        this.twoFingerEventSubscription = this.gesturesService.streamSliderTwoFingerEvent.subscribe(
            (event: HammerInput) => {
                event.deltaY = -event.deltaX;
                event.velocityY = -event.velocityX;

                if (!this._gestureStarted) {
                    this.onPanStart(event);
                } else {
                    if (event.type === 'panend') {
                        this.onPanEnd(event);
                    } else {
                        this.onPan(event);
                    }
                }
            }
        );
    }

    ngOnDestroy(): void {
        this.twoFingerEventSubscription.unsubscribe();
    }

    /**
     * Getter
     */
    public get contentHeight(): number {
        return this.el.nativeElement.scrollHeight; // parseFloat(getComputedStyle(this.el.nativeElement).getPropertyValue('height'));
    }

    public get parentHeight(): number {
        return parseFloat(getComputedStyle(this.el.nativeElement.parentElement).getPropertyValue('height'));
    }

    public get scrollTop(): number {
        return this.el.nativeElement.parentElement.scrollTop;
    }

    public get minTop(): number {
        return this._minTop;
    }

    public get maxTop(): number {
        return this._maxTop;
    }

    public get hasSnapPositions(): boolean {
        return this.positions && this.positions.length > 1;
    }

    public isInBounds(x_: number, y_: number, rect_: any): boolean {
        let b: boolean;
        if (rect_) {
            b = rect_.left <= x_ && x_ <= rect_.left + rect_.width && rect_.top <= y_ && y_ <= rect_.top + rect_.height;
        }
        return b;
    }

    /**
     * Reset all values
     */
    private reset(): void {
        this._contentHeight = this.contentHeight;
        this._parentHeight = this.parentHeight;
        this._scrollable = this._contentHeight > this._parentHeight;
        this._scrollTopStart = this.scrollTop;
        this._props.y = -this._scrollTopStart;
        this._parentBounds = this.el.nativeElement.parentElement.getBoundingClientRect();
        this._maxTop = 0;
        this._minTop = -this._contentHeight + this._parentHeight;
        this._lastDeltaY = 0;
        this._deltaY = 0;
        this._direction = 0;
        this.calculateSnapPositions();
        this.findGradients();
    }

    /**
     * Calculate scroll container's snap positions,
     * at the moment with every panStart
     */
    private calculateSnapPositions(): void {
        // if (this.positions && this.positions.length > 1 && this.positions[this.positions.length - 1] > 0) {
        //      return;
        // }

        this.positions = [];
        this.lastSnapIndex = 0;
        this.initialOffset = 0;

        const mainOffset: number = Math.round(this._parentBounds.top);
        const scrollOffset: number = this._props.y;
        const depth = 0;

        this.positions = this.positions.concat(
            this.getSnapPositionsOf(this.el.nativeElement, mainOffset, scrollOffset, depth)
        );

        if (this.positions.length > 0) {
            this.positions.push(this._minTop);

            for (let index = 0; index < this.positions.length; index++) {
                this.positions[index] -= mainOffset + scrollOffset;

                if (index === 0) {
                    this.initialOffset = this.positions[index];
                }

                if (this.positions[index] < this._contentHeight - this._parentHeight - this.initialOffset) {
                    this.lastSnapIndex = index + 1;
                }
            }
        }
    }

    /**
     * Recursive parsing of children with styleclass "snapY" for their y-pos,
     * no max-depth for now
     */
    private getSnapPositionsOf(parent: any, mainOffset_: number, scrollOffset_: number, depth_: number): Array<any> {
        let a: Array<any> = [];
        const total: number = parent.childElementCount;
        for (let index = 0; index < total; index++) {
            const child: any = parent.children[index];
            const hasSnapStyleClass: boolean = child.classList.contains('snapY');
            const childPos: number = Math.round(child.getBoundingClientRect().top); // - scrollOffset_; // - mainOffset_);
            if (hasSnapStyleClass) {
                a.push(childPos);
            }
            a = a.concat(this.getSnapPositionsOf(child, childPos + mainOffset_, scrollOffset_, depth_ + 1));
        }
        return a;
    }

    private findGradients(): void {
        if (!this._gradientBottom) {
            const parent: any = this.el.nativeElement.parentElement.parentElement;
            const total: number = parent.childElementCount;
            for (let index = 0; index < total; index++) {
                const child: any = parent.children[index];
                const isGradientTop: boolean = child.id === 'gradient-top' || child.classList.contains('gradient-top');
                if (isGradientTop) {
                    this._gradientTop = child;
                }
                const isGradientBottom: boolean =
                    child.id === 'gradient-bottom' || child.classList.contains('gradient-bottom');
                if (isGradientBottom) {
                    this._gradientBottom = child;
                }
            }
        }
    }

    /**
     * Reset Mousewheel props
     */
    private resetWheel(): void {
        this._props.y = -this.scrollTop || 0;
    }

    /**
     * ...
     */
    private onMouseWheel(e: any): void {
        this.gesturesService.gestureInProgress = true;
        this.reset();
        this.resetWheel();
        if (this._scrollable) {
            const pagePos: number = this.getPagePosByDirection(
                -this.scrollTop,
                e.deltaY > 0 ? -1 : 1,
                this.parentHeight
            );
            this.scrollTo(pagePos, false, 0.3);
        }
    }

    /*
     * On pan start
     */
    private onPanStart(ev: HammerInput): void {
        if (!this._gestureStarted) {
            this.tweenManagerService.kill(this._props);

            this._gestureStarted = true;
            this.reset();
            this.resetWheel();
            this.gesturesService.gestureInProgress = true;
        }
    }

    /*
     * On pan update
     */
    private onPan(ev: HammerInput): void {
        if (this._gestureStarted && this._scrollable) {
            const inBounds: boolean = this.isInBounds(ev.center.x, ev.center.y, this._parentBounds);
            if (ev.deltaY !== this._deltaY) {
                this._lastDeltaY = this._deltaY;
                this._deltaY = ev.deltaY;

                if (inBounds) {
                    this._direction = this._deltaY > this._lastDeltaY ? 1 : -1;
                }
            }

            const absDeltaY: number = Math.abs(this._deltaY);
            const targetTop: number = -this._scrollTopStart + this._deltaY;
            let diffY: number;
            let absDiffY: number;
            let rubberTop: number;
            const maxDeltaY: number = this._parentHeight;
            if (absDeltaY > maxDeltaY) {
                this._deltaY = maxDeltaY * Math.log10(absDeltaY / maxDeltaY);
            }

            if (targetTop > this.maxTop) {
                diffY = -targetTop - maxDeltaY;
                absDiffY = Math.abs(diffY);
                rubberTop = Math.round(maxDeltaY * Math.log10(absDiffY / maxDeltaY));
            } else if (targetTop <= this.minTop) {
                diffY = this.minTop - targetTop + maxDeltaY;
                absDiffY = Math.abs(diffY);
                rubberTop = Math.round(this.minTop - maxDeltaY * Math.max(0, Math.log10(absDiffY / maxDeltaY)));
            } else {
                rubberTop = Math.round(targetTop);
            }

            this._props.y = rubberTop;
            this.redraw();
        }
    }

    /**
     * Redraw
     * Use valid pos for scrollTop, rest for rubberband via translate3d(...)
     */
    private redraw(): void {
        if (this._props) {
            let scrollValue: number = -Math.round(Math.max(this.minTop, Math.min(this.maxTop, this._props.y)));
            let rubberDiff = 0;
            if (this._props.y > this.maxTop) {
                scrollValue = 0;
                rubberDiff = Math.abs(this._props.y - this.maxTop);
            } else if (this._props.y < this.minTop) {
                scrollValue = -this.minTop;
                rubberDiff = -Math.abs(this.minTop - this._props.y);
            }

            this.el.nativeElement.parentElement.scrollTop = scrollValue;
            this.renderer.setElementStyle(
                this.el.nativeElement,
                'WebkitTransform',
                'translate3d(' + 0 + 'px, ' + Math.round(rubberDiff) + 'px, ' + 0 + ')'
            );

            // let currentPosIndex:number = this.getNearestPositionIndex(this._props.y, 0);

            // Toggle gradient if needed
            this.toggleGradient(
                Math.round(this._props.y) < Math.round(this.maxTop),
                Math.round(this._props.y) > Math.round(this.minTop)
            );

            this.onScroll.emit(this._props.y);
        }
    }

    /*
     * On pan end
     */
    private onPanEnd(ev: HammerInput): void {
        if (this._gestureStarted && this._scrollable) {
            this._gestureStarted = false;
            this.snap(ev.velocityY);
        }
        this.gesturesService.gestureInProgress = false;
    }

    /**
     * Calculate snap settings by touch-velocity
     */
    private snap(velocity_: number = 0): void {
        const procDist: number = 100 / Math.max(this._contentHeight, this._parentHeight) * Math.abs(velocity_);
        let targetPos: number = this._props.y + 1.5 * procDist * this._contentHeight * this.direction;
        if (!this.hasSnapPositions || (this._pageSwipe && Math.abs(velocity_) >= 1)) {
            targetPos = this.getPagePosByDirection(-this._scrollTopStart, this.direction, this._parentHeight);
        }

        const nearestPosIndex: number = this.getNearestPositionIndex(targetPos);
        targetPos = this.hasSnapPositions ? -this.positions[nearestPosIndex] + this.initialOffset : targetPos;

        const duration: number = this.getDuration(this._props.y, targetPos);
        this.scrollTo(targetPos, false, duration);
    }

    /**
     * Scroll tweened to snap position
     */
    private scrollTo(top_: number, easeInOut: boolean = false, duration_: number = 0.8): void {
        this.tweenManagerService.kill(this._props);
        const easing = 'Cubic.easeOut';
        const targetY: number = Math.max(this.minTop, Math.min(this.maxTop, top_));
        this.tweenManagerService.to(this._props, duration_, {
            y: targetY,
            ease: easing,
            onUpdate: () => {
                this.redraw();
            },
            onComplete: () => {
                this.onSnapComplete();
            }
        });

        this.onScrollTo.emit(targetY);
    }

    /**
     * On snap complete
     */
    private onSnapComplete(): void {
        this._props.y = Math.round(this._props.y);
        this.el.nativeElement.parentElement.scrollTop =
            -1 * Math.max(this.minTop, Math.min(this.maxTop, this._props.y));
        this.renderer.setElementStyle(
            this.el.nativeElement,
            'WebkitTransform',
            'translate3d(' + 0 + 'px, ' + 0 + 'px, ' + 0 + ')'
        );
        this.gesturesService.gestureInProgress = false;
    }

    /**
     * Calculate snap duration by distance
     */
    private getDuration(start_: number, end_: number): number {
        const distance: number = Math.abs(end_ - start_);
        const procDist: number = distance / this._parentHeight;
        const duration: number = procDist; // * 1 / 2;
        return Math.max(0.3, Math.min(5, duration));
    }

    /**
     * Calculate nearest snap pos index by target pos
     */
    private getNearestPositionIndex(y_: number): number {
        const scrollY: number = Math.round(-y_);
        let n = 1;
        let posPrev: number;
        let posNext: number;
        let posBest: number;
        let indexBest: number = -1;

        if (this.positions && this.positions.length > 1) {
            if (this.scrollTop > 0 && scrollY < this.positions[0] + (this.positions[1] - this.positions[0]) / 2) {
                indexBest = 0;
            } else if (scrollY > this.positions[this.lastSnapIndex]) {
                indexBest = this.lastSnapIndex;
            } else {
                while (indexBest < 0 && n < this.positions.length) {
                    posPrev = this.positions[n - 1];
                    posNext = this.positions[n];
                    if (posPrev <= scrollY && scrollY <= posNext) {
                        posBest = scrollY - posPrev > posNext - scrollY ? posNext : posPrev;
                        indexBest = posBest === posNext ? n : n - 1;
                    }
                    n++;
                }

                if (indexBest < 0) {
                    indexBest = this.direction < 0 ? this.lastSnapIndex : 0;
                }
            }
        }

        return Math.max(0, Math.min(this.lastSnapIndex, indexBest));
    }

    /**
     * Returns target page-scroll-pos
     */
    private getPagePosByDirection(start_: number, direction_: number, span_: number): number {
        let index: number = this.getNearestPositionIndex(start_ + this.initialOffset);
        let bFound = false;
        let n = 0;
        let pagePos: number = start_;

        if (this.positions && this.positions.length > 1) {
            while (!bFound && n < 50 && index >= 0 && index <= this.lastSnapIndex) {
                const nextPos: number = -this.positions[index];
                const dist: number = Math.abs(nextPos - start_) + 2 * this.initialOffset;
                if (dist > span_) {
                    bFound = true;
                    index += direction_; // Set back
                } else {
                    index += -direction_;
                }

                n++;
            }

            index = Math.max(0, Math.min(this.lastSnapIndex, index));
            pagePos = -this.positions[index];
        } else {
            // Fallback for unfinished layouts without snap positions
            pagePos = Math.max(this.minTop, Math.min(this.maxTop, start_ + direction_ * span_));
        }

        // Check threshold
        if (pagePos > -80) {
            pagePos = 0;
        }

        return pagePos;
    }

    /**
     * Returns latest pan direction
     */
    private get direction(): number {
        return this._direction;
    }

    /**
     * Toggle Gradient
     */
    private toggleGradient(bShowTop_: boolean, bShowBottom_: boolean): void {
        if (this._gradientTopVisible !== bShowTop_) {
            this._gradientTopVisible = bShowTop_;
            if (this._gradientTop) {
                this.tweenManagerService.kill(this._gradientTop);
                this.tweenManagerService.to(this._gradientTop, 0.2, {
                    alpha: bShowTop_ ? 1 : 0,
                    ease: 'Cubic.easeOut'
                });
            }
        }
        if (this._gradientBottomVisible !== bShowBottom_) {
            this._gradientBottomVisible = bShowBottom_;
            if (this._gradientBottom) {
                this.tweenManagerService.kill(this._gradientBottom);
                this.tweenManagerService.to(this._gradientBottom, 0.2, {
                    alpha: bShowBottom_ ? 1 : 0,
                    ease: 'Cubic.easeOut'
                });
            }
        }
    }

    private get translateY(): number {
        let mat: any;
        if (!this.el.nativeElement) {
            return 0;
        }

        const style: any = getComputedStyle(this.el.nativeElement);

        if (!style) {
            return 0;
        }

        const transform: any = style.transform || style.webkitTransform || style.mozTransform;
        if (transform) {
            mat = transform.match(/^matrix3d\((.+)\)$/);
            if (mat) {
                return parseFloat(mat[1].split(', ')[13]);
            }
            mat = transform.match(/^matrix\((.+)\)$/);
        }
        return mat ? parseFloat(mat[1].split(', ')[5]) : 0;
    }
}
