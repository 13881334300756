import { ShiftScreenComponent } from './shift-screen.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';


@NgModule({
    imports: [CommonModule],
    exports: [ShiftScreenComponent],
    declarations: [ShiftScreenComponent]
})
export class ShiftScreenModule {}
