import { Component, Input, Output, EventEmitter, HostBinding, ViewChild, OnInit, NgZone} from '@angular/core';
import { ShiftScrollDirective } from '../../gestures/scroll/scroll.directive';

/**
 * The main component stores all the content. Just put your content of a page between the <shift-main> selector.
 * It will automatically add the correct scrollbar and behaviour for your content.
 * The shift-main should be implemented inside a shift-screen
 * 
 * @example
 * <shift-screen>
 *                  <shift-header [title]="'Test'"></shift-header>
 *                  <shift-main>
 *                      <!-- YOUR CONTENT -->
 *                  </shift-main>
 * </shift-screen>
 */
@Component({
    moduleId: module.id,
    selector: 'shift-main, shift-main-setup',
    template: `<div class="outer">
    <div class="shadow-inner-top"></div>
</div>

<div class="infoscreenArea">
    <div class="infoscreen-border1"></div>
    <div class="infoscreen-border2"></div>
</div>

<div class="main" 
    [class.alternative-main]="alternativeMain"
    shiftScroll
    [disableScrolling]="disableScrolling"
    [showCustomScrollbar]="showCustomScrollbar"
    [snapElement]="snapElement"
    [snapOffset]="snapOffset"
    [snapContainer]="'shift-main'"
    [rubberband]="!disableRubberband"
    [direction]="'vertical'"
    (scrollEnd)="scrollEnd.emit()">
    <div class="scroll-wrapper">
        <ng-content></ng-content>        
    </div>
</div>

<div class="outer bottom">
    <div class="shadow-inner-bottom"></div>
</div>
`,
    styles: [`:host{position:relative;overflow-y:hidden;width:100%;height:100%}.main{display:block;height:100%;box-sizing:border-box;flex-grow:10;overflow-y:scroll;overflow-x:hidden}.scroll-wrapper{transition-property:transform}`]
})
export class ShiftMainComponent implements OnInit {

    /**
     * Switch to disable scrolling behaviour of shift-main.
     */
    @Input() public disableScrolling = false;

    /**
     * Switch to show/hide the custom scrollBar.
     */
    @Input() public showCustomScrollbar = true;

    /**
     * Switch to disable rubberband behaviour in shift-main.
     */
    @Input() public disableRubberband = false;
    
    /**
     * Sets the element to which the scrollDirective should snap.
     * To disable snapping set the snapElement to null.
     */
    @Input() public snapElement: string | null = 'shift-item';

    /**
     * Sets the offset of the snapping from the scrollDirective. 
     * Useful to show borders of snapped items for example.
     */
    @Input() public snapOffset: number = -2;

    /**
     * Switch to enable alternative main with gray border
     */
    @Input() public alternativeMain = false;

    /**
     * Reference to the ShiftScrollDirective in this component.
     */
    @ViewChild(ShiftScrollDirective) scrollDirective: ShiftScrollDirective;

    /**
     * Stores information if next to a main exist a footer.
     */
    public hasFooter: boolean;

    /**
     * Sets the class 'hasFooter' to shift-main component.
     */
    @HostBinding('class.hasFooter') get footer(): boolean {
        return this.hasFooter;
    }

    @HostBinding('class.disable-scrolling') get scrolling(): boolean {
        return this.disableScrolling  || !this.showCustomScrollbar;
    }

    /**
     * Output emits when event onScrollEnd by the shiftScroll directive is fired.
     */
    @Output() public scrollEnd: EventEmitter<any> = new EventEmitter();

    /**
     * Constructor for the main component
     */
    constructor() { }

    /**
     * Default implementation of OnInit hook
     */
    ngOnInit() { }

    /**
     * Method to refresh the scroll-wrapper
     */
    public refreshScroll() {
        if(this.scrollDirective && this.scrollDirective.myScroll) {
            this.scrollDirective.myScroll.refresh();
        }
    }

}
