import { ModuleWithProviders, NgModule } from '@angular/core';
//Components
import { ShiftScreenModule } from './ui/shift-screen/shift-screen.module';
import { ShiftMainModule } from './ui/main/main.module';
import { ShiftAvatarModule } from './ui/avatar/avatar.module';
import { ShiftRangeModule } from './ui/range/range.module';
import { ShiftButtonModule } from './ui/button/button.module';
import { ShiftDropdownModule } from './ui/dropdown/dropdown.module';
import { ShiftListModule } from './ui/list/list.module';
import { OverlayModule } from './ui/overlay/overlay.module';
import { ShiftPwdStrengthIndicatorModule } from './ui/pwd-strength-indicator/pwd.strength-indicator.module';
import { ShiftSwitchModule } from './ui/switch/switch.module';
import { ShiftVirtualScrollListModule } from './ui/virtual-scroll-list/virtual-scroll-list.module';
import { ShiftPageLoadModule } from './ui/page-load/page-load.module';
import { ShiftDatePickerModule } from './ui/date-picker/date-picker.module';
import { ShiftHeaderModule } from './ui/header/header.module';
import { ShiftLegalOverviewModule } from './ui/legal-overview/legal-overview.module';
import { ShiftLegalEntryModule } from './ui/legal-entry/legal-entry.module';
import { ShiftFooterModule } from './ui/footer/footer.module';
import { ShiftPopupModule } from './ui/popup/popup.module';
import { ASSET_COMPONENT_DECLARATIONS } from './ui/asset-components/index';
import { ShiftPopup2Module } from './ui/popup2/component/popup2.module';
import { Popup2DispatcherService } from './ui/popup2/service/popup2-dispatcher.service';

//Gestures
import { ShiftGesturesModule } from './gestures/gestures.module';

//Services
import { UIService } from './services/ui.service';

@NgModule({
  declarations: [],
  imports: [
    ...ASSET_COMPONENT_DECLARATIONS,
    ShiftMainModule,
    ShiftAvatarModule,
    ShiftButtonModule,
    ShiftRangeModule,
    ShiftDropdownModule,
    ShiftListModule,
    OverlayModule,
    ShiftPwdStrengthIndicatorModule,
    ShiftSwitchModule,
    ShiftVirtualScrollListModule,
    ShiftGesturesModule,
    ShiftPageLoadModule,
    ShiftDatePickerModule,
    ShiftHeaderModule,
    ShiftFooterModule,
    ShiftLegalEntryModule,
    ShiftLegalOverviewModule,
    ShiftPopupModule,
    ShiftPopup2Module,
    ShiftScreenModule
  ],
  exports: [
    ...ASSET_COMPONENT_DECLARATIONS,
    ShiftScreenModule,
    ShiftMainModule,
    ShiftAvatarModule,
    ShiftButtonModule,
    ShiftRangeModule,
    ShiftDropdownModule,
    ShiftListModule,
    OverlayModule,
    ShiftPwdStrengthIndicatorModule,
    ShiftSwitchModule,
    ShiftVirtualScrollListModule,
    ShiftGesturesModule,
    ShiftPageLoadModule,
    ShiftDatePickerModule,
    ShiftHeaderModule,
    ShiftFooterModule,
    ShiftLegalEntryModule,
    ShiftLegalOverviewModule,
    ShiftPopupModule,
    ShiftPopup2Module
  ],
  providers: [
    UIService,
    Popup2DispatcherService
  ]
})
export class CqShiftUIComponentsModule {}
