import { Injectable, Inject } from '@angular/core';
import { IEndpointConfiguration, RSI } from './rsi';
import { RSIFactory } from './rsi.factory';
import { RSIEndpoint } from './rsi.endpoint';


@Injectable()
export class RSIService {
  public rsi: RSI;

  VIWI_PORT = 3000;
  VIWI_HOST = 'localhost';
  VIWI_API_PREFIX = '';


  constructor(private rsiFactory: RSIFactory, @Inject('RSI_CONFIG') private RSI_CONFIG: any) {
    if (this.RSI_CONFIG && this.RSI_CONFIG.VIWI_PORT) {
      this.VIWI_PORT = this.RSI_CONFIG.VIWI_PORT;
    }

    if (this.RSI_CONFIG && this.RSI_CONFIG.VIWI_HOST) {
      this.VIWI_HOST = this.RSI_CONFIG.VIWI_HOST;
    }

    if (this.RSI_CONFIG && this.RSI_CONFIG.VIWI_API_PREFIX) {
      this.VIWI_API_PREFIX = this.RSI_CONFIG.VIWI_API_PREFIX;
    }
    this.init();
  }

  init() {
    this.rsi = this.rsiFactory.get('viwi', {
      server: {
        port: this.VIWI_PORT,
        address: this.VIWI_HOST,
        protocol: 'http',
        socketprotocol: 'ws',
        api: this.VIWI_API_PREFIX
      }
    });
  }

  public endpoint(target: string, endpointConfig?: IEndpointConfiguration | boolean): RSIEndpoint<any> {
    return this.rsi.endpoint(target, endpointConfig);
  }

  public firstEndpoint(target: string, endpointConfig?: IEndpointConfiguration): RSIEndpoint<any> {
    return this.rsi.firstEndpoint(target, endpointConfig);
  }
}
