import { Component, HostBinding, HostListener, Input, ElementRef, OnInit } from '@angular/core';

/**
 * A button containing text and an optional icon. Or provide your content via ng-content.
 * Try to avoid setting text/icon AND provide content via ng-content. Decide for one of them.
 *
 * It can be used to submit a form or as a normal button (listen to click-event).
 * When "type" is set to 'submit" the button submits the surrounding form when it is clicked.
 * If you want to submit a different form then pass that form as input variable "submitForm".
 * 
 * @example
 * <shift-screen>
 *                  <shift-main>
 *                      <shift-button label="'Button-Text'"></shift-button>
 *                      <shift-button>
 *                          <span>Button-Text</span>
 *                      </shift-button>
 *                      <!-- MORE OF YOUR CONTENT -->
 *                  </shift-main>
 * </shift-screen>
 */
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'shift-button, shift-button-icon, shift-button-toggle, shift-button-expandable, shift-button-radio, shift-button-checkbox',
    template: `<button [disabled]="disabled">
    <div class="icon" *ngIf="isSelectableButton"></div>
    <ng-content></ng-content>
    <img src="{{icon}}" *ngIf="icon">
    <span *ngIf="label">{{label}}</span>
</button>
<ng-content select="[expandableContent]"></ng-content>
`,
    styles: [``]
})
export class ShiftButton implements OnInit {
    /**
     * Sets the label of the text-button.
     */
    @Input() public label: string;

    /**
     * Sets the icon-source for displaying an icon as image.
     */
    @Input() public icon: string; 

    /**
     * Set the type of the button. Type: string (default: 'normal')
     */
    @Input() type: 'normal' | 'submit' = 'normal';

    /**
     * Pass your form as input if you want to submit a different/your form.
     */
    @Input() submitForm;

    /**
     * Sets the disable state of a button.
     */
    @Input()
    disabled = false;

    /**
     * Sets class disabled to shift-button
     */
    @HostBinding('class.disabled') get disable() {
        return this.disabled;
    }

    /**
     * Sets the selected state of a button.
     * A class 'selected' will be applied to the button if setting the input to true.
     */
    @HostBinding('class.selected')
    @Input()
    selected = false;


    /**
     * HostListener executes on a 'click' a function where the form will be submitted. 
     * But only if the '@Input type' is set to 'submit'.
     */
    @HostListener('click', ['$event'])
    onClick(event: Event) {
        if(this.buttonType === 'radio') {
            this.selected = true;
        } else {
            this.selected = !this.selected;
        }
        event.preventDefault();
        if (this.type === 'submit') {
            let form;
            if (this.submitForm) {
                form = this.submitForm;
            } else {
                form = this._elementRef.nativeElement.parentElement;
                while (form !== null && form.nodeName.toLowerCase() !== 'form') {
                    form = form.parentElement;
                }
                if (form === null) {
                    return;
                }
            }
            form.dispatchEvent(new Event('submit'));
            event.stopPropagation();
        }
    }

    public buttonType: 'radio' | 'checkbox' | 'other' = 'other';
    public isSelectableButton = false;

    /**
     * Shift-Button constructor
     * @param _elementRef will be provided to access the parentElement when submitting a form
     */
    constructor(private _elementRef: ElementRef) {
    }

    ngOnInit() {
        const native: Element = this._elementRef.nativeElement;
        if(native.tagName === 'SHIFT-BUTTON-RADIO') {
            this.buttonType = 'radio';
            this.isSelectableButton = true;
        }
        if(native.tagName === 'SHIFT-BUTTON-CHECKBOX') {
            this.buttonType = 'checkbox';
            this.isSelectableButton = true;
        }
    }
}
