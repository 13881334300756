import { Component, Input } from '@angular/core';

/**
 * PwdStrengthIndicator component shows the password strength in a graphical way.
 * Use the corresponding pwd-strength-indicator-container!
 */
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'shift-pwd-strength-indicator',
    template: `<div class="pwd-bar">
    <div class="pwd-strength-bar" [ngClass]="pwdStrengthClasses"></div>
</div>
    
<span class="pwd-strength-text" [ngClass]="pwdStrengthClasses">{{pwdStrengthText}}</span>`,
    styles: [`:host{position:relative}`],
    host: {
        '[class.rtl]': 'isRtl'
    }
})

export class ShiftPwdStrengthIndicatorComponent {

    /**
     * An array of five strings used to indicate the password strength.
     */
    @Input() strengthTexts: string[];

    /**
     * Switch to show content from right to left.
     */
    @Input() isRtl: boolean;
    /**
     * The actual password strength text that will be displayed.
     */
    pwdStrengthText: string;

    /**
     * Stores classes with key-value-pair.
     * key: class name
     * value: boolean if class name should be applied. 
     */
    pwdStrengthClasses: {};

    /**
     * Constructor for pwd strength indicator component
     */
    constructor() {}

    /**
     * Setter for setting the strength text and applying the correct strength class to have a colorized style.
     */
    set strength(newStrength: number) {
        let strengthTexts = this.strengthTexts;
        if (!strengthTexts) {
            strengthTexts = ['Very bad', 'bad', 'ok', 'good', 'very good'];
        }
        this.pwdStrengthText = strengthTexts[newStrength];
        this.pwdStrengthClasses = {
            strength0: newStrength === 0,
            strength1: newStrength === 1,
            strength2: newStrength === 2,
            strength3: newStrength === 3,
            strength4: newStrength === 4
        };
    }
}
