import { Injectable } from '@angular/core';
import { RSIService } from '@shift/rsi';
import { BehaviorSubject } from 'rxjs';
import { hmiintegration } from '../../..';

@Injectable()
export class AmbientLightService {
    public ambientLightColor = new BehaviorSubject<string>('#1e64ff');

    constructor(private rsi: RSIService) {
    }

    public init() {
        this.subscribeForData();
    }

    private subscribeForData(): void {
        this.rsi
            .firstEndpoint('/hmiintegration/ambientLight/', {autoGetData: false})
            .subscribe((data: hmiintegration.IAmbientLightObject) => {
                if (data.hmi) {
                    this.ambientLightColor.next(data.hmiColor);
                }
            });
    }
}
