import { Injectable } from '@angular/core';
import { remote } from 'electron';
import { readFileSync, writeFile } from 'fs';

@Injectable()
export class PersistenceService {
    public _settingsFileExists = true;

    private EMPTY_SETTINGS_CONTAINER: any = { version: 2, data: {} };
    private SETTINGS_VERSION = 2;

    private _useLocalStorage = false;
    private _userFolder: string;
    private _filename: string;
    private _sharedObjectName: string;

    private _settingsData: any;

    constructor() {
        this._init();
        this.read();
    }

    /*
     * Ãffentliche Methode um Settings permanent unter einem Key zu speichern.
     *
     * Error-Codes
     *
     *  0 - o.k.
     * -1 - _settingsData is not existing
     * -2 - file writer throws error
     */
    public save(key_: string, value_: any): number {
        if (this._settingsData) {
            // console.log('saving settings for key: ' + key_);
            this._settingsData.data[key_] = value_;
            // console.log(this._settingsData.data[key_]);
            // console.log(this._settingsData);
            return this.write();
        }
        return -1;
    }

    // Ãffentliche Methode um Settings eines bestimmten Keys abzurufen.
    public load(key_: string): any {
        if (this._settingsData) {
            return this._settingsData.data.hasOwnProperty(key_) ? this._settingsData.data[key_] : null;
        }
        return null;
    }

    // Ãffentliche Methode um Settings eines bestimmten Key vollstÃ¤ndig zu entfernen.
    public delete(key_: string): void {
        if (this._settingsData && this._settingsData.data && this._settingsData.data.hasOwnProperty(key_)) {
            delete this._settingsData.data[key_];
            this.write();
        }
    }

    /**
     * Is it Electron?
     */
    public get isElectron(): boolean {
        const w: any = window;

        if (w.hasOwnProperty('process') && w.process !== undefined) {
            if (w.process.hasOwnProperty('versions') && w.process.versions !== undefined) {
                if (w.process.versions.hasOwnProperty('electron') && w.process.versions.electron !== undefined) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Initialize params
     */
    private _init(): void {
        this._sharedObjectName = 'shift_user_settings';

        if (this.isElectron) {
            let userFolder = null;
            try {
                userFolder = remote.app.getPath('userData');
            } catch (e) {}

            if (userFolder === null) {
                console.log('user folder unavailable - falling back to local storage.');
                this._useLocalStorage = true;
            } else {
                console.log('user folder available - using file storage.');
                this._userFolder = userFolder + '/';
                this._userFolder = this._userFolder.replace(/\\/g, '/'); // Replace path`s '\' width '/'
                this._filename = 'shift_user_settings.json';
            }
        } else {
            this._useLocalStorage = true;
        }
    }

    private deserialize(input_: string): any {
        return JSON.parse(input_);
    }

    private set settingsData(input_: string) {
        // check here for settings conversion...
        this._settingsData = this.deserialize(input_);
        if (this._settingsData.hasOwnProperty('version') && this._settingsData.version === this.SETTINGS_VERSION) {
            // all good, new version settings exist
            // console.log('settings have the correct version');
            // console.log(this._settingsData);
        } else {
            console.log('Settings need to be converted to current format.');
            console.log(this._settingsData);
            const obj: any = this._settingsData;
            const newSettingsContainer: any = this.EMPTY_SETTINGS_CONTAINER;
            const data: any = {};

            if (obj.hasOwnProperty('offsetX') && obj.hasOwnProperty('offsetY')) {
                data.display = {
                    offsetX: obj.offsetX,
                    offsetY: obj.offsetY,
                    pxPerCm: obj.pxPerCm,
                    fullscreen: obj.fullscreen
                };
                data.connection = { klima: obj.klima, leap: obj.leap, solid: obj.solid };
                newSettingsContainer.data = data;
            }

            console.log('Settings have been converted to the current format.');
            this._settingsData = newSettingsContainer;
            this.write();
            this.settingsData = JSON.stringify(newSettingsContainer);
        }
    }

    /**
     * Read storage
     */
    private read(): void {
        // console.log('reading storage...');
        // Electron: Read from existing file, or create new file if needed
        if (!this._useLocalStorage) {
            const filePath: string = this._userFolder + this._filename;
            try {
                this.settingsData = readFileSync(filePath, { encoding: 'utf-8' });
            } catch (e) {
                // Settings file did not exist, creating new one.
                this.settingsData = JSON.stringify(this.EMPTY_SETTINGS_CONTAINER);
                this._settingsFileExists = false;
                console.log(e);
            }
        } else {
            // OR, Browser: Read from existing SharedObject, or create new SharedObject if needed
            // Check, if SharedObject exists ...
            const s1: string = window.localStorage.getItem(this._sharedObjectName);

            // SharedObject exists
            if (s1 !== null) {
                this.settingsData = s1;
            } else {
                // SharedObject does not exist. Creating new one.
                this.settingsData = JSON.stringify(this.EMPTY_SETTINGS_CONTAINER);
                this._settingsFileExists = false;
            }
        }
    }

    /**
     * Write storage
     */
    private write(): number {
        /*
         * Error-Codes
         *
         *  0 - o.k.
         * -1 - _settingsData is not existing
         * -2 - file writer throws error
         */
        let ERROR_CODE = 0;

        if (!this._settingsData) {
            return -1;
        }

        const s: string = JSON.stringify(this._settingsData);

        // Electron:
        // Write to existing file, creates new file automatically if needed
        if (!this._useLocalStorage) {
            const filePath: string = this._userFolder + this._filename;
            writeFile(filePath, s, (err: any) => {
                if (err) {
                    ERROR_CODE = -2;
                    throw err;
                }
            });
        } else {
            // OR, Browser:
            // Write to existing SharedObject, creates new SharedObject automatically if needed
            window.localStorage.setItem(this._sharedObjectName, s);
        }

        return ERROR_CODE;
    }
}
