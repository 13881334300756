import { Injectable } from '@angular/core';
import { RSIService } from '@shift/rsi';
import { hmiintegration } from '../../interfaces/hmiintegration';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NearingStateService {
    public isNearing = new BehaviorSubject<boolean>(false);

    constructor(private rsi: RSIService) {
    }

    public init(): void {
        this.subscribeForData();
    }

    private subscribeForData(): void {
        this.rsi.firstEndpoint('/hmiintegration/proximity', {'autoGetData': false}).subscribe(
            (data: hmiintegration.IProximityObject) => {
                this.onNearingChanged(data.name === 'active'); // name = active or inactive
            },
            (e: Error) => {
                console.log(e);
                // this.showInfo('onlinestateError');
            }
        );
    }

    private onNearingChanged(nearing: boolean): void {
        this.isNearing.next(nearing);
    }
}
