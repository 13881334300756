import { Popup2Model } from '../popup2.model';
import { Popup2AbstractStateModel } from './popup2-abstract-state.model';

/**
 * An abstract class which represents a context for the popup state machine. It holds the current popup state.
 */
export abstract class Popup2AbstractStateContextModel {
  /** The current popup state represented by an instance of Popup2AbstractStateModel.
   *  The state reacts on changes or interactions by its abstract interface. */
  protected currentPopupState: Popup2AbstractStateModel;
  /** The currently shown popup */
  public abstract currentlyShownPopup: Popup2Model;

  public abstract getPopup2BeShownNext(): Popup2Model;
  public abstract mustSwitch2NextPopup(): boolean;
  public abstract showPopup(popup2BeShown: Popup2Model);
  public abstract closePopup();
  public abstract resetPopupState2Closed();

  /**
   * Swicth popup state to a new state
   * @param newPopupState the new popup state
   */
  public switch2State(newPopupState: Popup2AbstractStateModel) {
    this.currentPopupState = newPopupState;
    this.delayedOnEnter();
  }

  /**
   * Call onEnter() of the current popup state.
   * Done with timeout '0' to ensure it is done in a new stack of the js event queue.
   * Otherwise there are side effects while switching the state.
   */
  private delayedOnEnter() {
    setTimeout(() => {
      this.currentPopupState.onEnter();
    }, 0);
  }
}
