import { Injectable } from '@angular/core';

/**
 * This service gives you information about the current window/screen/device
 */
@Injectable()
export class WindowService {
    public windowWidth: number;
    public windowHeight: number;

    private mobileWidth = 680; // Max value for beeing an mobile device (main.scss)
    private mobileHeight = 480; // Max value for beeing an mobile device (main.scss)

    constructor() {
        this.windowWidth = window.screen.width;
        this.windowHeight = window.screen.height;
    }

    public get isLandscape(): boolean {
        return this.windowWidth > this.windowHeight;
    }

    public get isPortrait(): boolean {
        return this.windowHeight > this.windowWidth;
    }

    public get isMobileDevice(): boolean {
        if (this.isLandscape) {
            return this.windowHeight <= this.mobileHeight && this.windowWidth <= this.mobileWidth;
        } else {
            return this.windowHeight <= this.mobileWidth && this.windowWidth <= this.mobileHeight;
        }
    }
}
