import { NgModule, ModuleWithProviders } from '@angular/core';
import { RubberbandVerticalDirective } from './rubberband-vertical.directive';
import { RubberbandHorizontalDirective } from './rubberband-horizontal.directive';
import { CommonModule } from '@angular/common';
import { TweenManagerService } from '../animation/tween-manager.service';

@NgModule({
    imports: [CommonModule],
    exports: [RubberbandHorizontalDirective, RubberbandVerticalDirective],
    declarations: [RubberbandHorizontalDirective, RubberbandVerticalDirective]
})
export class RubberbandGestureModule {
    /** @deprecated */
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: RubberbandGestureModule,
            providers: [TweenManagerService]
        };
    }
}
