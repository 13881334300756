/**
 * Global UI Services where you can several different global states to which the UI will react
 */
export class UIService {

  /**
   * Private property where the current ambient color is saved
   */
  private _ambientColor = '';

  /**
   * Private property where the current theme is saved
   */
  private _theme = '';

  /**
   * Private property where the nearing state is saved
   */
  private _nearing = false;

  /**
   * Private property where the state of displaying the native status bar is saved
   */
  private _nativeStatusBar = false;

  /**
   * Sets the property _ambientColor when provided correct hexValue in form of '0xFFFFFF' or '#FFFFFF' for example
   * Also sets css properties for RGB color for the ambient light corresponding to the provided hex-string
   */
  public set ambientColor(hexValue: string) {
    if(hexValue.indexOf('#') === 0) {
      hexValue = hexValue.slice(1);
    }
    if(hexValue.indexOf('0x') === 0 || hexValue.indexOf('0X') === 0) {
      hexValue = hexValue.slice(2);
    }
    if(hexValue !== this._ambientColor && hexValue.length === 6) {
      const html: any = document.querySelector('html');
      const red = parseInt(hexValue[0] + hexValue[1], 16);
      const green = parseInt(hexValue[2] + hexValue[3], 16);
      const blue = parseInt(hexValue[4] + hexValue[5], 16);
      html.style.setProperty('--ambient-red', red);
      html.style.setProperty('--ambient-green', green);
      html.style.setProperty('--ambient-blue', blue);
      this._ambientColor = hexValue;
    } 
  }

  /**
   * Return the current set ambient color in hex
   */
  public get ambientColor() {
    return '#' + this._ambientColor;
  }

  /**
   * Sets the theme by adding the provided string as class on the html tag
   */
  public set theme(value: string) {
    if (value !== this._theme) {
      const html: Element = document.querySelector('html');
      if (this._theme !== '' && html.classList.contains(this._theme)) {
        html.classList.remove(this._theme);
      }
      if (value !== '') {
        html.classList.add(value);
      }
      this._theme = value;
    }
  }

  /**
   * Returns the current set theme
   */
  public get theme() {
    return this._theme;
  }

  /**
   * Sets the nearing state by setting the class 'nearing' on the html tag or removing it if the state is not nearing
   */
  public set nearing(value: boolean) {
    if(value !== this._nearing) {
      const html: Element = document.querySelector('html');
      if (value) {
        html.classList.add('nearing');
      } else {
        html.classList.remove('nearing');
      }
      this._nearing = value;
    }
  }

  /**
   * Returns the current nearing state
   */
  public get nearing() {
    return this._nearing;
  }

  /**
   * Sets the native status bar state by setting the class 'native-bar' on the html tag or removing it if the native status bar is not visible
   */
  public set nativeStatusBar(value: boolean) {
    if(value !== this._nativeStatusBar) {
      const html: Element = document.querySelector('html');
      if (value) {
        html.classList.add('native-bar');
      } else {
        html.classList.remove('native-bar');
      }
      this._nativeStatusBar = value;
    }
  }

  /**
   * Returns the current native status bar state
   */
  public get nativeStatusBar() {
    return this._nativeStatusBar;
  }
}
