import { ShiftHeaderComponent } from './header.component';

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { OnlineStatusComponent } from './online-status/online-status.component';
import { ShiftSpinnerModule } from '../asset-components/shift-spinner/shift-spinner.module';

@NgModule({
    imports: [CommonModule, ShiftSpinnerModule],
    exports: [ShiftHeaderComponent, OnlineStatusComponent],
    declarations: [ShiftHeaderComponent, OnlineStatusComponent],
    providers: []
})
export class ShiftHeaderModule {
    /** @deprecated */
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ShiftHeaderModule,
            providers: []
        };
    }
}
