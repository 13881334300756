import { AfterViewInit, Directive, ElementRef, EventEmitter, Output } from '@angular/core';
import { GesturesService } from '../gestures.service';
import { GesturesThresholds } from '../gestures-thresholds';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[panUpGesture]'
})
export class PanUpGestureDirective implements AfterViewInit {
    @Output() onPanUp: EventEmitter<{}> = new EventEmitter();

    public emitGesture = true;
    public eventId = 'panEvent';

    constructor(public el: ElementRef, private gesturesService: GesturesService) {}

    ngAfterViewInit(): void {
        const hammertime: HammerManager = new Hammer(this.el.nativeElement);
        hammertime.get('pan').set({
            threshold: GesturesThresholds.DEFAULT_PAN_UP_THRESHOLD,
            direction: Hammer.DIRECTION_UP
        });

        this.gesturesService.pan(hammertime, this.onPanUp, this);
        this.gesturesService.stopForIncomingEvents(hammertime, this);
    }
}
