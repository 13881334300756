import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { RSIService } from '@shift/rsi';
import { webappmanagement } from '../../interfaces/webappmanagement';
import { debounceTime } from 'rxjs/operators';

@Injectable()
export class StorageSpaceService {
    public controllerObject: BehaviorSubject<webappmanagement.IControllerObject>

    public warnings: BehaviorSubject<webappmanagement.IControllerObject>
    private defaultThreshold = 1e+7;

    constructor(private rsi: RSIService) {
        this.controllerObject = new BehaviorSubject({});
        this.warnings = new BehaviorSubject({});
    }

    /**
     * init service with threshold
     * @param threshold
     */
    public init(threshold?: number): void {
        threshold = threshold || this.defaultThreshold;
        this.subscribeForData(threshold);
    }

    /**
     * update controllerObject when freespace is under given threshold
     * @param threshold
     */
    private subscribeForData(threshold: number): void {
        const endpoint = this.rsi.firstEndpoint('/webappmanagement/controllers', {'autoGetData': false});
        endpoint.pipe(debounceTime(5000)).subscribe(
            (data: webappmanagement.IControllerObject ) => {
                this.controllerObject.next(data);
                if (data.freeDiskSpace <= threshold ) {
                    this.warnings.next(data);
                }
            },
            (e: Error) => {
                console.log(e);
            }
        );
    }
}

