import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Popup2Model } from '../model/popup2.model';
import { Popup2DispatcherService } from '../service/popup2-dispatcher.service';
import { Popup2AbstractStateContextModel } from '../model/popup2states/popup2-abstract-state-context.model';
import { Subscription } from 'rxjs';
import { Popup2StateClosedModel } from '../model/popup2states/popup2-state-closed.model';
import { Popup2SkippableModel } from '../model/popup2skippable.model';

/**
 * Popup component that show up from the top over the header.
 * Place the shift-popup2 globally into your app, e.g. into app.component.html after the router-outlet.
 * @example
 *
 * <router-outlet></router-outlet>
 * <shift-popup2></shift-popup2>
 *
 * Use Popu2Model and Popup2DispatcherService to create popups and enqueue them to be shown.
 * See popup2.model.ts and popup2dispatcher.service.ts for further documentation.
 */
@Component({
  selector: 'shift-popup2',
  template: `<div [ngClass]="{'screenblocker': open}"></div>
<div class="popup-container" [ngClass]="{'open': open}" >
  <div class="popup-wrapper" [ngClass]="{'fullWidth': currentlyShownPopup.fullWidth}">
    <div class="popup content" [ngClass]="{'customOverlay': currentlyShownPopup.numberOfButtons > 0}">
      <div class="customContent" [ngClass]="{'blocking': currentlyShownPopup.fullWidth, 'fixed-height': currentlyShownPopup.fixedHeight}">

        <div *ngIf="currentlyShownPopup.withImage" class="text-img-wrapper">
          <div class="img-area">
            <img [src]="currentlyShownPopup.imageSourcePath" />
          </div>
          <div class="text-area" [innerHTML]='currentlyShownPopup.textLines[0]'></div>
        </div>

        <div *ngIf="currentlyShownPopup.showLoadingIndicator">
          <div class="spinner-and-text-wrapper">
            <div class="spinner-container">
              <div class='loading-anime-mask'></div>
            </div>
            <div class="text-area" [innerHTML]='currentlyShownPopup.textLines[0]'></div>
          </div>
        </div>

        <div *ngIf="!currentlyShownPopup.withImage && !currentlyShownPopup.showLoadingIndicator">
          <div class="text-area">
            <p *ngFor='let atext of currentlyShownPopup.textLines'>{{ atext }}</p>
          </div>
        </div>

        <div *ngIf="currentlyShownPopup && currentlyShownPopup.numberOfButtons > 0" class="overlay-footer">
          <button *ngIf="currentlyShownPopup.numberOfButtons >= 3" type='button' (click)="buttonClicked($event, 2)">{{ currentlyShownPopup.buttonTexts[2] }}</button>
          <button *ngIf="currentlyShownPopup.numberOfButtons >= 2" type='button' (click)="buttonClicked($event, 1)">{{ currentlyShownPopup.buttonTexts[1] }}</button>
          <button *ngIf="currentlyShownPopup.numberOfButtons >= 1" type='button' (click)="buttonClicked($event, 0)">{{ currentlyShownPopup.buttonTexts[0] }}</button>
        </div>
      </div>
    </div>
  </div>

</div>
`,
  styles: [`:host{position:fixed}`]
})
export class ShiftPopup2Component extends Popup2AbstractStateContextModel implements OnInit, OnDestroy {

  /**
     * Switch to open and close the Popup. The class 'open' will be applied to the component.
     */
  @HostBinding('class.open')
  public open: boolean;

  /** Subscription for new popup available */
  private popupSubscription: Subscription;
  /** Subscription for update of popup */
  private popupUpdateSubscription: Subscription;

  /** A popup object with dummy values */
  private emptyPopup: Popup2Model;
  /** The popup which is shown when 'open' is 'true' */
  public currentlyShownPopup: Popup2Model;

  constructor(private popup2Dispatcher: Popup2DispatcherService) {
    super();
  }

  /**
     * Reset Current Popup and Popup state to valid values. Subscribe on events from app (new popup, close popup)
     */
  ngOnInit() {
    this.initWithEmptyPopup();
    this.resetPopupState2Closed();
    this.wait4NewPopup2BeShown();
  }

  /**
   * Cleanup subscriptions.
   */
  ngOnDestroy() {
    this.popupSubscription.unsubscribe();
    this.popupUpdateSubscription.unsubscribe();
  }

  /**
   * Initialize current popup with dummy values to prevent undefined references.
   */
  private initWithEmptyPopup() {
    this.emptyPopup = Popup2Model.create4OneButton('', '');
    this.currentlyShownPopup = this.emptyPopup;
  }

  /**
   * Switch state of current popup to closed.
   */
  public resetPopupState2Closed() {
    const popupStateClosed = new Popup2StateClosedModel(this);
    this.switch2State(popupStateClosed);
  }

  /**
   * Subscribe on events from popup dispatcher to get notified when
   *  - new popups are available
   *  - a shown popup has been updated (e.g. shall be closed)
   */
  private wait4NewPopup2BeShown() {
    this.popupSubscription = this.popup2Dispatcher.newPopupAvailable.subscribe(() => {
      this.currentPopupState.newPopupAvailable();
    });
    this.popupUpdateSubscription = this.popup2Dispatcher.onPopupUpdate.subscribe((updatedPopup: Popup2SkippableModel) => {
      if (updatedPopup.skip && updatedPopup.popup === this.currentlyShownPopup) {
        this.currentPopupState.closePopup();
      }
    });
  }

  /**
   * Check if a new popup is available and must be shown.
   * @returns 'true' if the new popup has higher priority than the currently shown or if none is currently shown.
   */
  public mustSwitch2NextPopup(): boolean {
    let mustSwitch = false;

    if (this.open) {
      const nextPopup = this.getPopup2BeShownNext();
      mustSwitch = nextPopup.priority < this.currentlyShownPopup.priority;
    } else {
      mustSwitch = true;
    }

    return mustSwitch;
  }

  /**
   * @returns the popup to be shown next (highest priority, first in queue)
   */
  public getPopup2BeShownNext(): Popup2Model {
    let nextPopup: Popup2Model;
    const nextPopupFromDispatcher = this.popup2Dispatcher.getPopup2BeShownNext();

    if (nextPopupFromDispatcher) {
      nextPopup = nextPopupFromDispatcher.popup;
    }

    return nextPopup;
  }

  /**
   * Show the popup.
   * @param popup2BeShown the popup to be shown
   */
  public showPopup(popup2BeShown: Popup2Model) {
    this.currentlyShownPopup = popup2BeShown;
    this.open = true;
  }

  /**
   * Close the currently shown popup. Remove it from queue. Emit event 'closedEvent' from currentlyShownPopup.
   */
  public closePopup() {
    this.open = false;

    if (this.currentlyShownPopup && this.currentlyShownPopup !== this.emptyPopup) {
      this.popup2Dispatcher.dequeuePopupAfterClosed(this.currentlyShownPopup);
      this.currentlyShownPopup.closedEvent.emit();
    }
  }

  /**
   * Handler for popup button clicked.
   * Forwards the event to currentlyShownPopup.buttonClickedEvents which can be handled by the app.
   * Handle the event in current state for possible switch to next state.
   * @param event the event object
   * @param buttonIndex the index of the clicked button starting with 0
   */
  public buttonClicked(event: Event, buttonIndex: number): void {
    if (event) {
        event.stopPropagation();
    }

    this.currentlyShownPopup.buttonClickedEvents[buttonIndex].emit();
    this.currentPopupState.buttonPressed();
  }
}
