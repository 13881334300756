import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { BehaviorSubject } from 'rxjs';

// shift
import { MibLogService } from '@shift/core';
import { servicemanagement } from '../../interfaces/servicemanagement';
import { RSIEndpoint, RSIService } from '@shift/rsi';

@Injectable()
export class TokenService {

    private endpoint: RSIEndpoint<any>;
    private subscribed: boolean;
    private _tokensObject: any = {};

    public accessTokens: BehaviorSubject<any> = new BehaviorSubject({});

    constructor(
        private http: Http,
        private logger: MibLogService,
        private rsi: RSIService
    ) { }

    /**
     * Subscribes to app's service in rsi servicemanagement
     * Collects all accesstokens of service's operations
     * @param serviceID {string} - app's unique service id
     * @param devTokens {any} - If devTokens are set service returns them as dummy data
     */
    public init(serviceID: string, devTokens?: any): Promise<void> {
        return new Promise((resolve, reject) => {
            if( devTokens ) {
                this.accessTokens.next(devTokens);
                resolve();
            } else {
                this.rsi.firstEndpoint(
                    '/servicemanagement/services?name=carapp_incarenrollment',
                    {'autoSubscribe': false}
                ).subscribe(
                    (service: any) => {
                        this.subscribed = true;
                        const promises: Promise<void>[] = [];

                        for( const operation of service.operations ) {
                            promises.push(this.listenToTokenChanges(operation));
                        }

                        Promise.all(promises).then(() => {
                            console.log('%c Operation AccessTokens: ', 'color:#fff; background:#333', this.accessTokens.getValue());
                            resolve();
                        }).catch((e: Error) => console.error(e));

                    }
                )
            }
        });
    }

    /**
     * Subscribe to a servicemanagement operation and listen for token changes
     * @param operation {IOperationObject}
     */
    private listenToTokenChanges(operation: any): Promise<void> {
        return new Promise((resolve, reject) => {
            this.rsi.endpoint(operation.uri, {'autoSubscribe': false}).subscribe((data: any) => {
                if (data.accessToken) {
                    this.rsi.endpoint(data.accessToken.uri, {'autoGetData': false}).subscribe(
                        (accessToken: any) => {
                            const accessTokens = this.accessTokens.getValue();
                            accessTokens[operation.operationId] = accessToken.token;
                            this.accessTokens.next(accessTokens);
                            resolve();
                        }
                    )
                } else {
                    resolve();
                }
            });
        });
    }

    /**
     * Exchanges a vehicle mbb accessToken for a we accessToken
     * @param mbbToken {string} - vehicle mbb accessToken exchanged for a we accessToken
     * @param baseUrl {string} - base url for identityKitProfileGateway
     * @param clientID {string} - client Id
     */
    public exchangeMBBToken(mbbToken: string, baseUrl: string, clientID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = `${baseUrl}/oidc/v1/token`;

            const data = {
                grant_type: "urn:ietf:params:oauth:grant-type:token-exchange",
                requested_token_type: "urn:ietf:params:oauth:token-type:access_token",
                actor_token_type: "urn:ietf:params:oauth:token-type:jwt",
                actor_token: mbbToken,
                scope: "agt_login",
                audience: clientID
            }

            let body = '';
            for (const key of Object.keys(data)) {
                body = `${body}${key}=${encodeURI(data[key])}&`
            }
            body = body.slice(0,-1);

            const bodyString = 'grant_type=${urn:ietf:params:oauth:grant-type:token-exchange}' + '&' +
                'requested_token_type=urn:ietf:params:oauth:token-type:access_token' + '&' +
                'actor_token_type=urn:ietf:params:oauth:token-type:jwt' + '&' +
                `actor_token=${mbbToken}` + '&' +
                'scope=agt_login' + '&' +
                `audience=${clientID}`;


            const headers = new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'cache-control': 'no-cache'
            });
            const options = new RequestOptions({ headers: headers });

            this.http.post(url, body, options)
                .toPromise()
                .then((res: any) => {
                    const json = res.json();
                    if (json.token_type[0] === 'b') { json.token_type = json.token_type.replace('b', 'B');}
                    const accessToken = json.token_type + ' ' + json.access_token;

                    console.log('%c Exchanged mbb token. ', 'color: #fff; background: #555');

                    resolve(accessToken);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }
}


