import { ShiftMainComponent } from './main.component';
import { ShiftScrollModule } from '../../gestures/scroll/scroll.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

export * from './main.component';

@NgModule({
    imports: [CommonModule, ShiftScrollModule],
    exports: [ShiftMainComponent ],
    declarations: [ShiftMainComponent ]
})
export class ShiftMainModule {}
