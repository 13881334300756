import { Injectable, EventEmitter } from '@angular/core';
import { Popup2Model } from '../model/popup2.model';
import { Popup2SkippableModel } from '../model/popup2skippable.model';

/**
 * A Service that manages popups of type Popup2Model. They are enqueued and can then be shown one after another.
 * To handle different priorities, there are as many queues as priority values (currently 2).
 *
 * How to use by app components:
 * @example
 * constructor(private popupDispatcher: Popup2DispatcherService)
 *
 * preparePopup() {
 *  this.popupLoading = Popup2Model.create4NoButtonLoading('Loading...');
 * }
 *
 * showPopup() {
 *  this.popupDispatcher.enqueueAndShowPopup(this.popupLoading);
 * }
 *
 * hidePopup() {
 *  this.popupDispatcher.closeOrSkipPopup(this.popupLoading);
 * }
 */
@Injectable()
export class Popup2DispatcherService {
  /** a two-dimensional array which holds queues of popups, one queue for each priority */
  private popupQueues4Priorities = new Array<Array<Popup2SkippableModel>>();
  /** event emitter that is triggered when a new popup was added to any queue */
  public newPopupAvailable = new EventEmitter<any>();
  /** event emitter that is triggered when any of the enqueued popups was marked to be skipped */
  public onPopupUpdate = new EventEmitter<Popup2SkippableModel>();

  constructor() {
    for (let i = 0; i < Popup2Model.numberOfPriorities; ++i) {
      const popupQueue = new Array<Popup2SkippableModel>();
      this.popupQueues4Priorities.push(popupQueue);
    }
  }

  /**
   * Find the right queue for the priority of the popup and enqueue it to be shown by popup2.component.
   * Trigger event 'newPopupAvailable'
   * @param popupDefinition the configured popup
   */
  public enqueueAndShowPopup(popupDefinition: Popup2Model) {
    const skippablePopup = new Popup2SkippableModel(popupDefinition);
    this.popupQueues4Priorities[popupDefinition.priority].push(skippablePopup);
    this.newPopupAvailable.emit();
  }

  /**
   * Close the popup if it is currently shown. Mark it to not being opened if it is still in queue.
   * @param popupDefinition The popup definition to be closed or skipped
   */
  public closeOrSkipPopup(popupDefinition: Popup2Model) {
    const priorityArray = this.popupQueues4Priorities[popupDefinition.priority];
    const popup2BeSkipped = priorityArray.find(skippablePopup =>
      skippablePopup.popup === popupDefinition);

    if (popup2BeSkipped) {
      popup2BeSkipped.skip = true;
      this.onPopupUpdate.emit(popup2BeSkipped);
    }
  }

  /**
   * @returns the popup with the highest priority that can be found in any queue
   * Internally used by popup states.
   */
  public getPopup2BeShownNext(): Popup2SkippableModel {
    let popup2BeShownNext: Popup2SkippableModel;

    for (let i = 0; i < this.popupQueues4Priorities.length; ++i) {
      if (this.popupQueues4Priorities[i].length > 0) {
        popup2BeShownNext = this.popupQueues4Priorities[i][0];
        break;
      }
    }

    return popup2BeShownNext;
  }

  /**
   * Remove the popup from its queue.
   * Internally used by popup states.
   * @param popupDefinition The popup to be removed
   */
  public dequeuePopupAfterClosed(popupDefinition: Popup2Model) {
    const priorityArray = this.popupQueues4Priorities[popupDefinition.priority];
    const popupAtHead = priorityArray[0].popup;
    if (popupAtHead && popupAtHead === popupDefinition) {
      priorityArray.shift();
    } else {
      console.warn('Closed Popup could not be dequeued - not found in array');
    }
  }
}
