import { Component, OnInit } from '@angular/core';

/**
 * Component that is used to show a loading animation.
 * Use css-transition with rotate to have an animated rotating component.
 */
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'shift-spinner',
    template: `<div class="loading-indicator-wrapper">
  <div class="loading-anime-mask"></div>
</div>`,
    styles: [``]
})
export class ShiftSpinnerComponent implements OnInit {

    /**
     * constructor for the shfit-spinner component
     */
    constructor() {}

    /**
     * default implementation of ngOnInit-interface function.
     */
    ngOnInit() {}
}
