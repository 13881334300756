import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Legal entry component that gets the content as html.
 * @example
 * <shift-screen>
 *                  <shift-header [title]="'Header text'"></shift-header>
 *                  <shift-main>
 *                      <shift-legal-entry *ngIf="yourShowLegalEntryVariable" [content]="yourHTMLContent"></shift-legal-entry>
 *                      <!-- MORE OF YOUR CONTENT -->
 *                  </shift-main>
 * </shift-screen>
 * 
 */
@Component({
    moduleId: module.id,
    // tslint:disable-next-line:component-selector
    selector: 'shift-legal-entry',
    template: `<div [innerHtml]="content"></div>`,
    styles: [`:host{position:relative}`]
})
export class ShiftLegalEntryComponent {

    /**
     * Content will be set as innerHtml in the DOM
     */
    @Input() public content: string;

    /**
     * Constructor of legal entry components injects a DomSanitizer
     * @param sanitizer service that holds the dom sanitizer
     */
    constructor(public sanitizer: DomSanitizer) {
        this.sanitizer = sanitizer;
    }
}
