import { Component, EventEmitter, HostBinding, Input, Output, AfterViewInit, ViewChild } from '@angular/core';
import { ShiftScrollDirective } from '../../gestures/scroll/scroll.directive';

/**
 * List component where you can include shift-item. 
 * ATTENTION: In general you don't need this list component and you can put directly shift-items
 * to the shift-main because the shift-main itself is a scrollable list.
 * 
 * This list component is useful in cases where you want to have a list in a list (shift-main).
 */
@Component({
    selector: 'shift-list',
    template: `<div class="wrapper" shiftScroll 
    [direction]="direction"
    [snapElement]="snapElement"
    [snapContainer]="'shift-list'"
    [snapOffset]="snapOffset"
    (onScroll)="onScroll.emit()"
    (onScrollEnd)="onScrollComplete.emit()">
    <div class="scrollable-content">
        <ng-content></ng-content>
    </div>
</div>`,
    styles: [`:host{display:block;overflow:hidden}:host(.vertical){overflow-y:auto}:host(.horizontal){overflow-x:auto}:host(.horizontal) .wrapper{height:100%;display:inline-block}.scrollable-content{display:flex}:host(.vertical) .scrollable-content{flex-direction:column;width:100%}:host(.horizontal) .scrollable-content{flex-direction:row;height:100%}`]
})
export class ListComponent implements AfterViewInit {

    /**
     * Sets the class vertical on the component depending on the property 'vertical'
     */
    @HostBinding('class.vertical') vertical: boolean;

    /**
     * Sets the class horizontal on the component depending on the property 'horizontal'
     */
    @HostBinding('class.horizontal') horizontal: boolean;

    /**
     * Property describes the direction.
     */
    private _direction = 'vertical';

    /**
     * Sets the direction of the list: 'horizontal' or 'vertical'
     */
    @Input()
    public set direction(dir: string) {
        this._direction = dir;
        this.horizontal = dir === 'horizontal';
        this.vertical = !this.horizontal;
    }

    /**
     * Getter to get the direction of the list
     */
    public get direction(): string {
        return this._direction;
    }

    /**
     * Sets the element to which the scrollDirective should snap.
     * To disable snapping set the snapElement to null.
     */
    @Input() public snapElement: string | null = 'shift-item';

    /**
     * Sets the offset of the snapping from the scrollDirective. 
     * Useful to show borders of snapped items for example.
     */
    @Input() public snapOffset: number = -2;

    /**
     * Output emits when event onscroll is fired. This event is defined in the shiftScroll Directive.
     */
    @Output() onScroll: EventEmitter<any> = new EventEmitter();

    /**
     * Output emits when event onscrollend is fired. This event is defined in the shiftScroll Directive.
     */
    @Output() onScrollComplete: EventEmitter<any> = new EventEmitter();

    /**
     * Reference to the ShiftScrollDirective in this component.
     */
    @ViewChild(ShiftScrollDirective) shiftScrollDirective: ShiftScrollDirective;

    /**
     * Constructor for the shift list
     */
    constructor() {}

    /**
     * Initializes the ShiftScrollDirective and add Gesture-Events after all children of this component where initialized first.
     */
    ngAfterViewInit() { }
}
