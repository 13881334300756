import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShiftPwdStrengthIndicatorComponent } from './pwd-strength-indicator.component';
import { ShiftPwdStrengthIndicatorDirective } from './pwd-strength-indicator.directive';
import { ShiftPwdStrengthIndicatorContainerComponent } from './pwd-strength-indicator-container.component';

export * from './pwd-strength-indicator.component';
export * from './pwd-strength-indicator.directive';
export * from './pwd-strength-indicator-container.component';

@NgModule({
    imports: [CommonModule],
    exports: [
        ShiftPwdStrengthIndicatorComponent,
        ShiftPwdStrengthIndicatorDirective,
        ShiftPwdStrengthIndicatorContainerComponent
    ],
    declarations: [
        ShiftPwdStrengthIndicatorDirective,
        ShiftPwdStrengthIndicatorComponent,
        ShiftPwdStrengthIndicatorContainerComponent
    ]
})
export class ShiftPwdStrengthIndicatorModule {}
