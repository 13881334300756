import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subscription, ReplaySubject, Subject, defer } from 'rxjs';
import { IEndpointConfiguration } from './rsi';
import { IService, ServiceLocator } from './service.locator';
import { retry } from './retry';
import { first, filter, switchMap, share, tap, map } from 'rxjs/operators';

export interface IRSIEndpointStatus {
  status: string;
  data?: any;
}


export interface IRAWMessage {
  type: 'ws' | 'get' | 'delete' | 'post';
  payload: any;
  direction: 'in' | 'out';
  error?: boolean;

}

export class RSIEndpoint<T> extends ReplaySubject<T> {
  public connected = false;
  public data: T;
  public deferUpdates = false;
  public status: ReplaySubject<IRSIEndpointStatus> = new ReplaySubject<IRSIEndpointStatus>();

  public destination: string;
  public endpointConfig: IEndpointConfiguration;
  private host = '';
  private _wsSubscription: Subscription;
  private initialDataReceived = false;
  private lastPendingUpdate: any;
  private waitForUpdate = false;
  public service: IService;
  public rawMessages: Subject<any> = new Subject<any>();
  public sessionId: any;
  public isListening = false;

  constructor(private http: HttpClient, private serviceRegistry: ServiceLocator) {
    super();
    this.sessionId = Date.now();
    this.serviceRegistry.onReset.subscribe(() => {
      this.reconnect();
    });
  }


  public reconnect() {
    this.unlisten();

    if (this._wsSubscription) {
      this._wsSubscription.unsubscribe();
    }

    this.getService(this.destination);
  }


  public initialize(destination: string, endpointConfig: IEndpointConfiguration) {
    if (this.sessionId && this.sessionId !== '') {
      this.destination = destination + '#' + this.sessionId;
    }

    this.endpointConfig = endpointConfig;
    this.getService(this.destination);
  }


  getService(destination: string) {

    const getService$ = defer(() => this.serviceRegistry.getService(destination));

    const check$ = getService$
      .pipe(
        retry(this.endpointConfig.reconnectCount, this.endpointConfig.reconnectDelay)
      );

    check$.subscribe((service) => {
      this.service = service;
      this.init();
    }, (e) => {
      this.error(e);
    });
  }

  private init() {
    if (this.service.host) {
      this.host = this.service.host;
    }

    this.service.ws.getMessages(this.destination).subscribe((message) => {
      this.rawMessages.next({
        type: 'ws',
        payload: message,
        direction: 'in'
      });

    });

    this._wsSubscription = this.service.ws.getDataMessages(this.destination).subscribe((data: any) => {
      this.waitForUpdate = false;
      this.next(data);

      // FIXME: this is never executed because waitForUpdate is always false at this point
      if (this.waitForUpdate && this.lastPendingUpdate && this.deferUpdates) {
        this.updateElement(this.lastPendingUpdate);
      }
    });

    if (this.endpointConfig.autoSubscribe) {
      this.listen();
    }

    this.subscribe(data => (this.data = data));

    this.service.ws.status.subscribe((data: any) => {
      if (data.type === 'open') {
        this.connected = true;
        if (this.endpointConfig.autoGetData && !this.initialDataReceived) {
          this.getElement();
        }
        this.status.next({
          status: 'open',
          data: data.data
        });
      } else if (data.type === 'close') {
        this.connected = false;
        this.status.next({
          status: 'closed',
          data: data.data
        });
      } else if (data.type === 'error') {
        this.status.next({
          status: 'error',
          data: data.data
        });
      }
    });
  }

  public getElement<T>(): Observable<HttpResponse<T>> {
    this.rawMessages.next({
      type: 'get',
      payload: null,
      direction: 'out'
    });
    const request = this.http.get<T>(`${this.host}${this.destination}`, { observe: 'response' }).pipe(share());
    request.pipe(map(resp => resp.body['data'])).subscribe((data: any) => {
      this.rawMessages.next({
        type: 'get',
        payload: data,
        direction: 'in'
      });
      this.initialDataReceived = true;
      this.next(data);
    });

    return request;
  }

  public createElement(data: any): Observable<HttpResponse<Object>> {
    const body: string = JSON.stringify(data);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    this.rawMessages.next({
      type: 'post',
      payload: body,
      direction: 'out'
    });

    const getRequest = () => {
      return this.http.post(`${this.host}${this.destination}`, body, { headers, observe: 'response' }).pipe(share());
    };

    let resp$: Observable<HttpResponse<Object>>;
    if (!this.connected) {
      resp$ = this.status.pipe(
        filter((x: any) => x.status === 'open'),
        switchMap(() => getRequest()),
        share()
      );
    } else {
      resp$ = getRequest();
    }

    const logPostResponse = (postResponse) => {
      this.rawMessages.next({
        type: 'post',
        payload: data,
        direction: 'in'
      });
    };

    resp$.pipe(tap(logPostResponse)).subscribe();
    return resp$;
  }

  public updateElement(data: any): Observable<HttpResponse<Object>> {
    if (this.waitForUpdate && this.deferUpdates) {
      // FIXME: lastPendingUpdate never gets reset
      // at the moment this doesn't matter because it is also never used anywhere
      this.lastPendingUpdate = data;
      return;
    }


    this.waitForUpdate = true;
    const body: string = JSON.stringify(data);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    this.rawMessages.next({
      type: 'post',
      payload: data,
      direction: 'out'
    });

    const getRequest = () => {
      const postUrl = `${this.host}${this.destination}`;
      return this.http.post(postUrl, body, { headers, observe: 'response' }).pipe(share());
    };

    let resp$: Observable<HttpResponse<Object>>;

    if (!this.connected) {
      resp$ = this.status.pipe(
        filter((x: any) => x.status === 'open'),
        switchMap(() => getRequest()),
        share()
      );
    } else {
      resp$ = getRequest();
    }


    const logPostSuccess = (postResponse) => {
      this.rawMessages.next({
        type: 'post',
        payload: postResponse,
        direction: 'in',
        error: false
      });
    };

    const logPostError = (error) => {
      this.rawMessages.next({
        type: 'post',
        payload: error,
        direction: 'in',
        error: true
      });
    };

    resp$.pipe(tap(logPostSuccess, logPostError)).subscribe();

    return resp$;
  }

  public deleteElement(): Observable<HttpResponse<Object>> {
    const getRequest = () => {
      return this.http.delete(`${this.host}${this.destination}`, { observe: 'response' }).pipe(share());
    };

    this.rawMessages.next({
      type: 'delete',
      payload: null,
      direction: 'out'
    });

    let resp$: Observable<HttpResponse<Object>>;
    if (!this.connected) {
      resp$ = this.status.pipe(
        filter((x: any) => x.status === 'open'),
        switchMap(() => getRequest()),
        share()
      );
    } else {
      resp$ = getRequest();
    }


    const deleteResponse = (resp) => {
      this.rawMessages.next({
        type: 'delete',
        payload: resp,
        direction: 'in'
      });
    };

    resp$.pipe(tap(deleteResponse)).subscribe();

    return resp$;
  }

  public listen(): Promise<any> {
    return new Promise((resolve, reject) => {
      const subscriptionConfig: any = Object.assign({}, this.endpointConfig);
      subscriptionConfig.destination = this.destination;

      this.service.ws.getMessages(this.destination)
        .pipe(
          filter(x => (x.type === 'subscribe' && x.status === 'ok')),
          first()
        ).subscribe(() => {
        this.isListening = true;
        resolve();
      });


      this.service.ws.subscribe(subscriptionConfig);
    });
  }

  public unlisten(): Promise<any> {
    return new Promise((resolve, reject) => {

      if (this.service) {
        this.service.ws.getMessages(this.destination)
          .pipe(
            filter(x => (x.type === 'unsubscribe' && x.status === 'ok')),
            first()
          ).subscribe(() => {
          this.isListening = false;
          resolve();
        });

        this.service.ws.unsubscribe(this.destination);
      } else {
        reject();
      }
    });
  }


}
