import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ArrowCAssetComponent } from './arrow-c.component';

@NgModule({
    imports: [CommonModule],
    exports: [ArrowCAssetComponent],
    declarations: [ArrowCAssetComponent]
})
export class ArrowCAssetModule {}
