import { ModuleWithProviders, NgModule } from '@angular/core';
import { PinchGestureDirective } from './pinch-gesture.directive';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule],
    exports: [PinchGestureDirective],
    declarations: [PinchGestureDirective]
})
export class PinchGestureModule {
    /** @deprecated */
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: PinchGestureModule,
            providers: []
        };
    }
}
