import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GesturesService } from './gestures.service';
import { DragGestureModule } from './drag-gestures/drag-gestures.module';
import { PanGestureModule } from './pan-gestures/pan-gesture.module';
import { PinchGestureModule } from './pinch-gestures/pinch-gesture.module';
import { PressGestureModule } from './press-gestures/press-gesture.module';
import { RubberbandGestureModule } from './rubberband-gestures/rubberband.module';
import { ShiftScrollModule } from './scroll/scroll.module';
import { TapGestureModule } from './tap-gestures/tap-gesture.modul';

@NgModule({
    imports: [
        CommonModule,
        DragGestureModule,
        PanGestureModule,
        PinchGestureModule,
        PressGestureModule,
        RubberbandGestureModule,
        ShiftScrollModule,
        TapGestureModule
    ],
    exports: [
        DragGestureModule,
        PanGestureModule,
        PinchGestureModule,
        PressGestureModule,
        RubberbandGestureModule,
        ShiftScrollModule,
        TapGestureModule
    ],
    declarations: [],
    providers: [GesturesService]
})
export class ShiftGesturesModule {
    /** @deprecated */
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ShiftGesturesModule,
            providers: []
        };
    }
}
