/**
 * Class that describes the legal entry model.
 */
export class LegalEntryModel {
    /**
     * Title entry for the legal entry.
     */
    public title: string;

    /**
     * HTML Content for the legal entry. Will be applied to InnerHtml.
     */
    public content: string;
}
