import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { LegalEntryModel } from '../legal-entry/legal-entry.model';

/**
 * Legal overview components shows all legal entries in a list overview
 */
@Component({
    moduleId: module.id,
    // tslint:disable-next-line:component-selector
    selector: 'shift-legal-overview',
    template: `<div class="shift-list-style" *ngIf="!showLegalEntry">
  <shift-item class="shift-item-style" *ngFor="let legal of legalEntrys; let i = index;" (tap)="tappedLegalEntry(legal)">{{ legal.title }}</shift-item>
  <shift-item *ngIf="showDevMode" class="shift-item-style" (tap)='openDevSettings($event)'>Developer Settings</shift-item>
</div>

<shift-legal-entry id="legalOverlay"
  *ngIf="showLegalEntry"
  [(content)]="content">
</shift-legal-entry>
`,
    styles: [``]
})
export class ShiftLegalOverviewComponent implements OnInit {

    /**
     * Sets the information for the legal entries.
     */
    @Input() public legalEntrys: LegalEntryModel[];
    
    /**
     * Switch to show developer settings.
     */
    @Input() public showDevMode: boolean;

    /**
     * Switch to show the details of a legal entry and hide the list.
     */
    @Input() public showLegalEntry;

    /**
     * Output selectDevSettings will emit when the list entry for developer settings was selected.
     */
    @Output() public selectDevSettings: EventEmitter<any> = new EventEmitter();

    /**
     * Stores the title information of a legal entry.
     */
    title: string;

    /**
     * Stores the content information of a legal entry.
     */
    content: string;

    /**
     * Constructor for legal overview.
     */
    constructor() {}

    /**
     * Default implementation of OnInit.
     */
    ngOnInit(): void {}

    /**
     * Function that will be triggered when a list item in the overview was selected.
     * @param entry 
     */
    tappedLegalEntry(entry: LegalEntryModel): void {
        this.title = entry.title;
        this.content = entry.content;
        this.showLegalEntry = true;
    }

    /**
     * Function that will be triggered when the developer list item was selected.
     * @param event 
     */
    openDevSettings(event: Event) {
        this.selectDevSettings.emit();
    }
}
