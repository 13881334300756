import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ListComponent } from './list.component';
import { ShiftItemComponent } from './item.component';
import { ShiftScrollModule } from '../../gestures/scroll/scroll.module';
import { ScrollService } from '../../gestures/scroll/scroll.service';

export * from './list.component';

@NgModule({
    imports: [CommonModule, ShiftScrollModule],
    exports: [ListComponent, ShiftItemComponent],
    declarations: [ListComponent, ShiftItemComponent],
    providers: [ScrollService]
})
export class ShiftListModule {
    /** @deprecated */
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ShiftListModule,
            providers: [ScrollService]
        };
    }
}
