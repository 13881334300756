import { ModuleWithProviders, NgModule } from '@angular/core';
import { DurationPipe } from './pipes/duration.pipe';
import { AddPluralPipe } from './pipes/add-plural.pipe';

@NgModule({
    declarations: [DurationPipe, AddPluralPipe],
    imports: [],
    exports: [],
    providers: []
})
export class CqShiftCoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CqShiftCoreModule,
            providers: []
        };
    }
}
