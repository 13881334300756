import { ElementRef, EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class GesturesService {
    public streamSliderTwoFingerEvent: EventEmitter<HammerInput> = new EventEmitter<HammerInput>();
    private preventTimeout: number;
    private _gestureInProgress = false;
    public modalOpen = false;

    set gestureInProgress(value: boolean) {
        if (this._gestureInProgress !== value) {
            window.clearTimeout(this.preventTimeout);

            // Prevent click events after gesture complete
            if (value) {
                // this.ngZone.run(() => {
                this._gestureInProgress = true;
                // });
            } else {
                this.preventTimeout = window.setTimeout(() => {
                    // this.ngZone.run(() => {
                    this._gestureInProgress = false;
                    // });
                }, 200);
            }
        }
    }

    get gestureInProgress(): boolean {
        return this._gestureInProgress;
    }

    constructor() {}

    public sliderTwoFingerEvent(event: HammerInput): void {
        this.streamSliderTwoFingerEvent.emit(event);
    }

    public pan(hammertime: HammerManager, eventEmitter: EventEmitter<{}>, directive: any): void {
        hammertime.on('panstart pan panend', (ev: HammerInput) => {
            // this.ngZone.run(() => {
            if (ev.type === 'panstart') {
                if (directive.gesturesService.gestureInProgress) {
                    directive.emitGesture = false;
                    hammertime.stop(true);
                } else {
                    directive.emitGesture = true;
                    directive.gesturesService.gestureInProgress = true;
                }
            }

            if (directive.emitGesture) {
                // Set event to isFinal/panend for multitouches when a single touch ends.
                const maxPointers = (ev as any).maxPointers;
                if (maxPointers > 1 && (ev.srcEvent.type === 'touchend' || ev.srcEvent.type === 'mouseup')) {
                    ev.isFinal = true;
                    ev.type = 'panend';
                }

                // TODO: revise
                // PanRecognizer.options werden an das HammerInput event Objekt angehaengt, um im Handler damit arbeiten zu koennen.
                // Entscheiden, ob man hier ggf. das gesamte HammerManager Objekt mitgeben sollte, um Gesamtzugriff
                // auf alle Recognizer zu haben. Im Moment (noch) nicht notwendig.
                const addData: any = {};
                addData.panOptions = (hammertime.get('pan') as any).options;
                (ev as any).additionalData = addData;

                // If is final ...
                if (ev.type === 'panend' || ev.type === 'touchend') {
                    setTimeout(() => {
                        directive.gesturesService.gestureInProgress = false;
                    }, 0.1);
                }
                eventEmitter.emit(ev);
                this.dispatchDomEvent(ev, directive.eventId, directive.el);
            }
        });
        // });
    }

    public stopForIncomingEvents(hammertime: HammerManager, directive: any): void {
        directive.el.nativeElement.addEventListener(
            directive.eventId,
            (e: CustomEvent) => {
                // this.ngZone.run(() => {
                if (e.type === 'panend' || e.type === 'touchend') {
                    setTimeout(() => {
                        directive.emitGesture = true;
                    }, 0.1);
                } else {
                    directive.emitGesture = false;
                    hammertime.stop(true);
                }
                // });
            },
            false
        );
    }

    /**
     * Erstellt ein Event und dispatcht dieses vom parent des aktuellen Elements
     */
    private dispatchDomEvent(ev: HammerInput, id: string, el: ElementRef): void {
        const event: Event = new CustomEvent(id, { detail: ev, bubbles: true, cancelable: true });
        el.nativeElement.parentNode.dispatchEvent(event);
    }
}
