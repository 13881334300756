import { Injectable } from '@angular/core';
import { RSIService, RSIEndpoint } from '@shift/rsi';
import { Subject, BehaviorSubject, Observable, throwError} from 'rxjs';
import { privacysetup } from '../../..';
import { ServicePrivacyLevel } from './service-privacy-level.enum';

@Injectable()
export class PrivacySetupService {
    public privacyLevel = new Subject<number>();
    public privacyGroup = new BehaviorSubject<privacysetup.IPrivacyGroupObject>({});

    private privacyGroupEndpoint: RSIEndpoint<privacysetup.IPrivacyGroupObject>;

    constructor(private rsi: RSIService) {
    }

    public init() {
        this.privacyGroupEndpoint = this.rsi.firstEndpoint('/privacysetup/privacyGroups/', {autoGetData: false});
        this.subscribeForData();
    }

    private subscribeForData(): void {
        this.privacyGroupEndpoint.subscribe(
            (privacyGroup: privacysetup.IPrivacyGroupObject) => {
                this.privacyGroup.next(privacyGroup);
                this.getPrivacyLevel(privacyGroup);
            }
        );
    }

    /**
     * Set privacy level
     * @param privacyGroup {IPrivacyGroupObject}
     */
    private getPrivacyLevel(privacyGroup: privacysetup.IPrivacyGroupObject) {

        if (!privacyGroup.personalVehicle || !privacyGroup.personalUser) {
            this.privacyLevel.next(ServicePrivacyLevel.FullPrivacy);
        } else if (!privacyGroup.personalGps && !privacyGroup.pseudonymousGps) {
            this.privacyLevel.next(ServicePrivacyLevel.PersonalData);
        } else if (!privacyGroup.personalGps) {
            this.privacyLevel.next(ServicePrivacyLevel.GPS);
        } else {
            this.privacyLevel.next(ServicePrivacyLevel.GPSTracking);
        }
    }


    /**
     * Set privacy level
     * @param privacyLevel {number | string} - valid options [0, 1, 2, 3, FullPrivacy, PersonalData, GPS, GPSTracking]
     */
    public setPrivacyLevel(privacyLevel: number | string): Observable<any> {
        switch(privacyLevel) {
            case ServicePrivacyLevel.FullPrivacy || 'FullPrivacy':
                return this.updatePrivacyGroup(false, false, false, false, false, false);
            case ServicePrivacyLevel.PersonalData || 'PersonalData':
                return this.updatePrivacyGroup(false, false, true, true, true, true);
            case ServicePrivacyLevel.GPS || 'GPS':
                return this.updatePrivacyGroup(false, true, true, true, true, true);
            case ServicePrivacyLevel.GPSTracking || 'GPSTracking':
                return this.updatePrivacyGroup(true, true, true, true, true, true);
            default:
                return throwError(new Error(`UNKOWN_PRIVACY_LEVEL: Privacy level ${privacyLevel} does not exists. Use one of those: ${ServicePrivacyLevel}`));
        }
    }


    /**
     * update privacy group
     * @param pG {boolean} - if personalGps is enabled
     * @param psG {boolean} - if pseudonymousGps is enabled
     * @param pU {boolean} - if personalUser is enabled
     * @param pV {boolean} - if personalVehicle is enabled
     * @param bOCD {boolean} - if basedOnConsentData is enabled
     * @param bOBID {boolean} - if basedOnBalancedInterestData is enabled
     */
    public updatePrivacyGroup(pG: boolean, psG: boolean, pU: boolean, pV: boolean, bOCD: boolean, bOBID: boolean): Observable<any> {
        return this.privacyGroupEndpoint.updateElement({
            personalGps: pG,
            pseudonymousGps: psG,
            personalUser: pU,
            personalVehicle: pV,
            basedOnConsentData: bOCD,
            basedOnBalancedInterestData: bOBID
        });
    }
}

