import { Component, HostBinding, HostListener, Input } from '@angular/core';

/**
 * Component that can show a binary state (on/off) as a switch. If you want to do something on a state switch please listen to the output 'onClick'.
 * For example you could have a value that you get from a rsi-service that is connected with the the switch.
 * @example
 * <shift-screen>
 *                  <shift-main>
 *                      <shift-switch [on]="yourRsiValue" (onClick)="yourChangeRsiValueFunction($event)"></shift-switch>
 *                      <!-- MORE OF YOUR CONTENT -->
 *                  </shift-main>
 * </shift-screen>
 */
@Component({
    selector: 'shift-switch',
    template: `<div class="outer">
    <div class="inner"><div>
</div>
`,
    styles: [`:host{display:inline-block}`],
    host: {
        class: 'switch'
    }
})
export class ShiftSwitchComponent {

    /**
     * Sets the switch to 'on'. The class 'on' will be applied to the component.
     */
    @HostBinding('class.on')
    @Input()
    on: boolean;

    /**
     * Constructor for switch component
     */
    constructor() {}

    /**
     * On click event the switch will be toggled.
     * @param event 
     */
    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        event.stopPropagation();
        this.on = !this.on;
    }
}
