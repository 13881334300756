import { Popup2AbstractStateContextModel } from './popup2-abstract-state-context.model';
import { Popup2AbstractStateModel } from './popup2-abstract-state.model';
import { Popup2StateClosingModel } from './popup2-state-closing.model';

/**
 * A class which represents the state where popup is open.
 * After a defined number of milliseconds, the state switches to 'closing' automatically.
 */
export class Popup2StateOpenWithTimeoutModel extends Popup2AbstractStateModel {

  private closeAfterTimer: NodeJS.Timer;

  constructor (private stateContext: Popup2AbstractStateContextModel) {
    super();
  }

  /**
   * Starts the timer.
   */
  public onEnter() {
    this.checkAndEnsureCloseAfterTime();
  }

  /**
   * Checks if new available popup has higher priority than the current one. If true, then perfom popup close.
   */
  public newPopupAvailable() {
    if (this.stateContext.mustSwitch2NextPopup()) {
      this.closePopup();
    }
  }

  /**
   * Close popup on button pressed.
   */
  public buttonPressed() {
    this.closePopup();
  }

  /**
   * Start timer and subscribe on timeout after defined milliseconds. Close popup on timeout.
   */
  private checkAndEnsureCloseAfterTime() {
    let remainingMillisecondsBeforeClose = this.getRemainingMillisecondsBeforeClose();

    if (remainingMillisecondsBeforeClose) {
      if (remainingMillisecondsBeforeClose <= 0) {
        remainingMillisecondsBeforeClose = 0;
      }
      this.ensureCloseAfterTime(remainingMillisecondsBeforeClose);
    } else {
      this.closePopup();
    }
  }

  /**
   * @returns the number of milliseconds to keep the popup open considering also the 'show-at-least-time'
   */
  private getRemainingMillisecondsBeforeClose(): number {
    const showAtLeastMilliseconds = this.stateContext.currentlyShownPopup.showAtLeastNMilliseconds;
    const closeAfterMilliseconds = this.stateContext.currentlyShownPopup.closeAfterNMilliseconds;
    let remainingMillisecondsBeforeClose: number;

    if (closeAfterMilliseconds) {
      if (showAtLeastMilliseconds) {
        remainingMillisecondsBeforeClose
            = closeAfterMilliseconds - showAtLeastMilliseconds;
      } else {
        remainingMillisecondsBeforeClose = closeAfterMilliseconds;
      }
    }

    return remainingMillisecondsBeforeClose;
  }

  /**
   * Start the timer and call switch2StateClosing() on timeout.
   * @param milliseconds milliseconds to run the timer
   */
  private ensureCloseAfterTime(milliseconds: number) {
    this.closeAfterTimer = setTimeout(() => {
      this.switch2StateClosing();
    }, milliseconds);
  }

  /**
   * Clear timer and close the popup.
   */
  public closePopup() {
    this.clearTimer();
    this.switch2StateClosing();
  }

  /**
   * Clear timer if not undefined.
   */
  private clearTimer() {
    if (this.closeAfterTimer) {
      clearTimeout(this.closeAfterTimer);
    }
  }

  /**
   * Create new state 'closing' and switch to this state.
   */
  private switch2StateClosing() {
    const nextState = new Popup2StateClosingModel(this.stateContext);
    this.stateContext.switch2State(nextState);
  }
}
