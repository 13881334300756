import { Injectable } from '@angular/core';
import { RSIService } from '@shift/rsi';
import { BehaviorSubject } from 'rxjs';
import { unit } from '../../interfaces/unit';

@Injectable()
export class UnitService {

    public units = new BehaviorSubject<unit.IUnitObject>(undefined);

    constructor(private rsi: RSIService) {
    }

    init() {
        this.subscribeForData();
    }

    private subscribeForData(): void {
        const endpoint = this.rsi.firstEndpoint('/unit/units', {'autoGetData': false});
        endpoint.subscribe(
            (data: unit.IUnitObject) => {
                this.units.next(data);
            },
            (error: any) => {
                // this.logger.error(error);
                console.warn('Error in UnitService: ' + error);
            }
        );
    }
}
