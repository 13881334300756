import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShiftDatePickerComponent } from './date-picker.component';
import { ShiftHeaderModule } from '../header/header.module';
import { ShiftMainModule } from '../main/main.module';

@NgModule({
    imports: [CommonModule, ShiftHeaderModule, ShiftMainModule],
    declarations: [ShiftDatePickerComponent],
    exports: [ShiftDatePickerComponent]
})
export class ShiftDatePickerModule {}
