export class UrlUtil {


  constructor() {

  }

  public static getParameterByName(name, url?) {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }


  public static normalizeEngineinstanceURI(val: string) {
    if (val.indexOf('http') !== -1 || val.indexOf('https') !== -1) {
      return new URL(val).pathname;
    }
    return val;
  }


}
